/* Simon.com 404 */

.error-404 {
  background-color: #f1f1f1;

  &-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
  }

  &-title {
    margin-bottom: 0;
    font-size: var(--font-size-h2);

    @media(--sm-viewport-up) {
      font-size: var(--display4-size);
    }
  }

  &-btn {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 30px;

    &.btn-outline-primary:focus,
    &.btn-outline-primary:hover,
    &.btn-outline-primary:active {
      border-color: var(--brand-primary);
      color: var(--brand-primary);
    }
  }
}
