/*
 * Normalize/Reset
 *
 * Tried to only include the necessary components needed
 */

html {
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  font-size: var(--html-base-size);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@at-root {

  @-moz-viewport {
    width: device-width;
  }

  @-webkit-viewport {
    width: device-width;
  }

  @viewport {
    width: device-width;
  }
}

body {
  background: var(--body-bg);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: normal;
  line-height: var(--body-line-height);
  margin: 0;
}

/* Reset margin and padding to zero */

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
figures {
  margin: 0;
  padding: 0;
}

main {
  display: block;
}

h1,
h2,
h3,
h4 {
  font-size: inherit;
}

/* Set bold to normal and use Circular Pro */

b,
strong {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
}

/* Links */

a,
button {
  color: inherit;

  @include transition(background, 0.3s);
}

a {
  background-color: transparent;
  text-decoration: underline;
}

a {
  &:active {
    outline: 0;
  }

  &:hover {
    outline: 0;
  }
}

::-moz-focus-inner {
  border: 0;
  padding: 0;
}

:focus {
  outline: 0;
}

[tabindex="-1"]:focus {
  outline: none !important;
}

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

/* HTML5 display definitions
 *
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

address {
  font-style: normal;
}

/* HTML5 display definitions
 *
 * 1. Correct `inline-block` display not defined in IE 8/9.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

/*
 * 1. Prevent modern browsers from displaying `audio` without controls.
 * 2. Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
  display: none;
  height: 0;
}

/*
 * 1. Address `[hidden]` styling not present in IE 8/9/10.
 * 2. Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */

[hidden],
template {
  display: none;
}

/* Text-level semantics
 *
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */

abbr[title] {
  border-bottom: 1px dotted;
}

/* Address inconsistent and variable font size in all browsers. */

small {
  font-size: 0.77rem;
}

/* Prevent `sub` and `sup` affecting `line-height` in all browsers. */

sub,
sup {
  font-size: 50%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.7em;
}

sub {
  bottom: -0.25em;
}

/* Embedded content
 *
 * Correct overflow not hidden in IE 9/10/11.
 */

img {
  border: 0;
  height: auto;
  max-width: 100%;
}

svg:not(:root) {
  overflow: hidden;
}

iframe {
  border: none;
  display: block;
}

/* Grouping content
 *
 * Address margin not present in IE 8/9 and Safari.
 */

figure {
  margin: 1em 40px;
}

/* Address differences between Firefox and other browsers. */

hr {
  box-sizing: content-box;
  height: 0;
}

/* Contain overflow in all browsers. */

pre {
  overflow: auto;
}

/* Address odd `em`-unit font size rendering in all browsers. */

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

/* Address iOS rounded text inputs */

textarea,
input:not([type="radio"]):not([type="checkbox"]) {
  appearance: none;
  border-radius: 0;
}

/* Forms
 *
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 *
 * 1. Correct color not being inherited.
 *    Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 */

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0;
}

/* Address `overflow` set to `hidden` in IE 8/9/10/11. */

button {
  background: none;
  border: 0;
  cursor: pointer;
  -webkit-font-smoothing: inherit;
  letter-spacing: inherit;
  overflow: visible;
}

/*
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */

button,
select {
  text-transform: none;
}

/*
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 */

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  appearance: button;
  cursor: pointer;
}

/* Re-set default cursor for disabled elements. */

button[disabled],
html input[disabled] {
  cursor: default;
}

/* Remove inner padding and border in Firefox 4+. */

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/*
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

input {
  line-height: normal;
}

/*
 * It's recommended that you don't attempt to style these elements.
 * Firefox's implementation doesn't respect box-sizing, padding, or width.
 *
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 */

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

/*
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/* Address `appearance` set to `searchfield` in Safari and Chrome. */

input[type="search"] {
  appearance: textfield;
}

/*
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  appearance: none;
}

/*
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 */

legend {
  border: 0;
  padding: 0;
}

/* Remove default vertical scrollbar in IE 8/9/10/11. */

textarea {
  overflow: auto;
}

/* Tables
 *
 * Remove most spacing between table cells.
 */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}
