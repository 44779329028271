/* Simon.com Homepage */

.homepage-content {
  & .container-fluid {
    background-color: var(--brand-primary);
  }

  @media (--md-viewport-up) {
    margin-top: 100vh;
    z-index: 1;
    position: relative;
    width: 100%;
  }
}

.footer-homepage {
  z-index: 1;
  position: relative;
}

.po-home .homepage-content {
  background-color: var(--white);
  padding-top: 1.9152rem;

  @media (--lg-viewport-up) {
    padding-top: 3.30947rem;  
  }
}

.po-banner {
  &-bg.jumbotron-bg {
    background-color: #9f9e9a;
    background-repeat: no-repeat;
  }

  .container-fluid {
    background-color: transparent !important;
  }
}

.grid-cell {
  position: relative;
  background-color: var(--black);
  overflow: hidden;

  &-text {
    position: absolute;
    bottom: 1.9152rem;
    left: 1.9152rem;
    right: 1.9152rem;
    color: var(--white);
    z-index: 2;

    @media(--sm-viewport-down) {
      bottom: 1.596rem;
      left: 1.33rem;
      right: 1.33rem;
    }
  }

  &-text.center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: auto;
  }

  &-title {
    line-height: 0.77;
    margin-bottom: var(--spacer);

    @media(--sm-viewport-up) {
      font-size: var(--font-size-h2);
      margin-bottom: 1.33rem;
    }

    @media(--xl-viewport-up) {
      font-size: var(--display4-size);
      margin-bottom: 1.596rem;
    }
  }

  &-desc {
    margin-bottom: 0;
    max-width: 800px;

    @media(--lg-viewport-up) {
      font-size: var(--font-size-h5);
    }
  }

  &-img {
    opacity: 0.5;
    z-index: 1;

    @mixin transition opacity, 0.5s, ease;
  }

  &:hover .grid-cell-img:not(.no-opacity) {
    opacity: 0.7;
  }

  &-img.no-opacity {
    opacity: 1;
  }

  @media (--sm-viewport-down) {
    height: 100vw;
    width: 100vw;
    overflow: hidden;
  }
}

.center-cell {
  height: calc(100vw / 3 - 7px);
  background-color: var(--brand-primary);
}

.mobile-search-callout {
  aside {
    height: 22px;
  }

  &-text {
    position: relative;
    top: -5px;
  }

  .icon-search {
    margin-right: 5px;
    position: relative;
  }
}

.mobile-banner {
  padding: 1.33rem 0;
  text-align: center;
  background-color: var(--section-bg);

  &-title {
    margin-bottom: 7px;
  }

  &-desc {
    margin: 0;
  }
}

/* Filters */

.filters {
  padding: 1.9152rem 0;

  @media (--lg-viewport-down) {
    padding-bottom: 0;
    position: sticky;
    background-color: var(--gray-lighter);
    top: calc(var(--navbar-global-height) + var(--navbar-mobile-height));
    z-index: 1010;
  }

  .filter {
    position: relative;

    @media (--lg-viewport-down) {
      margin-bottom: 1.9152rem;
    }
  }
}

/* Mall Insider Hero Bg Color */

.mall-insider-hero {
  background-color: #e2e2e0;
}

.forgot-password-link {
  font-size: .77rem;
}


.hero-container {
  position: relative;

  & .scroll-arrow {
    border: 3px solid var(--white);
    border-radius: 50%;
    bottom: 1.9152rem;
    width: 3rem;
    height: 3rem;
    left: 50%;
    transform: translateX(-50%) scale(1);
    position: absolute;
    transition: all .2s;
    z-index: 2;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      transform: translateX(-50%) scale(1.2);
    }

    & > svg {
      fill: var(--white);
      position: absolute;
      top: calc(50% + 0.045rem);
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 1.25rem;
      height: 1.25rem;
      animation: 6s infinite scroll-arrow;
    }

    @keyframes scroll-arrow {
      0% {
        transform: translateX(-50%) translateY(-200%);
      }
      10%,
      50%,
      95% {
        transform: translateX(-50%) translateY(-50%);
      }
      100% {
        transform: translateX(-50%) translateY(75%);
      }
    }
  }
}