/*
 * Google Maps
 *
 * Custom Styles
 */

/* Gmap sizes */

.map.open {
  height: 400px;

  @media (--lg-viewport-down) {
    height: 300px;
  }
}

/* Zoom styles - TODO: Update to use new svg icons. when done in web. port over to brands */

.map-zoom {
  background-color: var(--black);
  border-radius: 5px;
  margin-right: var(--spacer);
  margin-bottom: var(--spacer);
  opacity: 1;
  padding: 6px;
}

.zoom-control {
  opacity: 0.75;

  &:hover {
    opacity: 1 !important;
  }
}

/* Overwrites direction feedback from gmaps api */

.adp {
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight: normal !important;
  color: var(--black) !important;
}

.adp-placemark {
  background: var(--white) !important;
  border: none !important;
  border-top: 1px solid var(--brand-gray) !important;
  border-bottom: 1px solid var(--brand-gray) !important;
  color: var(--black) !important;
  margin: 0 !important;
  padding: 1.10833rem 0px 1.10833rem 0px !important;
  vertical-align: middle !important;

  @media (--lg-viewport-down) {
    background-color: transparent !important;
  }
}

.adp-step,
.adp-substep {
  border-top: 1px solid var(--brand-gray) !important;
  padding: 1.10833rem 0px 1.10833rem 0px !important;
  vertical-align: middle !important;
}

.adp-summary {
  padding: 0 !important;
  margin: 1.10833rem 0px 1.10833rem 0px !important;
  font-weight: normal !important;
}

.adp-legal {
  font-size: 0.64137rem !important;
  padding-top: 1.5rem !important;
}

td.adp-substep[jstcache="31"] {
  display: none !important;
}

td.adp-substep[jstcache="32"],
td.adp-text[jstcache="22"] {
  padding-left: 1.10833rem !important;
}

td.adp-text[jstcache="22"] {
  padding: 1.10833rem 0 !important;
}

div.adp-distance[jstcache="33"] {
  padding: 0 0.64137rem !important;
}

.infobox {
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  position: relative;
  max-width: 630px;
  min-width: 630px !important;

  @mixin box-shadow 0px, 2px, 5px, 0px, rgba(0,0,0,0.2);
}

img[src="https://assets.simon.com/content/maps/infobox-close.jpg"] {
  display: none;
}

.infobox-close {
  position: absolute;
  right: 12px;
  top: 12px;
  padding: 0;
}

.infobox-close svg {
  fill: #757575;
}

.infobox-nav {
  display: flex;
  flex-direction: column;
}

.infobox-img {
  flex: 1;
  max-width: 140px;
}

.infobox-btns {
  display: flex;
  flex-direction: row;
  background-color: var(--gray);
  height: 50px;
}

.infobox-btn {
  display: flex;
  flex: 1;
  position: relative;
  align-items: center;
}

.infobox-btn svg {
  fill: var(--brand-gray);
  margin: 0 auto;

  @mixin transition;

  &:hover {
    fill: var(--white);
  }
}

.infobox-btn:first-child::after {
  background: var(--brand-gray);
  content: "";
  height: 70%;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
}

.infobox-text {
  flex: 1;
  padding: 23px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.infobox-list {
  margin-top: 0;
}

.infobox-list .list-inline-item {
  width: calc(50% - 0.9256rem);
}

.infobox-list .list-inline-item:nth-child(even) {
  margin-right: 0;
}

@media (--sm-viewport-down) {
  .infobox {
    flex-direction: column;
    margin-left: 15px;
    margin-right: 15px;
    max-width: 290px;
    min-width: 290px !important;
  }

  .infobox-nav {
    order: 1;
  }

  .infobox-list .list-inline-item {
    width: 100%;
  }
}
