/*
 * Forms
 *
 * Textual form controls
 */

@import "password";

/* Floating Animations */

@keyframes floatIn {
  0% {
    transform: translateX(0) translateY(-50%);
    opacity: 0;
  }

  30% {
    transform: translateX(0) translateY(-50%);
    opacity: 0;
  }

  68%,
  72% {
    opacity: 0;
    transform: translateX(0) translateY(-50%);
  }

  100% {
    transform: translateX(0) translateY(-41px);
    opacity: 1;
    font-size: 11px;
  }
}

@keyframes floatOut {
  0% {
    transform: translateX(0) translateY(-40px);
    opacity: 1;
    font-size: 11px;
  }

  30% {
    opacity: 0;
    transform: translateX(0) translateY(0);
    font-size: 0.7696rem;
  }

  68%,
  72% {
    transform: translateX(1.2731rem) translateY(-50%);
    opacity: 0;
    font-size: 0.7696rem;
  }

  100% {
    transform: translateX(1.2731rem) translateY(-50%);
    opacity: 1;
    font-size: 0.7696rem;
  }
}

.form-control {
  appearance: none;
  backface-visibility: hidden;
  background-color: transparent; /* var(--input-bg) */
  background-image: none;
  border: var(--input-btn-border-width) solid var(--input-border-color);
  border-radius: 0;
  color: var(--input-color);
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  font-size: var(--input-font-size);
  font-weight: normal;
  letter-spacing: -0.01em;
  line-height: var(--body-line-height);
  padding: 0 var(--input-padding-x);
  width: 100%;

  @include transition(box-shadow 0.3s, ease-in-out);

  /* Don't restrict textarea height */

  &:not(textarea) {
    height: var(--input-height);
    min-height: var(--input-height);
  }

  /* No padding top/bottom for selects - centers better */

  &:not(select) {
    padding: var(--input-padding-y) var(--input-padding-x);
  }

  /* Unstyle the caret on `<select>`s in IE10+. */

  &::-ms-expand,
  &::-ms-clear,
  &::-ms-reveal {
    display: none;
  }

  /* Placeholders */

  &::placeholder {
    color: var(--input-color-placeholder);
    letter-spacing: -0.01em;
    line-height: var(--body-line-height);
    opacity: 1;

    @include transition(color, 0.3s, ease-in-out);
  }

  &:focus,
  &:active {
    border-color: var(--input-border-color-active);

    @include box-shadow(0, 0, 0, 1px, var(--black), true);
    @include transition(box-shadow, 0.3s, ease-in-out);

    &::placeholder {
      color: var(--input-color-placeholder-active);
    }
  }

  /* Disabled and read-only inputs
   *
   * HTML5 says that controls under a fieldset > legend:first-child won't be
   * disabled if the fieldset is disabled. Due to implementation difficulty, we
   * don't honor that edge case; we style them as disabled anyway.
   */

  &:disabled,
  &[readonly] {
    background-color: var(--input-bg-disabled);
    opacity: 1; /* iOS fix for unreadable disabled content */
  }

  &:disabled {
    cursor: var(--cursor-disabled);
  }
}

/* Selects */

select.form-control {
  appearance: none;
  background-image: var(--icon-down-arrow);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 20px 20px;
  color: var(--input-color-placeholder);
  padding-right: 2.75rem;
  cursor: pointer;
  text-indent: 0.01px;
  text-overflow: "";

  &:not([size]):not([multiple]) {
    height: 53px; /* calc(3.06rem + 2px) */
  }
}

/* Remove custom dropdown arrow for multi selects */

select[multiple].form-control {
  background-image: none;
  height: auto;
  padding: var(--input-padding-y) var(--input-padding-x);
}

/* Make file inputs better match text inputs by forcing them to new lines. */

.form-control-file,
.form-control-range {
  display: block;
  font-size: var(--input-font-size);
  margin: var(--spacer) 0;
  width: 100%;
}

/* File - Custom file input. */

.file {
  cursor: pointer;
  display: inline-block;
  font-size: var(--input-font-size);
  height: var(--input-height);
  max-width: 100%;
  position: relative;
}

.file-input {
  filter: alpha(opacity = 0);
  margin: 0;
  max-width: 100%;
  min-width: var(--file-input-min-width);
  opacity: 0;
}

.file-control {
  background-color: var(--input-bg);
  border: var(--input-btn-border-width) solid var(--input-border-color);
  color: var(--input-color);
  font-weight: normal;
  height: var(--input-height);
  left: 0;
  line-height: var(--body-line-height);
  padding: var(--input-padding-y) var(--input-padding-x);
  position: absolute;
  right: 0;
  top: 0;
  user-select: none;
  z-index: 1;

  @include transition(background-color);

  &::after {
    content: var(--file-input-placeholder);
  }

  &::before {
    background-color: var(--file-button-background);
    border: var(--input-btn-border-width) solid var(--file-button-background);
    bottom: -var(--input-btn-border-width);
    color: var(--file-button-color);
    content: var(--file-button-label);
    display: block;
    font-family: Arial, Helvetica, sans-serif;
    height: var(--input-height);
    letter-spacing: 0.05em;
    line-height: var(--body-line-height);
    padding: var(--input-padding-y) var(--input-padding-x);
    position: absolute;
    right: -var(--input-btn-border-width);
    top: -var(--input-btn-border-width);
    z-index: 2;

    @include transition(background-color);
  }

  &:hover {
    &::before {
      background-color: var(--btn-primary-bg-active);
      border-color: var(--btn-primary-border);
    }
  }
}

/* Labels
 *
 * For use with horizontal and inline forms, when you need the label text to
 * align with the form controls.
 */

.form-control-label {
  margin-bottom: 0; /* Override the `<label>` default */
  padding: var(--input-padding-y) var(--input-padding-x);
}

label {
  color: var(--input-color-placeholder);
  display: inline-block;
  margin-bottom: 0;

  /* Empty labels collapse automatically */

  &:empty {
    display: none;
  }

  /* Floating label class to hide and show label as an input is being filled/emptied */

  &.floating {
    top: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    transition: all 0.2s ease-in;

    &.up {
      top: -16px;
      opacity: 1;
    }

    &.floating-accessibly {
      opacity: 1;
      top: 50%;
      left: 0;
      position: absolute;
      transform: translateX(1.2731rem) translateY(-50%);
      font-size: 0.7696rem;
      margin: 0;

      &.up {
        transform: translateX(0) translateY(-41px);
        opacity: 1;
        font-size: 11px;
      }

      &.up-ta {
        transform: translateX(0) translateY(-73px);
      }

      &.down {
        transform: translateX(1.2731rem) translateY(-50%);
        opacity: 1;
        font-size: 0.7696rem;
        z-index: -1;
      }

      & + .form-control,
      & + div > .form-control,
      & + input + .form-control,
      & + .phone-number-prefix + .form-control {
        background-color: #fff;
      }

    }

    &.up.has-error > .floating-desc {
      display: inline-block;
    }

    &.down > .floating-desc {
      display: none;
    }
  }

  /* Hide floating label error message unless on error */

  .floating-desc {
    display: none;
  }
}

/* Static form control text
 *
 * Apply class to an element to make any string of text align with labels in a
 * horizontal form layout.
 */

.form-control-static {
  margin-bottom: 0;
  min-height: var(--input-height);
  padding-bottom: var(--input-padding-y);
  padding-top: var(--input-padding-y);

  &.form-control-sm,
  &.form-control-lg {
    padding-left: 0;
    padding-right: 0;
  }
}

/* Hack to make this CSS be Safari-only */

_::-webkit-full-page-media,
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  &.form-control {
    line-height: var(--input-height);
  }
}

/* Form header */

.form-header {
  margin-bottom: var(--form-header-margin-mobile);
  text-align: center;

  @media(--md-viewport-up) {
    text-align: left;
    border-bottom: var(--form-header-border-width) solid var(--form-header-border-color);
    margin-bottom: var(--form-header-margin-desktop);
    padding-bottom: var(--spacer);
  }

  @media(--lg-viewport-up) {
    font-size: var(--form-header-font-size-lg);
  }
}

/* Form groups
 *
 * Designed to help with the organization and spacing of vertical forms. For
 * horizontal forms, use the predefined grid classes.
 */

.form-group {
  margin-bottom: var(--form-group-margin-bottom);
  position: relative;
}

.form-with-header {

  @media (--md-viewport-down) {
    margin-top: var(--form-group-no-header-top-margin);
  }
}

/* Current fix for initial form group with floating label and any items within form group that won't work with absolute positioning of label
 * Adds extra margin for the first form group element. If the form has a form header then use .form-with-header class on the <form> to cancel this out
 */

*:not(.form-group) + .form-group,
form:not(.form-inline, .form-with-header) > .form-group:first-child {
  margin-top: var(--form-group-no-header-top-margin);
}

/* Checkboxes and Radios
 *
 * Indent the labels to position radios/checkboxes as hanging controls.
 */

.radio,
.checkbox {
  display: block;
  margin-bottom: var(--spacer);
  position: relative;

  label {
    color: var(--checkbox-color);
    cursor: pointer;
    display: inline-block;
    font-size: var(--input-font-size);
    margin-bottom: 0;
    padding-left: 1.5rem;

    /* When there's no labels, don't position the input. */

    input:only-child {
      position: static;
    }
  }
}

/* Indicator is the custom span tag used for custom check and circle on selected boxes and buttons */

.checkbox .c-indicator,
.radio .r-indicator,
.checkbox-inline .c-indicator,
.radio-inline .r-indicator {
  background: var(--input-bg) none no-repeat center;
  background-size: 0;
  border: var(--input-btn-border-width) solid var(--input-border-color);
  display: block;
  height: var(--checkbox-size);
  left: 0;
  position: absolute;
  width: var(--checkbox-size);
}

.checkbox .c-indicator,
.checkbox-inline .c-indicator {

  @include transition(background-size, 0.2s);
}

.radio .r-indicator,
.radio-inline .r-indicator {
  border-radius: calc(var(--checkbox-size) / 2);
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  opacity: 0;
  left: -9999px;
  margin-left: -1.25rem;
  margin-top: 0.25rem;
  position: absolute;

  &:checked ~ .c-indicator,
  &:checked ~ .r-indicator {
    background-position: 50%;
    background-size: 11px 7px;
    border-color: var(--input-border-color-active);
  }
}

/* Custom radio button image */

.radio input[type="radio"]:checked ~ .r-indicator::after,
.radio-inline input[type="radio"]:checked ~ .r-indicator::after {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  border-radius: 50%;
  background-color: var(--black);
}

/* Custom check image */

.checkbox input[type="checkbox"]:checked ~ .c-indicator,
.checkbox-inline input[type="checkbox"]:checked ~ .c-indicator {
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4wLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSItMzc5LjQgMzAzLjcgMTAuMyA3LjMiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgLTM3OS40IDMwMy43IDEwLjMgNy4zOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8Zz4NCgk8cGF0aCBkPSJNLTM3NS41LDMxMUMtMzc1LjUsMzExLTM3NS41LDMxMS0zNzUuNSwzMTFjLTAuMywwLTAuNSwwLTAuNy0wLjJsLTMuMS0zLjJsMS40LTEuNGwyLjQsMi41bDUuMS01bDEuNCwxLjRsLTUuOCw1LjcNCgkJQy0zNzUsMzExLTM3NS4zLDMxMS0zNzUuNSwzMTF6Ii8+DQo8L2c+DQo8L3N2Zz4NCg==") 50% 50%/11px 7px no-repeat;
}

.radio + .radio,
.checkbox + .checkbox {

  /* Move up sibling radios or checkboxes for tighter spacing */
  margin-top: -0.25rem;
}

/* Radios and checkboxes on same line */

.radio-inline,
.checkbox-inline {
  cursor: pointer;
  display: inline-block;
  margin-bottom: 0;
  padding-left: 1.25rem;
  position: relative;
  vertical-align: middle;
}

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-left: 0.75rem;
  margin-top: 0;
}

/* Apply same disabled cursor tweak as for inputs
 *
 * Some special care is needed because <label>s don't inherit their parent's `cursor`.
 *
 * Note: Neither radios nor checkboxes can be readonly.
 */

input[type="radio"],
input[type="checkbox"] {
  &:disabled,
  &.disabled {
    cursor: var(--cursor-disabled);
  }
}

/* These classes are used directly on <label>s */

.radio-inline,
.checkbox-inline {
  &.disabled {
    cursor: var(--cursor-disabled);
  }
}

/* These classes are used on elements with <label> descendants */

.radio,
.checkbox {
  &.disabled {
    label {
      color: var(--input-color-placeholder);
      cursor: var(--cursor-disabled);
    }
  }
}

/* Validation States - success = teal; error = plum */

.has-success {
  color: var(--brand-success);

  @include form-control-validation(var(--brand-success));
}

.has-error,
.parsley-error {
  color: var(--brand-error);

  @include form-control-validation($brand-error);
}

/* Inline forms */

.form-inline {

  /* Kick in the inline onle for < 768px */

  @media (--sm-viewport-up) {

    /* Inline-block all the things for "inline" */

    .btn {
      margin-top: -1px;
    }

    .form-group {
      display: inline-block;
      margin-bottom: 0;
      vertical-align: middle;
    }

    .form-group + .form-group {
      margin-left: 1.396rem;
    }

    label {
      font-size: var(--input-font-size);
    }

    /* Allow folks to *not* use `.form-group */

    .form-control {
      display: inline-block;
      vertical-align: middle;
      width: auto;
    }

    /* Make static controls behave like regular ones */

    .form-control-static {
      display: inline-block;
    }

    .form-control-label {
      margin-bottom: 0;
      vertical-align: middle;
    }

    /* Remove default margin on radios/checkboxes that were used for stacking, and then undo the floating of radios and checkboxes to match. */

    .radio,
    .checkbox {
      display: inline-block;
      margin-bottom: 0;
      margin-top: 0;
      vertical-align: middle;

      label {
        padding-left: 0;
      }
    }

    .radio input[type="radio"],
    .checkbox input[type="checkbox"] {
      margin-left: 0;
      position: relative;
    }
  }
}

/* Credit Card / Payment form styles */

.credit-card-number {
  position: relative;
}

/* Credit card icon that appears inside the input while credit information is being entered */

.payment-method-icon {
  height: 32px;
  margin-top: -16px;
  position: absolute;
  right: var(--input-padding-y);
  top: 50%;
  width: 50px;
}

/* Phone Number Styles */

.phone-number {
  display: flex;

  & > input {
    flex: 1;
    min-width: 0; /* for firefox to negate input width 100% */
    width: auto; /* for firefox to negate input width 100% */
  }

  & .phone-number-prefix {
    background: var(--input-bg-disabled);
    border: var(--input-btn-border-width) solid var(--input-border-color);
    border-right: none;
    box-shadow: none;
    cursor: var(--cursor-disabled);
    width: auto;
    padding: var(--input-padding-y) var(--form-phone-prefix);
  }
}

/* Norton and Security Lock */

.form-security {
  margin: 22px 0px;

  .lock-text {
    font-size: 0.76968rem;
  }

  @media (--lg-viewport-down) {
    width: 100%;
    margin: -8px 0 22px;
  }
}

/* Google reCaptcha */

@media (--sm-viewport-down) {
  .g-recaptcha iframe {
    max-width: 100%;
    transform: scale(0.96);
    transform-origin: left;
  }

  #rc-imageselect {
    transform: scale(0.96);
    transform-origin: 0 0;
  }

  .modal-captcha .g-recaptcha iframe {
    transform: scale(0.75);
  }

  .modal-form .modal-captcha .g-recaptcha iframe {
    transform: scale(0.79);
  }
}

/* Sortables */

.form-sortables {
  display: flex;
  flex-direction: column;
}

.sortable {
  flex: 1;
  cursor: pointer;

  &-primary {
    margin-bottom: 1.596rem;
  }

  &-active::after {
    content: "CLICK TO SWAP PRIMARY";
    display: block;
    color: #757575;
    width: 100%;
    position: relative;
    bottom: -42px;
    left: -1.2731rem;
    font-size: 0.6414rem;
    height: 0;
  }

  &:not(.sortable-primary) {
    order: 2;
    margin-top: 0.798rem;
  }

  &:first-child {
    margin-top: 0;
  }
}
