/* Core Variables and Mixins */

@import "../../ga/styles/partials/variables";
@import "../../ga/styles/partials/mixins";

/* Basic foundation reset and print styles */

@import "../../ga/styles/partials/foundation";
@import "../../ga/styles/partials/print";

/* Core CSS
 *
 * NOTE - _type.css: Contains _typescale.css: written and compiled from scss folder
 * DO NOT EDIT _grid.css - Written and compiled from scss folder
 */

@import "../../ga/styles/partials/type";
@import "../../ga/styles/partials/lists";
@import "../../ga/styles/partials/images";
@import "../../ga/styles/partials/grid";
@import "../../ga/styles/partials/tables";
@import "../../ga/styles/partials/forms";
@import "../../ga/styles/partials/buttons";
@import "../../ga/styles/partials/accessibility";

/* Compontnets */

@import "../../ga/styles/partials/animations";
@import "../../ga/styles/partials/close";
@import "../../ga/styles/partials/dropdowns";
@import "../../ga/styles/partials/jumbotrons";
@import "../../ga/styles/partials/nav";
@import "../../ga/styles/partials/navbar";
@import "../../ga/styles/partials/footers";
@import "../../ga/styles/partials/modals";
@import "../../ga/styles/partials/accordion";
@import "../../ga/styles/partials/search";
@import "../../ga/styles/partials/sections";
@import "../../ga/styles/partials/pagination";
@import "../../ga/styles/partials/weather";
@import "../../ga/styles/partials/cards";
@import "../../ga/styles/partials/slider";
@import "../../ga/styles/partials/video";
@import "../../ga/styles/partials/icons";
@import "../../ga/styles/partials/spo";
@import "../../ga/styles/partials/headerfooter";
@import "../../ga/styles/partials/sidenav";

/* Components with JS */

@import "../../ga/styles/vendor/jqueryui/jqueryui-base";
@import "../../ga/styles/vendor/googlemaps/googlemaps";
@import "../../ga/styles/vendor/smartbanner/smartbanner";

/* App Specific */

@import "partials/homepage";
@import "partials/search";
@import "partials/national-landing-page";
@import "partials/legal";
@import "partials/404";
@import "partials/mall";
@import "partials/holiday";
@import "partials/travel";
@import "partials/simon-search";
@import "partials/print";
@import "partials/nosto-tabs";

/* Site Content - below header, above footer */

/*a:focus {
    outline: 3px solid #000000;
}
*/
main {
    margin-top: calc(var(--navbar-global-height) + var(--navbar-height) + var(--navbar-sub-height));

    &.mall-alert-active {
        margin-top: calc(var(--navbar-global-height) + var(--navbar-sub-height) + var(--navbar-height) + var(--mall-alert-height));
    }
}

@media only screen and (max-width: 992px) {
    main {
        margin-top: calc(var(--navbar-global-height) + var(--navbar-mobile-height));
    }

        main.mall-alert-active {
            margin-top: calc(var(--navbar-global-height) + var(--mall-alert-height) + var(--navbar-mobile-height));
        }
}

@media only screen and (max-width: 700px) {
    main {
        margin-top: calc(var(--navbar-global-height) + var(--navbar-mobile-height));
    }
}

@media (min-width: 1000px) {
    .montreal main {
        margin-top: var(--navbar-height);
    }
}

@media only screen and (max-width: 360px) {


    main.mall-alert {
        margin-top: calc(var(--navbar-global-height) + var(--mall-alert-height) + var(--navbar-mobile-height) + 2rem);
    }

    main.mall-alert-active {
        margin-top: calc(var(--navbar-global-height) + var(--mall-alert-height) + var(--navbar-mobile-height) + 2rem);
    }
}

/* Utilities */

@import "../../ga/styles/partials/utilities";
