/*
 * Accessibility - for accessibility features / improvements across multiple Simon sites
 */

.skip-link {
  position: fixed;
  top: -20rem;
  left: 10px;
  z-index: -99999;
  background-color: #fff;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  color: var(--gray);
}

.skip-link:focus,
.skip-link:active {
  top: 10px;
  z-index: 99999;
  color: #000;
}

body:not(.mall-map-view) main a:not(.btn):focus,
.legal-text a:focus,
.dropdown-item a:not(.btn):focus {
  color: var(--brand-primary);
}

a.text-gray:focus {
  color: var(--black);
}
