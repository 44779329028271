/* Enhanced password fields */
.show-password-container {
  position: relative;
  display: flex;

  & .form-control {
    border-right: none;
    padding-right: 0;
  }

  & .show-password-icon,
  & .hide-password-icon {
    border: 1px solid var(--gray);
    border-left: none;
    padding-left: 0.675rem;
    padding-right: 0.675rem;
    flex-grow: 0;
    background-color: var(--white);

    & > svg {
      width: 1.5rem;
      height: auto;
      fill: var(--gray);
      display: block;
    }

    &:hover > svg,
    &:focus > svg {
      fill: var(--black);
    }
  }
}

.has-error input ~ .show-password-icon,
.has-error input ~ .hide-password-icon,
.has-error input:focus ~ .show-password-icon,
.has-error input:focus ~ .hide-password-icon,
.has-error input:active ~ .show-password-icon,
.has-error input:active ~ .hide-password-icon {
  border-color: var(--brand-primary);
}

input:focus ~ .show-password-icon,
input:focus ~ .hide-password-icon,
input:active ~ .show-password-icon,
input:active ~ .hide-password-icon {
  border-color: var(--black);
}

.password-requirements {
  margin-bottom: var(--form-group-margin-bottom);
  margin-top: -0.75rem;

  & .password-requirements-met {
    color: #000;
    background-color: rgba(108, 194, 74, 0.5);
    font-size: 0.7696rem;
    padding: 0.5rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -0.5rem;
    margin-bottom: -0.5rem;

    &::before {
      content: "";
      display: inline-block;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0iYmxhY2siIHdpZHRoPSIxOHB4IiBoZWlnaHQ9IjE4cHgiPjxwYXRoIGQ9Ik0wIDBoMjR2MjRIMFYwem0wIDBoMjR2MjRIMFYweiIgZmlsbD0ibm9uZSIvPjxwYXRoIGQ9Ik0xNi41OSA3LjU4TDEwIDE0LjE3bC0zLjU5LTMuNThMNSAxMmw1IDUgOC04ek0xMiAyQzYuNDggMiAyIDYuNDggMiAxMnM0LjQ4IDEwIDEwIDEwIDEwLTQuNDggMTAtMTBTMTcuNTIgMiAxMiAyem0wIDE4Yy00LjQyIDAtOC0zLjU4LTgtOHMzLjU4LTggOC04IDggMy41OCA4IDgtMy41OCA4LTggOHoiLz48L3N2Zz4=");
      background-size: 100% 100%;
      width: 1rem;
      height: 1rem;
      margin-right: 0.25rem;
    }
  }

  & .password-requirements-validation {
    position: relative;
    width: 100%;
    height: 100%;
  }
}

.password-requirements p {
  display: flex;
  align-items: center;
  font-size: 0.66rem;

  &::before {
    content: "";
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0iIzZlNmU2ZSIgd2lkdGg9IjE4cHgiIGhlaWdodD0iMThweCI+PHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjxwYXRoIGQ9Ik0xMiAyQzYuNDcgMiAyIDYuNDcgMiAxMnM0LjQ3IDEwIDEwIDEwIDEwLTQuNDcgMTAtMTBTMTcuNTMgMiAxMiAyeiIvPjwvc3ZnPg==");
    width: 0.75rem;
    height: 0.75rem;
    display: inline-block;
    margin-right: 0.3rem;
    flex-shrink: 0;
    background-size: 60% 60%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    position: relative;
    top: -1px;
  }

&.pass::before {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0iIzMzYjUwMCIgd2lkdGg9IjE4cHgiIGhlaWdodD0iMThweCI+PHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjxwYXRoIGQ9Ik05IDE2LjJMNC44IDEybC0xLjQgMS40TDkgMTkgMjEgN2wtMS40LTEuNEw5IDE2LjJ6Ii8+PC9zdmc+DQo=");
    background-size: 100% 100%;
}
}

.password-requirements.error p:not(.pass) {
  color: var(--brand-primary);

  &::before {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0iIzlmMDA1YiIgd2lkdGg9IjE4cHgiIGhlaWdodD0iMThweCI+PHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjxwYXRoIGQ9Ik0xOSA2LjQxTDE3LjU5IDUgMTIgMTAuNTkgNi40MSA1IDUgNi40MSAxMC41OSAxMiA1IDE3LjU5IDYuNDEgMTkgMTIgMTMuNDEgMTcuNTkgMTkgMTkgMTcuNTkgMTMuNDEgMTJ6Ii8+PC9zdmc+");
    background-size: 100% 100%;
  }
}
