.btn-holiday {
  @mixin button-variant #ffffff, #d81f26 !important, #d81f26 !important, #ffffff, #e8797d !important, transparent;
}

.text-holiday {
  color: #d81f26 !important;
}

.holiday-red-bg {
  background-color: #DD2229 !important;

  .text-white {
    color: #fff;
  }
}

.homepage-content .container-fluid {
  background-color: #d81f26;
}

.holiday-red {
  color: #d20b37 !important;
}

.content-stream-hero-holiday {
  color: #ffffff;
}

.simon-section-holiday {
  background: var(--holiday-red) url(https://assets.simon.com/htmlcontent/RSC-snowflake-pattern-1000x1500.jpg);
  background-size: 750px;

  @media(--md-viewport-down) {
    background-size: 100%;
  }

  .line {
    background-color: var(--holiday-red);
  }

  .nav-pills {
    .nav-link {
      color: var(--white);
    }

    .nav-item {
      border-bottom: 1px solid var(--white);
      border-top: 1px solid var(--white);

      &:not(:first-child) {
        border-left: 1px solid var(--white);
      }

      &:first-child {
        border-left: 1px solid var(--white);
      }

      &:last-child {
        border-right: 1px solid var(--white);
      }

      :focus {
        box-shadow: inset 0 0 0 1px var(--white);
      }
    }

    .nav-link.active,
    .nav-item.open .nav-link {
      &,
      &:hover {
        background-color: rgba(0,0,0,0.25);
        color: var(--white);
      }

      &:focus {
        color: var(--gray-light);
      }
    }
  }

  select.form-control {
    color: var(--white);
    border-color: var(--white);
    background-image: var(--icon-down-arrow-white);

    &:focus {
      box-shadow: inset 0 0 0 1px var(--white);
    }

    & option,
    & optgroup {
      color: var(--black);
    }
  }

  .btn {
    background-color: var(--white);
    color: var(--holiday-red);

    &:hover,
    &:focus {
      background-color: var(--gray-light);
    }
  }

  .directory-print .text, .directory-sort .text {
    color: var(--white);
  }
  .directory-print .icon, .directory-sort .icon {
    fill: var(--white);
  }

  .logo-loader {
    color: var(--white);
  }

  .simon-logo-path {
    stroke: var(--white);
  }
}