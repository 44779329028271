/*
 * Cards
 */

/* Container */

.card {
  background-color: var(--card-bg);
}

/* No underline for elements inside link */

.card-link {
  text-decoration: none;
}

/* Image of the card */

.card-img {

  @mixin img-fluid;
}

/* All of the text content under the card image */

.card-details {
  padding: var(--card-padding-y) var(--card-padding-x);

  /* Careful, this breaks the word wherever it is without a hyphen */
  overflow-wrap: break-word;
  word-wrap: break-word;

  /* Adds a hyphen where the word breaks */
  hyphens: auto;
}

/* Card Title */

.card-title {
  margin-bottom: 0.6rem;
  position: relative;
  top: -3px;
}

/* Card Description */

.card-desc {
  margin-bottom: 0;
}

/* Card Footer - End of card details including social, times, dates etc */

.card-footer {
  margin-top: 2.85rem;
  color: var(--card-footer-color);
  letter-spacing: 0.05em;
  font-size: var(--card-footer-font-size);
  position: relative;
  bottom: -3px;
}

/* Card Icon - currently used for small calendar icon. If it's a social icon, make it the same size as the ones in the footer */

.card-icon {
  width: var(--card-icon-size);
  height: var(--card-icon-size);
  fill: var(--card-footer-color);
  margin-right: 7px;
  display: inline-block;

  &.social-icon {
    height: var(--footer-social-icon-size);
    width: var(--footer-social-icon-size);
  }
}

.card-secondary {
  text-align: center;
  background-color: var(--white);
  margin-bottom: 30px;

  @media(--sm-viewport-down) {
    text-align: left;
    margin-bottom: 0;
    display: flex;
    border-bottom: 1px solid var(--brand-gray);
    padding: var(--spacer);
    width: 100%;

    &-desc {
      font-size: var(--base-font-size);
      font-family: Arial, Helvetica, sans-serif;
    }

    &-title,
    &-desc {
      margin-bottom: 6px;
    }
  }

  &-img {
    border-bottom: 1px solid var(--brand-gray);

    @media(--sm-viewport-down) {
      border: none;
      width: 75px;
      height: 75px;
      display: inline-block !important;
    }
  }

  &-no-img {
    position: relative;
    width: 50px;
    height: 50px;
    display: inline-block !important;
  }

  &-no-img-text {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);

    @media(--sm-viewport-down) {
      font-size: 0.64137rem;
    }
  }

  &-text {
    padding: 1.596rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media(--sm-viewport-down) {
      display: inline-block;
      padding: 0 0 0 1.596rem;
      align-self: center;
      max-width: calc(100vw - 80px - 1.596rem);
    }
  }

  &-btn {
    padding: var(--spacer);
    background-color: var(--brand-gray);
    color: var(--white);
    display: block;
    font-size: 0.769rem;

    @mixin transition;
  }

  &-btn:hover {
    background-color: var(--gray);
  }

  &-arrow {
    align-self: center;
    position: absolute;
    right: var(--spacer);
  }
}

.blob {
  position: relative;

  @media (--md-viewport-down) {
    margin-bottom: 1.9152rem;
  }

  &-title {
    margin-bottom: 0.4rem;
    line-height: 0.77;

    @media (--lg-viewport-down) {
      font-size: var(--font-size-h5);
      line-height: 0.82;
    }
  }

  &-desc {
    margin-bottom: 0;
    line-height: 1.1;
  }

  &-text {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 1.7rem 1.9152rem;
    background: rgba(255, 255, 255, 0.75);
  }
}
