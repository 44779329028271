/*
 * Lists
 */

/* No margin on child list elements - <li> in parents handle that */

ul,
ol {
  ul,
  ol {
    margin-bottom: 0;
  }
}

.list {
  padding: 0 var(--spacer);
}

.list-unstyled {

  /* JEREMY TESTING */

  @include vertical-list-spacer;
  @include list-unstyled;
}

/* Inline turns list items into inline-block */

.list-inline,
.list-blocks {

  @include list-unstyled;
}

.list-inline-item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: var(--list-inline-padding);
  }
}

/* List with plum diamonds as the bullet icon */

.list-diamond {

  @include vertical-list-spacer;
  display: inline-block;
  fill: var(--diamond-list-bullet-color);
  height: var(--list-bullet-size);
  margin-right: calc(var(--spacer) / 2);
  width: var(--list-bullet-size);
}

.list-blocks {
  margin-bottom: 0;
}

.list-blocks-item {
  background-color: var(--gray-lighter);
  color: var(--gray);
  padding: 10px;
  display: inline-block;
  margin-bottom: 4px;

  &:hover {
    background-color: var(--brand-gray);
    color: var(--white);

    @include transition(background-color);
  }
}

dl.dl-inline dt {
  float: left;
  clear: left;
  margin-right: 0.3rem;
}

dl.dl-inline dd {
  margin-left: 0px;
}
