/*
 * Slick Slider Base stlyes
 */

.slick-slider {
  position: relative;
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-track::before,
.slick-track::after {
  display: table;
  content: "";
}

.slick-track::after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

/* .slick-loading .slick-list {
  background: #fff url(var(--icon-loader)) center center no-repeat;
} */

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  opacity: 0.75;
  transform: translateY(-50%);
  z-index: 2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  opacity: 1;
}

.slick-prev.slick-disabled,
.slick-next.slick-disabled {
  opacity: 0.25;
}

.slick-prev {
  background-image: var(--icon-left-arrow);
  background-repeat: no-repeat;
  left: 0px;
}

[dir="rtl"] .slick-prev {
  right: 0px;
  left: auto;
}

.slick-next {
  background-image: var(--icon-right-arrow);
  background-repeat: no-repeat;
  background-position: right;
  right: 0;
}

[dir="rtl"] .slick-next {
  right: auto;
  width: 285px;
  left: 0;
}

.slick-dots {
  text-align: center;
  margin-top: 1rem;
  padding: 0;
  margin-bottom: 0;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 2px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover::before,
.slick-dots li button:focus::before {
  opacity: 1;
}

.slick-dots li button::before {
  font-family: Arial, Helvetica, sans-serif;
  font-size: var(--base-font-size);
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button::before {
  opacity: 0.75;
  color: black;
}
