/* Jumbotrons - for hero content / full page main body images */

.jumbotron {
  padding: var(--jumbotron-padding);
}

.jumbotron-hr {
  border-top-color: darken(var(--jumbotron-bg), 10%);
}

.jumbotron-fluid {
  padding-left: 0;
  padding-right: 0;
}

.jumbotron-hero,
.jumbotron-banner {
  display: flex;
  flex-direction: row;
  text-align: center;
  height: 300px;
  position: relative;

  & > *:not(.jumbotron-bg) {
    z-index: 2;
    width: 100%;
  }

  @media (--lg-viewport-up) {
    height: 400px;
  }

  .container,
  .container-fluid {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.jumbotron-title {
  font-size: var(--font-size-h3);
  margin-bottom: 0;
  line-height: 0.77;

  @media (--sm-viewport-up) {
    font-size: var(--font-size-h1);
  }

  @media (--lg-viewport-up) {
    font-size: var(--display4-size);
  }
}

.jumbotron-desc {
  font-size: var(--base-font-size);
  margin-bottom: var(--font-size-xsmall);

  @media (--sm-viewport-up) {
    margin-bottom: var(--font-size-h6);
  }

  @media (--lg-viewport-up) {
    font-size: var(--font-size-h3);
    margin-bottom: var(--font-size-h3);
    margin-top: -2px;
  }
}

.jumbotron-desc-long {
  margin-bottom: var(--font-size-xsmall);

  @media (--sm-viewport-up) {
    margin-bottom: var(--font-size-h6);
  }

  @media (--lg-viewport-up) {
    font-size: var(--font-size-h4);
    margin-bottom: var(--font-size-h4);
  }
}

.jumbotron-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;

  & img.container-width {
    min-height: 400px;
    width: auto;
    margin: 0 auto;

    @media (--lg-viewport-down) {
      min-height: 300px;
    }
  }
}

.jumbotron-banner {

  @media (--md-viewport-down) {
    display: block;
    height: 100%;
    padding-top: 0;
    padding-bottom: 1.9152rem;
  }

  .jumbotron-title {
    margin-bottom: 1.596rem;

    @media (--lg-viewport-up) {
      margin-bottom: 1.33rem;
    }
  }

  .jumbotron-desc {
    margin-bottom: 1.596rem;

    @media (--lg-viewport-up) {
      font-size: var(--font-size-h5);
      margin-bottom: 1.9152rem;
    }
  }

  .jumbotron-bg {

    @media (--md-viewport-down) {
      position: relative;
      height: 300px;
      margin-bottom: 1.9152rem;
      background-color: var(--white);
      display: block;
    }
  }

  .btn {
    margin: 0 auto;
  }
}

.jumbotron-banner-no-stack {

  @media (--md-viewport-down) {
    display: flex;
    height: 300px;
    padding: var(--jumbotron-padding);
  }

  .jumbotron-title {
    margin-bottom: 0;

    @media (--sm-viewport-up) {
      margin-bottom: 0;
    }

    @media (--lg-viewport-up) {
      margin-bottom: 0;
    }
  }

  .jumbotron-desc {
    margin-bottom: var(--font-size-xsmall);

    @media (--sm-viewport-up) {
      margin-bottom: var(--font-size-h6);
    }

    @media (--lg-viewport-up) {
      font-size: var(--font-size-h3);
      margin-bottom: var(--font-size-h3);
    }
  }

  .jumbotron-bg {

    @media (--md-viewport-down) {
      position: absolute;
      height: 100%;
      margin-bottom: 0;
      background-color: transparent;
      display: block;
    }
  }
}
