/* Base class
 *
 * Kickstart any navigation component with a set of style resets. Works with
 * `<nav>`s or `<ul>`s.
 */

.nav {
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
}

.nav-link {
  color: var(--nav-link-color);
  display: inline-block;
  text-decoration: none;
  vertical-align: baseline;

  @import transition(transition all, 0.3s);

  &:hover,
  &:focus {
    color: var(--nav-link-hover-color);
    text-decoration: none;
  }

  /* Disabled state lightens text and removes hover/tab effects */

  &.disabled {
    color: var(--nav-disabled-link-color);

    &:hover,
    &:focus {
      color: var(--nav-disabled-link-hover-color);
      cursor: var(--cursor-disabled);
    }
  }

  &.active {
    color: var(--black);

    &:hover,
    &:focus {
      color: var(--nav-link-color);
    }
  }
}

/* Nav inline */

.nav-inline {
  .nav-item {
    display: inline-block;
  }

  .nav-item + .nav-item,
  .nav-link + .nav-link {
    margin-left: var(--nav-item-inline-spacer);
  }
}

/* Tabs */

.nav-tabs {
  border-top: var(--nav-tabs-border-width) solid var(--nav-tabs-border-color);
  border-right: var(--nav-tabs-border-width) solid var(--nav-tabs-border-color);
  border-left: var(--nav-tabs-border-width) solid var(--nav-tabs-border-color);
  background-color: var(--gray-light);

  @include clearfix;

  .nav-item {
    float: left;

    /* Make the list-items overlay the bottom border */
    margin-bottom: var(--nav-tabs-border-width);

    &:first-child .nav-link {
      margin-left: 0;
    }
  }

  .nav-link {
    border-bottom: var(--nav-tabs-border-width) solid transparent;
    display: block;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.7rem;
    letter-spacing: 0.05em;
    margin-left: -1px;
    padding: var(--btn-padding-y) var(--btn-padding-x);

    &:hover,
    &:focus {
      border-color: var(--nav-tabs-link-hover-border-color) var(--nav-tabs-link-hover-border-color) var(--nav-tabs-border-color);
    }

    &.disabled,
    &.disabled:hover,
    &.disabled:focus {
      background-color: transparent;
      border-color: transparent;
      color: var(--nav-disabled-link-color);
    }
  }

  .nav-link.active,
  .nav-item.open .nav-link {
    color: var(--brand-primary);
    border-color: var(--nav-tabs-active-link-hover-border-color);

    &:hover,
    &:focus {
      color: var(--nav-tabs-active-link-hover-color);
      border-color: var(--nav-tabs-active-link-hover-color);
    }
  }

  .dropdown-menu {

    /* Make dropdown border overlap tab border */
    margin-top: var(--nav-tabs-border-width);
  }
}

/* Pills */

.nav-pills {

  @include clearfix;

  .nav-item {
    border-bottom: 1px solid var(--gray);
    border-top: 1px solid var(--gray);
    float: left;
    position: relative;

    &:not(:first-child, .no-border)::before {
      background: var(--brand-gray);
      content: "";
      height: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
    }

    &:first-child {
      border-left: 1px solid var(--gray);
    }

    &:last-child {
      border-right: 1px solid var(--gray);
    }
  }

  .nav-link {
    display: block;
    font-family: Arial, Helvetica, sans-serif;
    font-size: var(--btn-font-size);
    letter-spacing: 0.05em;
    padding: var(--btn-padding-y) var(--btn-padding-x);
  }

  .nav-link.active,
  .nav-item.open .nav-link {
    &,
    &:hover {
      background-color: var(--nav-pills-active-link-bg);
      color: var(--nav-pills-active-link-color);
      cursor: default;
    }

    &:focus {
      color: var(--gray-lighter);
    }
  }

  &.flex .nav-item {
    flex: 1;
    text-align: center;
  }
}

.nav-stacked {
  .nav-item {
    display: block;
    float: none;

    + .nav-item {
      margin-left: 0;
      margin-top: 0;
    }
  }
}

/* Tabbable tabs */

/* Hide tabbable panes to start, show them when `.active` */

.tab-content {
  > .tab-pane {
    display: none;
  }

  > .active {
    display: block;
  }
}

.nav-box {
  & + *:not(.nav-box) {
    margin-top: 1.10833rem;
  }

  @media (--md-viewport-down) {
    margin-bottom: 0;
    display: flex;
    background-color: var(--gray-lighter);

    &:first-of-type {
      margin-top: 1.9152rem;
      border-bottom: 2px solid var(--brand-gray);
    }

    /* &:last-of-type {
      margin-bottom: 1.10833rem;
    } */

    & > .nav-item {
      flex: 1;
      padding: 1.5rem 0;
      text-align: center;
      text-transform: uppercase;
      font-family: "SimonCircularPro-Bold";
    }

    & .nav-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: var(--black);
    }

    .nav-item + .nav-item {
      border-left: 2px solid var(--brand-gray);
    }

    & .nav-box-icon {
      margin-bottom: var(--spacer);
    }
  }
}
