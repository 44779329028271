/*
 * HTML5 Video Styles
 */

/* Hide the Controls */

video::-webkit-media-controls,
video::-webkit-media-controls-start-playback-button {
  display: none !important;
  appearance: none;
}

video,
.video-fallback {
  width: 100%;
  object-fit: cover;
  height: 100vh;
}

.video-fallback {
  max-width: inherit;
}

@media (--md-viewport-down) {
  video,
  .video-fallback {
    height: 100vw;
  }
}

.video-container {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  height: 100vw;

  @media (--md-viewport-up) {
    height: calc(100vh - var(--navbar-height));
    position: fixed;
    width: 100%;
    top: var(--navbar-height);
    z-index: 0;
  }

}

video {
  margin: 0 auto;
  display: block;
}

.video-full {
  height: calc(100vh - var(--navbar-height));
  overflow: hidden;
}

.video-fixed {
  position: fixed;
  top: var(--navbar-height);
  z-index: 1;
  width: 100%;
}

.video-back {
  z-index: -1;
}

.video-overlay {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  color: var(--white);
  z-index: 9;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.15);

  @include transition(opacity, 0.25s, ease);

  &.show {
    opacity: 1;
  }
}

.video-title {
  font-size: var(--font-size-h2);
  line-height: 0.77;
  margin-top: 3rem;

  @media(--sm-viewport-up) {
    font-size: var(--display4-size);
  }

  @media(--lg-viewport-up) {
    font-size: var(--display3-size);
    margin-bottom: 1.10833rem;
    margin-top: 0;
  }
}

.video-desc {
  margin-bottom: 1.33rem;

  @media(--sm-viewport-up) {
    font-size: var(--font-size-h3);
    margin-bottom: 1.9152rem;
  }

  @media(--lg-viewport-up) {
    font-size: var(--font-size-h1);
    margin-bottom: 0;
  }
}

.video-control {
  cursor: pointer;
}

.play {
  align-self: center;
  margin: 0 auto;
  display: none;

  & .icon-play {
    width: 60px;
    height: 60px;
    fill: var(--white);
  }

  &.paused {
    display: block;
    margin-top: 18px;
  }
}

.pause {
  position: absolute;
  bottom: 1.9152rem;
  left: 1.9152rem;
  line-height: 1;
  width: var(--icon-size-xl);
  height: var(--icon-size-xl);
  background-repeat: no-repeat;
  opacity: 0;

  @mixin transition(opacity);

  &.on {
    opacity: 1;
    background-image: var(--icon-pause);
  }
}

.volume {
  position: absolute;
  bottom: 1.9152rem;
  right: 1.9152rem;
  line-height: 1;
  width: var(--icon-size-xl);
  height: var(--icon-size-xl);
  background-repeat: no-repeat;
  background-image: var(--icon-mute);

  &.on {
    background-image: var(--icon-sound);
  }
}

.arrow {
  left: calc(50% - 50px);
  z-index: 2;
  position: absolute;
  bottom: 1.9152rem;

  & .icon-arrow {
    width: 50px;
    height: 20px;
    fill: var(--white);
  }
}
