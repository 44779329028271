/* Form validation states
 *
 * Used in _forms.scss to generate the form validation CSS for warnings, errors,
 * and successes.
 */

@mixin form-control-validation($color) {

  /* Color the label and help text */

  .text-help,
  label,
  .form-control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline,
  &.radio label,
  &.checkbox label,
  &.radio-inline label,
  &.checkbox-inline label {
    color: $color;
  }

  label .floating-desc {
    display: inline;
  }

  .r-indicator,
  .c-indicator {
    border-color: $color;
  }

  /* Set the border and box shadow on specific inputs to match */

  &.form-control,
  .form-control {
    border-color: $color;
    color: $color;

    @mixin box-shadow 0, 0, 0, 1px, $color, true;

    /* Placeholder */

    &::placeholder {
      color: $color;
    }

    &:focus,
    &:active {
      border-color: $color;

      @mixin box-shadow 0, 0, 0, 1px, $color, true;
      @mixin transition box-shadow 0.3s, ease-in-out;

      &::placeholder {
        color: $color;
      }
    }
  }
}
