/* Project Specific Print Blacklist */

@media print {
  html {
    font-size: 90%;
  }

  svg {
    fill: var(--black) !important;
  }

  .container {
    max-width: 100% !important;
  }

  .dont-print,
  header,
  nav,
  .nav,
  header + .search,
  main > .navbar,
  footer,
  picture,
  .jumbotron,
  img:not(.mall-logo):not(.card-img),
  .all-offers .content-stream-item,
  form[role="search"],
  .mall-alert,
  .adminTool {
    display: none !important;
  }

  .simon-section {
    padding: 0;
  }

  main {
    margin-top: 0 !important;
  }

  .print-me,
  .print-me.hidden-xs-up {
    display: block !important;
  }

  .content-stream-item {
    margin-bottom: 30px;
    margin-top: 0;
    -webkit-column-break-inside: avoid; /* Chrome, Safari */
    page-break-inside: avoid;           /* Theoretically FF 20+ */
    break-inside: avoid-column;         /* IE 11 */
    display:table;                      /* Actually FF 20+ */
    word-wrap: break-word; 
  }
  
  .content-stream-item * {
    -webkit-column-break-inside: avoid; /* Chrome, Safari */
    page-break-inside: avoid;           /* Theoretically FF 20+ */
    break-inside: avoid-column; 
  }

  .content-stream-item .card-details {
    padding-left: 0;
    padding-right: 0;
  }

  .content-stream-item a[href]::after {
    content: "";
  }

  .content-stream-item + .content-stream-item {
      margin-top: 0;
  }

  .content-stream-item-details,
  .content-stream-item-desc {
    white-space: normal !important;
    overflow: auto;
    text-overflow: initial;
    word-wrap: break-word;
    display: block;
  }

  .content-stream-item-title {
    font-size: var(--base-font-size);
    word-wrap: break-word;
  }

  .content-stream-item-text .legal-text {
    margin-bottom: 15px !important;
    word-wrap: break-word;
  }

  .content-stream-grid {
    column-count: 2;
    padding-left: 0;
    padding-right: 0;
    word-wrap: break-word;
  }

  .QSIFeedbackButton {
    display: none !important;
  }

  .card-footer {
    margin-top: 1rem;
  }

  .offers-print-header {
    margin-top: 1rem;
  }
}
