/*
 * Print styles
 *
 * Inlined to avoid the additional HTTP request
 * http://www.phpied.com/delay-loading-your-print-css/
 */

@media print {
  *,
  *::before,
  *::after {
    background: transparent !important;
    box-shadow: none !important;
    color: #000 !important;
    text-shadow: none !important;
  }

  a,
  a::visited {
    text-decoration: underline;
  }

  a[href]::after {
    content: " (" attr(href) ")";
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]::after,
  a[href^="javascript:"]::after {
    content: "";
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  .table {
    border-collapse: collapse !important;

    td,
    th {
      background-color: #fff !important;
    }
  }

  .table-bordered {
    th,
    td {
      border: 1px solid #ddd !important;
    }
  }
}
