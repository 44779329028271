/* Simon.com LLegal */

.legal {
  &-title,
  &-update {
    margin-bottom: 1.10833rem;
    margin-top: -2px;
  }

  &-title {
    @media (--lg-viewport-up) {
      font-size: var(--font-size-h3);
    }
  }

  &-title {
    @media (--lg-viewport-down) {
        font-size: var(--font-size-h3);
    }
  }
  &-subtitle {
    @media (--lg-viewport-up) {
      font-size: var(--font-size-h5);
    }
  }
  &-subtitle {
    @media (--lg-viewport-down) {
        font-size: var(--font-size-h5);
    }
  }


  &-update {
    color: var(--gray);
  }

  &-dropdown {
    margin-bottom: 1.9152rem;
  }

  p:not(.legal-update) + .legal-title, dl + .legal-title, ol + .legal-title, section > .legal-title {
    margin-top: 3.30947rem;
  }

  &-list > li::marker {
    font-family: SimonCircularPro-Bold, sans-serif;
    font-size: var(--font-size-h3);
  }
  &-list > li .legal-list > li::marker {
    font-family: SimonCircularPro-Bold, sans-serif;
    font-size: 1.1083333333rem
  }
}
