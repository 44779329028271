/*
 * Weather - Classes for weather related styles
 */

.weather-icon-small {
  width: 22px;
  height: 18px;
  position: relative;
  left: 0;
  top: 4px;
}

.navbar-nav .nav-link.weather {
  padding-bottom: calc(
    (var(--navbar-height) - var(--navbar-nav-link-size) - 1px) / 2
  );
  padding-top: calc(
    (var(--navbar-height) - var(--navbar-nav-link-size) - 17px) / 2
  );

  @media (--lg-viewport-down) {
    padding-bottom: 17px;
    padding-top: 11px;
  }
}
