/* Flexbox utilities */

.flex {
  display: flex;
}

.flex-row {
  align-items: center;
  display: flex;
}
