/* Image Mixins
   - Responsive image
   - Retina image

  Responsive image
  Keep images from scaling beyond the width of their parents. */

@mixin img-fluid($display: block) {
  display: $display;
  height: auto; /* Part 2: Scale the height according to the width, otherwise you get stretching */
  max-width: 100%; /* Part 1: Set a maximum relative to the parent */
}

/* Retina image - Short retina mixin for setting background-image and -size. */

@mixin img-retina($file-1x, $file-2x, $width-1x, $height-1x) {
  background-image: url($file-1x);

  /* Autoprefixer takes care of adding -webkit-min-device-pixel-ratio and -o-min-device-pixel-ratio,
   but doesn't convert dppx=>dpi.
   There's no such thing as unprefixed min-device-pixel-ratio since it's nonstandard.
   Compatibility info: http://caniuse.com/#feat=css-media-resolution */

  @media
    only screen and (--retina-in-dpi),
    only screen and (--retina-in-dppx) {
    background-image: url($file-2x);
    background-size: $width-1x $height-1x;
  }
}
