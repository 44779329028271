/* Button variants
 *
 * Easily pump out default styles, as well as :hover, :focus, :active,
 * and disabled options for all buttons
 */

@mixin button-variant(
  $color,
  $background,
  $border,
  $active-color,
  $active-background,
  $active-border,
  $focus-color
) {
  background-color: $background;
  border-color: $border;
  color: $color;

  &:hover {
    background-color: $active-background;
    border-color: $active-border;
    color: $active-color;

    svg {
      fill: $active-color;
    }
  }

  &:focus,
  &.focus {
    background-color: $active-background;
    border-color: $active-border;
    color: $active-color;

    svg {
      fill: $active-color;
    }
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-color: $active-background;
    background-image: none;
    border-color: $active-border;
    color: $active-color;

    &:hover,
    &:focus,
    &.focus {
      background-color: $active-background;
      border-color: $active-border;
      color: $focus-color;
    }
  }

  &.disabled,
  &:disabled {
    &:focus,
    &.focus {
      background-color: $background;
      border-color: $border;
    }

    &:hover {
      background-color: $background;
      border-color: $border;
    }
  }
}

@mixin button-outline-variant($color, $active-color) {
  background-color: transparent;
  background-image: none;
  border-width: 2px;
  border-color: $color;
  color: $color;
  padding: calc(var(--btn-padding-y) - 1px) var(--btn-padding-y);

  &:focus,
  &.focus,
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    border-color: $active-color;
    color: $active-color;
  }

  &:hover {
    background-color: transparent;
    border-color: $active-color;
    color: $active-color;
  }

  &.disabled,
  &:disabled {
    &:focus,
    &.focus {
      border-color: color($active-color lightness(30%));
    }
  }
}

/* Button sizes */

@mixin button-size(
  $padding-y,
  $padding-x,
  $font-size,
  $line-height,
  $border-radius
) {
  font-size: $font-size;
  line-height: $line-height;
  padding: $padding-y $padding-x;

  @include border-radius($border-radius);
}
