.nosto-tab-container {
    margin-top: 3rem;
}

.nosto-tabs {
    position: relative;
}

.nosto-tab:not(.nosto-tab-inactive) {
    opacity: 1;
    transition: opacity 0.2s;
}

.nosto-tab.nosto-tab-inactive {
    pointer-events: none;
    opacity: 0;
    height: 0;
}

.nosto-tab-button-wrapper {
    border-bottom: 1px solid var(--brand-gray);

    & .nosto-tab-button {
        color: var(--gray);
        padding: 0.25rem 0;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        margin-bottom: -1px;

        &:first-child {
            margin-left: 0;
        }

        &.active {
            color: var(--black);
            font-family: 'SimonCircularPro-Medium', Helvetica, Arial, sans-serif;
            border-bottom: 1px solid black;
            
            &:hover {
                color: var(--black);
                border-bottom: 1px solid var(--black);
            }
        }

        &:hover {
            color: var(--gray-dark);
            border-bottom: 1px solid var(--gray);
        }

        &:focus {
            color: var(--black);
            border-bottom: 1px solid var(--black);

        }
    }
}