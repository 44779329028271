/*
 * Modals - Pop Ups etc.
 */

/* Kill the scroll on the body */

.modal-open {
  overflow: hidden;
}

/* Container that the modal scrolls within */

.modal {
  backface-visibility: hidden;
  height: auto;
  left: 50%;
  max-width: 920px;
  min-width: 320px;
  outline: 0; /* Prevent Chrome on Windows from adding a focus outline. */
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  visibility: hidden;
  width: 50%;
  z-index: var(--zindex-modal);

  &.open {
    visibility: visible;

    .modal-content {
      opacity: 1;
      transform: scale(1);
    }
  }

  /* Spacing fix for stacked form elements */

  .form-group {

    @media(--sm-viewport-down) {
      margin-bottom: var(--base-font-size);
    }
  }

  /* Scale modal to different min sizes per breakpoint for more control */

  @media(--sm-viewport-up) {
    min-width: var(--modal-sm);
  }

  @media(--md-viewport-up) {
    min-width: var(--modal-md);
  }

  @media(--lg-viewport-up) {
    min-width: var(--modal-lg);
  }

  @media(--xl-viewport-up) {
    min-width: var(--modal-xl);
  }
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

/* Shell div to position the modal with bottom padding */

.modal-dialog {
  margin: 0;
  position: relative;
  width: auto;

  @media(--sm-viewport-up) {
    margin: var(--modal-dialog-sm-up-margin-y) auto;
    max-width: var(--modal-lg);
    min-width: 500px;
  }
}

/* Actual modal contents */

.modal-content {
  background-clip: padding-box;
  background-color: var(--modal-content-bg);
  border: var(--modal-content-border-width) solid var(--modal-content-border-color);
  opacity: 0;
  outline: 0;
  position: relative;
  transform: scale(0.7);

  @include clearfix;
  @include transition(transition all, 0.3s);

  @media(--md-viewport-down) {
    max-height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

/* Modal background */

.modal-backdrop {
  background-color: var(--modal-backdrop-bg);
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: var(--zindex-modal-bg);

  &.open {
    opacity: var(--modal-backdrop-opacity);
  }
}

/* Modal header - Top section of the modal w/ title and dismiss */

.modal-header {
  padding: 1.33rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;

  @include clearfix;

  @media(--lg-viewport-up) {
    padding: 0;
    top: var(--modal-inner-padding);
    right: var(--modal-inner-padding);
  }
}

/* Close icon */

.modal-header .close {
  margin-top: -2px;

  svg {
    height: var(--modal-close-size);
    width: var(--modal-close-size);
  }
}

/* Title text within header */

.modal-title,
.modal-desc,
.modal-form {
  margin-bottom: 0.7rem;

  @media(--md-viewport-up) {
    margin-bottom: 1.03rem;
  }

  @media(--lg-viewport-up) {
    margin-bottom: 1.6rem;
  }
}

.modal-title {
  margin-top: 1.9152rem;
  font-size: var(--font-size-h1);
  line-height: 0.77;
  letter-spacing: -0.06em;
  max-width: 100%;

  @media(--md-viewport-up) {
    margin-top: 2.75rem;
  }

  @media(--lg-viewport-up) {
    font-size: var(--font-size-h1);
  }

  @media(--xl-viewport-up) {
    margin-top: 0;
    font-size: var(--display4-size);
  }
}

.modal-desc {
  font-size: var(--base-font-size);
  letter-spacing: -0.01em;
  max-width: 100%;

  @media(--lg-viewport-up) {
    font-size: var(--font-size-h5);
  }
}

.modal-body {
  max-width: 100%;
}

.modal-details {
  padding: 0 15px 1.75rem;
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  text-align: center;
  width: 100%;

  @media(--md-viewport-up) {
    padding: 0 15px 2.5rem;
  }

  @media(--lg-viewport-up) {
    padding: 0 50px 0;
    position: absolute;
    left: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
  }
}

.modal-details * {
  transform: translateZ(0);
}

.modal-legal {
  margin: -4px 0 0 0;
  float: none;

  @media(--md-viewport-up) {
    margin-top: 2px;
  }
}

.modal-legal + .modal-legal {
  margin-top: 0.5rem;

  @media(--md-viewport-up) {
    margin-top: 0.9rem;
  }

  @media(--lg-viewport-up) {
    margin-top: 1.475rem;
  }
}

.modal-img-wrapper {
  overflow: hidden;
  max-height: 200px;

  @media(--lg-viewport-up) {
    max-height: 640px;
  }
}

.modal-img {
  float: none;
  width: 100%;
  margin-right: 0;
  display: block;

  @media(--lg-viewport-up) {
    float: left;
    width: 40%;
    margin-right: 30px;
  }
}

.modal-header + .modal-form {
  padding: 30px 30.5px;
}
