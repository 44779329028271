.g-recaptcha {
    color: #626262;
    font-weight: inherit;
    font-size: inherit;
    background-color: inherit !important;
    border: medium;
    padding: 0px;
    margin-top: -5%;
    line-height: inherit;
        a {
                text-decoration: underline !important;
          }
}

.grecaptcha-badge {
    visibility: hidden;
}

.search, .search-links {
    z-index: 1010;
    background: rgba(0, 0, 0, 1);
}

.search.open {
    top: var(--navbar-global-height);
    z-index: 1030;
    background: rgba(0, 0, 0, 1);
}

.search-links.open, .search-results.open {
    top: calc(var(--search-height) + var(--navbar-global-height));
    z-index: 1030;

    @media (--md-viewport-down) {
        top: calc(var(--search-height) + var(--navbar-global-height) + var(--navbar-mobile-height));
    }
}

.search-links.collapsed, .search-results.collapsed {
    display: none;
    top: calc(var(--search-height) + var(--navbar-global-height));
    z-index: -1;
}

#copyright-section, #footer-nav, .spo-nav-container, .vip-nav-container, .nav-container, .submenu {
    background-color: var(--gray-pale);
    display: grid;
    grid-template-columns: 1fr minmax(auto, 940px) 1fr;
}
.footer-container, .global-nav {
  grid-column: 2 / 3;
  padding-left: 15px;
  padding-right: 15px;
}

.footer-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  font-size: .75rem;
}

#copyrightStatement {
  grid-column: 1 / 4;
  margin-top: 1rem;
}

#legal-links {
    grid-column: 1 / 4;
    padding-left: 0px;
}

.form-control.footer-form {
    /*flex-basis:50%;*/
    margin-right: 0.5rem;
    width: 23.2rem;
}

#footerSignupButton {
    width: 8.11rem;
}

#footer-nav, .spo-nav-container, .vip-nav-container {
    background-color: var(--white);
}

.utility-links .spo-nav-container #spoMenu a {
    font-size: 14px;
    font-family: SimonCircularPro-Book,helvetica,arial,sans-serif;
    color: var(--black);
    &:hover, &:focus {
        color: var(--gray-dark);
    }
}

.spo-nav-container, .vip-nav-container {
    position: absolute;
    z-index: 1031;
    width: 100%;
    left: 0;
    display: none;
}

.spo-nav-container.open {
    display: grid;
}

.vip-nav-container.open {
    display: block;
    left: auto;
    min-width: 350px;
    right: 0;
    padding: 15px;
    border: var(--dropdown-border-width) solid var(--dropdown-border-color);
    border-top-style: none;
    top: calc(var(--navbar-global-height) - var(--dropdown-border-width));
}

#copyrightStatement p {
  font-size: .75rem;
}

#legal-links li {
  display: inline-block;
  vertical-align: middle;
  padding: 0px 5px;
  color: inherit;
  font-size: .75rem;
  padding-bottom: .5rem;
  & a:hover {
                color: inherit;
            }
}

#footer-nav-links {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    text-align: start;
}
#footer-nav-links a, #spoMenu a {
    text-decoration: none;
}
#footer-nav-links a:hover, #spoMenu a:hover {
    color: var(--gray-dark);
    text-decoration: underline;
}

nav.global-nav {
    background-color: var(--gray-pale);
    font-size: 12px;
    grid-column: 2 / 3;
    height: var(--navbar-global-height);
    line-height: 40px;
    z-index: 1030;
    .vip-nav-container {
                        line-height: 20px;
                        .btn-primary {
                                         color:#fff;
                                     }
                       }
}


.site-links, .utility-links, .sub-nav-links {
    list-style: none;
    margin: 0px;
    padding-left: 0px;
}

.site-links li, .utility-links li {
    display: inline-block;
    vertical-align: middle;
    margin: 0px 12px;
    font-size: 12px;
}

.utility-links li {
    margin-left: 7px;
}

.sub-nav-links li {
    margin-left: 23px;
}

.utility-links li a:not(.btn), 
.site-links li a:not(.btn),
.utility-links li button:not(.btn), 
.site-links li button:not(.btn) {
    text-decoration: none;
    font-size: 12px;
    transition: 0.2s;
    color: #626262;
    font-family: 'SimonCircularPro-Medium';

    &:hover,
    &:focus
        {
            color: var(--black);
        }
}

.global-nav .global-nav-divider {
    font-family: 'SimonCircularPro-Book';
    font-size: 16px;
    color: #626262;
    padding: 0;
    margin: 0;
}

.submenu.local-nav {
    @media (--lg-viewport-down) {
        height: var(--navbar-mobile-height)
    }

    @media (--md-viewport-down) {
        height: var(--navbar-mobile-height);
    }
}

.sub-nav-offset {
    @media(--lg-viewport-up) {
        padding-top: 5rem;
    }
}

.sub-nav-links li {
    display: inline-block;
    vertical-align: middle;
    padding: 0 5px;
    color: var(--white);
    font-size: 16px;
}

.site-links li.active {
    color: var(--black);
    font-family: "SimonCircularPro-Bold", sans-serif !important;
}

.site-links li.active a {
    color: var(--black);
    font-family: "SimonCircularPro-Bold", sans-serif !important;
}

.sub-nav-links li a {
    text-decoration: none;
    transition: text-decoration 0.2s ease;
    &:hover {
                color: var(--gray-light);
                text-decoration: underline solid;
                text-underline-position: under;
            }
    &.active {
        font-family: SimonCircularPro-Bold;
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-position: under;
    }
}

.site-links {
    float: left;
    & li:first-child {
        margin-left: 0px;
    }
    & li:last-child {
        margin-right: 0px;
    }
}

@media (max-width: 360px) {
    .site-links {
        display: inline-flex;
        margin-left: auto;
        margin-right: auto;
    }
}


.utility-links {
    float: right;
    font-family: 'SimonCircularPro-Medium';
}


#spoDropdown, #vipDropdown {
    border: none;
    background-color: inherit;
    transition: 0.2s;
    color: #626262;
    cursor: pointer;
    & svg {
              height: 10px;
              width: 10px;
              pointer-events: none;
          }
    &:hover, &:focus {
        color: var(--black);

        & svg {
            fill: var(--black);
        }
    }
}
#spoDropdown.open, #vipDropdown.open {
    background-color: var(--white);
}

.submenu {
    display: grid;
    background-color: #fff;
    align-items: center;
    height: var(--navbar-height);

    @media (--lg-viewport-down) {
        height: var(--navbar-mobile-height);
    }
}

.header-items {
    grid-column: 2 / 3;
    display: grid;
    grid-template-columns: 2fr 4fr 2fr;
    grid-template-areas: "weather  mainLogo signup";
    align-items: center;
    padding: 0px 15px 0px 15px;
    height: inherit;
}

#spoMenu {
    grid-column: 2 / 3;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    padding: 0px 15px 0px 15px;

    & .link-group {
        font-size: 14px;
    }

    & dd {
        font-family: 'SimonCircularPro-Book', helvetica, arial, sans-serif;
        padding: 5px 0px;
    }
}


#spoClose {
    grid-column: 2 / 3;
    justify-self: end;
    border: none;
    background-color: inherit;
    font-size: 1.5rem;
    cursor: pointer;
    padding-right: 20px;
}

.main-logo {
    justify-self: center;
    grid-area: mainLogo;
    text-align: center;

    & a {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & img,
    & svg {
        height: var(--navbar-logo-height);
        max-width: 100%;
    }

    @media(--md-viewport-down) {
        & a {
            height: var(--navbar-mobile-height);
        }

        & svg,
        & img {
            height: var(--navbar-logo-mobile-height);
        }
    }
}

svg.mall-logo {
    width: 300px;
}

svg.outlet-logo {
    width: 400px;
}

.search-menu {
    justify-self: end;
    display: none;
    grid-area: searchMenu;
}

.burger-menu {
    display: none;
    grid-area: mobileMenu;
    padding-top: 6px;
}

.weather {
    grid-area: weather;
    font-size: 14px;
    background-color: var(--white);
}

.weather-mobile {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 0px;
}

.header-signup {
    text-align: right;
    grid-area: signup;
}

.burger-menu button {
    border: none;
    background-color: #fff;
    font-size: 1.25rem;
    padding-left: 0;
    padding-right: 0;
}

.hamburger-inner {
    background-color: var(--black);
    &::before {
            background-color: var(--black);
            top: -8px;
        }
    &::after {
            background-color: var(--black);
            bottom: -8px;
        }
}

.link-group {
    font-size: .75rem;
    padding: 0;
    line-height: 1.5rem;
    margin-top: 15px;
}

.link-group dd {
    margin-left: 0;
    margin-bottom: 0;
}

.footer-social {
    text-align: right;
}

.appstore-badge {
    height: 48px;
}

.panel {
    display: block;
    font-family: SimonCircularPro-Book;
}
.d-none {
    display: none;
}

#fullHeader {
    position: fixed;
    z-index: 1020;
    width: 100%;
    top: 0px;
}

.admin-view #fullHeader {
    top: var(--navbar-height);
}

header:has(div.mall-alert:not(.hidden-xs-up)) ~ div.sub-nav, header:has(div.mall-alert:not(.hidden-xs-up)) ~ div.search.open {
    top: calc(var(--navbar-global-height) * 2);
}

header:has(div.mall-alert:not(.hidden-xs-up)) ~ section.search-links.open, header:has(div.mall-alert:not(.hidden-xs-up)) ~ section.search-results.open {
    top: calc(var(--navbar-global-height) * 2 + var(--search-height));
}

@media only screen and (min-width: 1200px) {
    #copyright-section, #footer-nav, .spo-nav-container, .vip-nav-container, .nav-container, .submenu {
        grid-template-columns: 1fr minmax(auto, 1140px) 1fr;
    }
}

@media only screen and (min-width: 1600px) {
    #copyright-section, #footer-nav, .spo-nav-container, .vip-nav-container, .nav-container, .submenu {
        grid-template-columns: 1fr minmax(auto, 1500px) 1fr;
    }
}

dt.squeezebox-full::after {
    content: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 19.2.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 20 8.9' style='enable-background:new 0 0 20 8.9;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D .st1%7Bfill:%239E1E5E;%7D%0A%3C/style%3E%3Cpolygon points='10,8.9 0,1.2 0.9,0 10,7 19.1,0 20,1.2 '/%3E%3C/svg%3E%0A");
    width: 14px;
    float: right;
    color: var(--black);
    margin-right: 25%;

    @media (--sm-viewport-down) {
        margin-right: 0;
    }
}

dt.squeezebox-full.active::after {
    transform: rotate(180deg);
}

.squeezebox-full + .panel {
    display: none;
}

.squeezebox-full.active + .panel {
    display: block;
}

@media only screen and (max-width: 991px) {
    .utility-links, .sub-nav, .spo-menu, .spo-nav-container, .vip-nav-container, .header-signup {
        display: none;
    }

    .burger-menu, .search-menu {
        display: block;
    }

    .header-items {
        grid-template-columns: 1fr 4fr 1fr;
        grid-template-areas: "mobileMenu mainLogo searchMenu";
        background-color: #fff;
        border-bottom: solid;
        border-bottom-width: 1px;
        border-bottom-color: var(--brand-gray);
        grid-column: 1 / 4;
    }

    svg.outlet-logo, svg.mall-logo {
        width: 100%;
    }

    .site-links {
        float: none;
        text-align: center;
    }

    .search-toggle {
        &:hover .icon-search, 
        &:active .icon-search, 
        &:focus .icon-search {
            fill: var(--black);
        }
    }

    header:has(#mobileBottomNav) ~ footer #copyright-section {
        padding-bottom: 5.5rem;
    }

    #copyright-section, .weather {
        text-align: center;
    }
    #footer-nav-links {
        grid-template-columns: 1fr;
        margin-bottom: 15px;
        & dl {
                 margin-bottom: 0px;
             }
    }
    .squeezebox + .panel {
        display: none;
    }

    .squeezebox.active + .panel {
        display: block;
    }

    .squeezebox-full {
        margin-top: 15px;
    }

    .section-squeezebox ~ dl {
        display: none;
    }

    .section-squeezebox.active ~ dl {
        display: block;
    }

    .footer-social {
        text-align: center;
    }

    .search.open {
        top: calc(var(--navbar-global-height) + var(--navbar-mobile-height));
    }
    .search-links.open {
        top: calc(var(--navbar-global-height) + var(--navbar-mobile-height) + var(--search-height));
    }
    header:has(div.mall-alert:not(.hidden-xs-up)) ~ div.sub-nav, header:has(div.mall-alert:not(.hidden-xs-up)) ~ div.search.open {
        top: calc(var(--navbar-global-height) * 2 + var(--navbar-mobile-height));
    }
    header:has(div.mall-alert:not(.hidden-xs-up)) ~ section.search-links.open, header:has(div.mall-alert:not(.hidden-xs-up)) ~ section.search-results.open {
        top: calc(var(--navbar-global-height) + var(--navbar-global-height) + var(--navbar-mobile-height) + var(--search-height));
    }


    dt.squeezebox {
        border-top: solid;
        border-top-width: 1px;
        border-top-color: var(--brand-gray);
        padding-top: 15px;
    }

    dt.squeezebox::after, span.section-squeezebox::after {
        content: var(--icon-down-arrow);
        /*        content: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 19.2.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 20 8.9' style='enable-background:new 0 0 20 8.9;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D .st1%7Bfill:%239E1E5E;%7D%0A%3C/style%3E%3Cpolygon points='10,8.9 0,1.2 0.9,0 10,7 19.1,0 20,1.2 '/%3E%3C/svg%3E%0A");
*/          width: 14px;
        float: right;
    }
    dt.squeezebox.active::after, span.section-squeezebox.active::after {
        transform: rotate(180deg);
    }

}

.sub-nav {
    background: rgb(0,0,0,.75);
    color: #fff;
    z-index: 2;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
}

.sub-nav-menu {
    display: grid;
    justify-content: center;
    height: var(--navbar-sub-height);
    line-height: var(--navbar-sub-height);
}

/* overwrite some of the older styles */
.search-toggle {
    left: auto;
    position: relative;
    top: inherit;
    padding-right: initial;
    padding-left: initial;
    margin-top: inherit;

    & .icon-search {
        fill: var(--black);
    }

    & svg:hover {
        fill: var(--brand-gray);
    }

    &.collapsed:not(:hover) .icon-search {
        fill: var(--black);
    }
}