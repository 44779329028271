/* Typography
 *
 * For sizing of elements including typographic scale see:
 * ../scss/modular-scale.scss and build through gulp
 * Include font-face for all simon webfonts
*/

/* TODO: point fonts to https://assets.simon.com/fonts when ready */

/* @include font-face(SimonCircularPro-Black, https://assets.simon.com/fonts/simon-circular-pro-black-c, normal, normal);
@include font-face(SimonCircularPro-Blackitalic, https://assets.simon.com/fonts/simon-circular-pro-blackitalic-c, normal, normal);
@include font-face(SimonCircularPro-Bold, https://assets.simon.com/fonts/simon-circular-pro-bold-c, normal, normal);
@include font-face(SimonCircularPro-Bolditalic, https://assets.simon.com/fonts/simon-circular-pro-bolditalic-c, normal, normal);
@include font-face(SimonCircularPro-Book, https://assets.simon.com/fonts/simon-circular-pro-book-c, normal, normal);
@include font-face(SimonCircularPro-Bookitalic, https://assets.simon.com/fonts/simon-circular-pro-bookitalic-c, normal, normal);
@include font-face(SimonCircularPro-Medium, https://assets.simon.com/fonts/simon-circular-pro-medium-c, normal, normal);
@include font-face(SimonCircularPro-Mediumitalic, https://assets.simon.com/fonts/simon-circular-pro-mediumitalic-c, normal, normal);
@include font-face(SimonCircularPro-Light, https://assets.simon.com/fonts/simon-circular-pro-light-c, normal, normal);
@include font-face(SimonCircularPro-Lightitalic, https://assets.simon.com/fonts/simon-circular-pro-Lightitalic-c, normal, normal); */

@import "fontassets";

/* Modular typescale - make changes at ../scss/modular-scale.scss and build through gulp */

@import "typescale";
@import "fontsloaded";

/* Headers */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  line-height: var(--header-line-height);

  /* Thin class needed for header variants */

  &.thin {
    font-family: Arial, Helvetica, sans-serif;
  }
}

h1,
h2,
h3 {
  letter-spacing: -0.03em; /* -0.03em = -30 tracking */

  sup { /* For reg marks */
    font-size: 0.3em;
    left: 0.25em;
    position: relative;
    top: -1.7em;
  }
}

h3 sup {
  left: 0em;
  top: -1em;
}

h4,
h5,
h6 {
  letter-spacing: -0.01em; /* -0.01em = -10 tracking */

  sup { /* For reg marks */
    position: relative;
    top: -0.5em;
  }
}

.display-1 sup,
.display-2 sup,
.display-3 sup,
.display-4 sup {
  top: -1.6em;
}

/* Paragraph with a border top for dividing text */

p.divider {
  border-top: 1px solid var(--gray);
  margin-top: 1.11rem;
  padding-top: 1.11rem;
}

/* Block of text with extra padding and grey background */

blockquote {
  background-color: var(--gray-lighter);
  margin: 1.11rem 0;
  padding: 1.11rem;
}

/* Horizontal rules */

hr {
  border: 0;
  border-top: var(--hr-border-width) solid var(--hr-border-color);
  margin-bottom: var(--spacer-y);
  margin-top: var(--spacer-y);
}

.small {
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 0.05em;
}

.no-underline,
.no-underline a {
  text-decoration: none;
}
