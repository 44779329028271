/*
 * Dropdowns - Not <select>s - used for custom dropdowns that can be toggled on hover or on click
 */

/* The class that holds the toggle button and the dropdown content */

.dropdown {
  position: relative;
}

/* The toggle button element */

.dropdown-toggle {
  &-border {
    background-color: transparent;
    bottom: 0;
    display: block;
    height: var(--dropdown-toggle-border-height);
    left: 0;
    position: absolute;
    width: 100%;

    @mixin transition background-color;
  }

  &.open > .dropdown-toggle-border,
  &.active > .dropdown-toggle-border {
    background-color: var(--dropdown-toggle-border-bg);
  }
}

/* The container that holds all dropdown content */

.dropdown-menu {
  background-clip: padding-box;
  background-color: var(--dropdown-bg);
  border: var(--dropdown-border-width) solid var(--dropdown-border-color);
  float: left;
  left: 0;
  max-height: 0;
  min-width: 300px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-align: left;
  top: 100%;
  transition: opacity, 0.75s, ease, max-height, 0.5s, ease;
  z-index: var(--zindex-dropdown);

  &.open {
    max-height: 999px;
    opacity: 1;
    transition: opacity, 0.5s, ease, max-height, 0.75s, ease;
  }
}

/* Menu positioning
 *
 * Add extra class to `.dropdown-menu` to flip the alignment of the dropdown
 * menu with the parent
 */

.dropdown-menu-right {
  left: auto;
  right: 0;
}

.dropdown-menu-left {
  left: 0;
  right: auto;
}

/* Links, buttons, and more within the dropdown menu
 *
 * `<button>`-specific styles are denoted with `// For <button>s`
 */

.dropdown-item {
  color: var(--dropdown-link-color);
  display: block;
  padding: var(--dropdown-item-padding-y) var(--dropdown-item-padding-x);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;

  &:first-child {
    padding-top: var(--dropdown-padding-y);
  }

  &:last-child {
    padding-bottom: var(--dropdown-padding-y);
  }

  /* For textual elements that are not links or buttons */

  &:not(a),
  &:not(.btn) {
    color: var(--dropdown-link-active-color);
    margin: 0;

    /* Don't add hover, focus, active, disabled effects */

    &:focus,
    &:hover,
    &.active,
    &:active {
      background-color: var(--dropdown-bg);
      color: var(--dropdown-link-active-color);
    }
  }

  /* Hover, focus, active states */

  &:focus,
  &:hover,
  &:active,
  &.active {
    background-color: var(--dropdown-link-hover-bg);
    color: var(--dropdown-link-hover-color);
    outline: 0;
    text-decoration: none;
  }

  /* Disabled state
   *
   * Gray out text and ensure the hover/focus state remains gray
   */

  &.disabled {
    color: var(--dropdown-link-disabled-color);

    /* Nuke hover/focus effects !1! */

    &:focus,
    &:hover,
    &:active,
    &.active {
      background-color: transparent;
      background-image: none; /* Remove CSS gradient !1! */
      cursor: var(--cursor-disabled);
      filter: "progid:DXImageTransform.Microsoft.gradient(enabled = false)";
      text-decoration: none;
    }
  }
}

.dropdown-link {
  color: var(--dropdown-link-color);
  font-size: var(--dropdown-link-font-size);
  text-decoration: none;

  @mixin transition color;

  &:hover,
  &:focus {
    color: var(--dropdown-link-active-color);
  }
}

/* Dividers (basically an `<hr>`) within the dropdown */

.dropdown-divider {
  background-color: var(--dropdown-border-color);
  height: 1px;
  margin: var(--dropdown-divider-padding-y) 0;
  overflow: hidden;
}

/* Checkbox Faux Dropdown Styles */

.dropdown-filter-menu {
  background: var(--white);
  color: var(--black);
  position: absolute;
  top: var(--input-height);
  left: 15px;
  width: 95%;
  width: calc(100% - 30px);
  height: 0;
  padding: 0;
  transition: height 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
  overflow: hidden;
  box-sizing: border-box;
  z-index: 3;
}

.dropdown-filter-menu input[type="checkbox"] {
  appearance: none;
  cursor: pointer;
  display: none;
}

.dropdown-filter-menu input[type="checkbox"] + label {
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
}

.dropdown-filter-menu input[type="checkbox"]:checked + label {
  color: var(--black);
}

.dropdown-filter-menu input[type="checkbox"]:checked + label::after {
  background-image: var(--icon-checkbox);
  content: "";
  background-size: 9px 14px;
  background-repeat: no-repeat;
  width: 10px;
  height: 10px;
  margin-left: 10px;
  display: inline-block;
  font-size: 10px;
}

.dropdown-filter {
  position: absolute;
  appearance: none;
  cursor: pointer;
  left: 0;
  top: 0;
  display: none;
}

.dropdown-filter + label {
  height: var(--input-height);
  display: block;
  border: 1px solid var(--gray);
  width: 100%;
  padding: var(--input-padding-y) var(--input-padding-x);
  position: relative;
  top: 0;
  left: 0;
  outline: none;
  color: var(--gray);
  text-align: left;
  line-height: 1.35;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  transition: all 500ms ease;
  background-image: var(--icon-down-arrow);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 20px 20px;
  text-indent: 0.01px;
  text-overflow: "";
}

.dropdown-filter + label::after {
  content: "FILTER CENTERS BY";
}

.dropdown-filter:checked ~ .dropdown-filter-menu {
  height: 474px;
  border: 1px solid var(--gray);
  border-top: none;
}

.dropdown-filter:checked + dropdown-filter-menu {
  background-image: var(--icon-down-arrow);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 20px 20px;
  text-indent: 0.01px;
  text-overflow: "";
}

.dropdown-filter:checked + dropdown-filter-menu::after {
  content: "CLOSE";
}

.vip-dropdown {
    line-height: 1.25rem;
}

.dropdown-icon-close {
    height: 14px;
    width: 14px;
}