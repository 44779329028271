/* Lists  - Unstyled keeps list items block level, just removes default browser padding and list-style */

@mixin list-unstyled {
  list-style: none;
  padding-left: 0;
}

@mixin vertical-list-spacer {
  ul,
  ol,
  li + li {
    margin-top: var(--vertical-list-margin);
  }
}
