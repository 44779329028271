/*
 * Tables
 *
 * Basic table class
 */

.table {
  font-size: var(--base-font-size);
  margin-bottom: var(--spacer);
  max-width: 100%;
  width: 100%;

  th,
  td {
    border-top: var(--table-border-width) solid var(--table-border-color);
    padding: var(--table-cell-padding);
    text-align: left;
    vertical-align: top;
  }

  thead th {
    border-bottom: calc(2 * var(--table-border-width)) solid var(--table-border-color);
    vertical-align: bottom;
  }

  tbody + tbody {
    border-top: calc(2 * var(--table-border-width)) solid var(--table-border-color);
  }

  .table {
    background-color: var(--body-bg);
  }
}

/* Condensed table w/ half padding */

.table-sm {
  th,
  td {
    padding: var(--table-sm-cell-padding);
  }
}

/* Zebra-striping - Default zebra-stripe styles (alternating gray and transparent backgrounds) */

.table-striped {
  tbody tr:nth-of-type(odd) {
    background-color: var(--table-bg-accent);
  }

  th,
  td {
    border-top: none;
  }
}

/* Responsive tables
 *
 * Wrap your tables in `.table-responsive` and it will make them mobile friendly
 * by enabling horizontal scrolling. Only applies <768px. Everything above that
 * will display normally.
 */

.table-responsive {
  display: block;
  min-height: 0.01%;
  overflow-x: auto;
  width: 100%;
}

.table-responsive-no-scroll {
  min-width: 295px;

  th {
    display: none;
  }

  td {
    display: block;
  }

  td::before {
    content: attr(data-th) ": ";
    font-family: Arial, Helvetica, sans-serif;
    width: 6.5em;
    display: inline-block;

    @media (--sm-viewport-up) {
      display: none;
    }
  }

  th,
  td {

    @media (--sm-viewport-up) {
      display: table-cell;
    }
  }
}

/* Invert the table header of the table */

.thead-inverse {
  th {
    background-color: var(--black);
    color: var(--white);
  }
}

/* Default table header styles */

.thead-default {
  th {
    background-color: var(--white);
    color: var(--black);
  }
}
