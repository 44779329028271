.spo-btn {
  border-color: var(--brand-primary);
  color: var(--brand-primary);

  & svg {
    height: 0.75rem;
    margin-left: 6px;
    margin-right: 0;
    top: 2px;
    width: 0.75rem;
    fill: var(--brand-primary);
  }

  &:hover svg {
    fill: var(--brand-gray);
  }

  @media(--md-viewport-down) {
    background-color: var(--brand-primary);
    color: #fff;

    &:hover {
      border-color: var(--brand-primary);
      color: #fff;
    }

    & svg {
      fill: #fff;
    }
  }
}

.spo-arrow-copy {
  position: relative;
  height: 0.5rem;
  margin-left: 2px;
  margin-right: 0;
  width: 0.5rem;
  fill: var(--brand-primary);
}

.spo-carousel {
  margin-bottom: 3.3rem;
}

.spo-carousel .carousel__item {
  padding-left: 15px;
  padding-right: 15px;
}

.spo-carousel .carousel__item img {
  margin-bottom: var(--spacer);
}

.navbar-sidepanel-secondary {
  background-color: #393939;
  height: calc(100vh - 74px);
  max-width: 300px;
  min-height: calc(100vh - 74px);
  overflow-x: hidden;
  overflow-y: scroll;
  position: fixed;
  right: -100%;
  top: 74px;
  width: 100%;
  margin: 0px;
  padding: 0px 0px 100px;
  transition: right 0.3s ease 0s;
  z-index: 1051;

  &.open {
    transition: right 0.3s;
    right: 0;
  }

  & .icon-pagination.pull-xs-left {
    margin-right: 10px;
  }

  & .nav-link-back {
    background-color: #000;
  }

  & .nav-link-secondary,
  & .nav-link {
    width: 100%;
    text-align: left;
    cursor: pointer;
  }

  & .nav-link-title {
    background-color: #252525;
  }

  & .icon-pagination {
    margin-top: 0px;
  }

  & .sub-nav {
    position: absolute;
    top: 104px;
    bottom: 0;
    max-width: 300px;
    width: 100%;
    background-color: #393939;
    max-width: 300px;
    overflow-x: hidden;
    overflow-y: scroll;
    right: -100%;
    transition: right 0.3s ease 0s;
    z-index: 1052;

    &.open {
      transition: right 0.3s;
      right: 0;
    }
  }
}

.spo-nav {
  padding: 40px 0;
  transform: translateY(-120%);
  transition: all 0.5s ease;
  opacity: 0;
  left: 0;
  right: 0;
  top: 74px;
  position: fixed;
  background-color: #fff;
  z-index: 1021;

  h1 {
    margin-bottom: 30px;

    &.header-sm {
      margin-bottom: 20px;
    }
  }

  &.open {
    opacity: 1;
    transform: translateY(0);
  }

  .nav {
    margin-bottom: 20px;
  }

  .nav-item + .nav-item {
    margin-top: 0.6414rem;
  }

  &__image {
    margin-top: 40px;
    width: 80%;
  }

  .col-md-3 > a {
    display: block;

    &:focus,
    &:focus span {
      color: var(--black);
    }
  }
}

/* SPO AD */

@keyframes caret {
  50% {
    border-color: transparent;
  }
}

@keyframes expandUp {
  from {
    transform: scaleY(0);
  }

  to {
    transform: scaleY(1);
  }
}

@keyframes expandDown {
  from {
    transform: scaleY(1);
  }

  to {
    transform: scaleY(0);
  }
}

@keyframes expandAndSlideDown {
  0% {
    transform: translateY(0px) scaleX(0);
  }

  30% {
    transform: translateY(0px) scaleX(1);
  }

  40% {
    transform: translateY(0px) scaleX(1);
  }

  60% {
    transform: translateY(40px) scaleX(1);
  }

  100% {
    transform: translateY(40px) scaleX(1);
  }
}

@keyframes slideUp {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-74px);
  }
}

.shop-online {
  position: relative;
}

.spo-ad {
  animation-delay: 0.75s;
  background-color: var(--gray-lighter);
  height: 74px;
  position: fixed;
  transform: translateY(10px) scaleX(0);
  width: 100vw;
  top: 0;
  right: 0;
  z-index: 1021;
  transform-origin: center;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (--lg-viewport-down) {
    flex-wrap: wrap;
  }

  @media (--sm-viewport-down) {
    padding: 14px 0px;
  }
}

.spo-ad h1 {
  display: inline-block;

  @media (--sm-viewport-down) {
    font-size: 1.3rem;
  }
}

.spo-ad-header {
  padding-right: 30px;
  width: 375px;
  display: inline-block;

  & sup {
    margin-left: -0.2rem;
    margin-right: 0.5rem;
  }

  @media (--lg-viewport-down) {
    width: 355px;
  }

  @media (--md-viewport-down) {
    padding-right: 0;
    width: 320px;
  }

  @media (--sm-viewport-down) {
    width: 181px;
  }
}

.spo-ad-header span {
  border-right: 0.05em solid;
  animation: caret 1s steps(1) infinite;
}

.spo-ad-text {
  opacity: 0;
  transition: opacity 0.3s ease-in;

  @media (--sm-viewport-down) {
    font-size: 13px;
  }
}

.spo-ad-text.fadeIn {
  opacity: 1;
}

.spo-ad-text + .spo-ad-text {
  padding-left: 10px;

  @media (--sm-viewport-down) {
    padding-left: 5px;
  }
}

.hamburger-box.black .hamburger-inner,
.hamburger-box.black .hamburger-inner::after,
.hamburger-box.black .hamburger-inner::before {
  background-color: #000;
}

.spo-ad-link-bg {
  background-color: #f1f1f1;
  height: 40px;
  position: absolute;
  top: -1px;
  left: 0px;
  right: 0;
  padding: 15px;
  transform: scaleY(0);
  transform-origin: bottom;
  z-index: -1;

  @media (--lg-viewport-down) {
    left: 8px;
    width: 65px;
    top: -27px;
  }
}

.expandAndSlideDown {
  animation: expandAndSlideDown 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.7s forwards;
}

.expandUp {
  animation: expandUp 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s forwards;
}

.expandDown {
  transform: scaleY(1);
  animation: expandDown 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s forwards;
}

.slideUp {
  top: 40px;
  transform: scale(1, 1);
  animation: slideUp 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) 0.75s forwards;
}
