/* Font Face Generator Mixin */

@mixin font-face($font-family: no, $file-path: no, $font-weight: normal, $font-style: normal) {

  @font-face {
    font-family: $font-family;
    font-style: $font-style;
    font-weight: $font-weight;
    src: local("Simon Circular Pro"), local("SimonCircularPro"), url("$file-path.woff2") format("woff2"), url("$file-path.woff") format("woff");
  }
}

/* Font families */

@mixin SimonCircularPro-Black {
  font-family: "SimonCircularPro-Black", helvetica, arial, sans-serif;
  font-style: normal;
  font-weight: normal;
}

@mixin SimonCircularPro-Blackitalic {
  font-family: "SimonCircularPro-Blackitalic", helvetica, arial, sans-serif;
  font-style: normal;
  font-weight: normal;
}

@mixin SimonCircularPro-Bold {
  font-family: "SimonCircularPro-Bold", helvetica, arial, sans-serif;
  font-style: normal;
  font-weight: normal;
}

@mixin SimonCircularPro-Bolditalic {
  font-family: "SimonCircularPro-Bolditalic", helvetica, arial, sans-serif;
  font-style: normal;
  font-weight: normal;
}

@mixin SimonCircularPro-Book {
  font-family: "SimonCircularPro-Book", helvetica, arial, sans-serif;
  font-style: normal;
  font-weight: normal;
}

@mixin SimonCircularPro-Bookitalic {
  font-family: "SimonCircularPro-Bookitalic", helvetica, arial, sans-serif;
  font-style: normal;
  font-weight: normal;
}

@mixin SimonCircularPro-Medium {
  font-family: "SimonCircularPro-Medium", helvetica, arial, sans-serif;
  font-style: normal;
  font-weight: normal;
}

@mixin SimonCircularPro-Mediumitalic {
  font-family: "SimonCircularPro-Mediumitalic", helvetica, arial, sans-serif;
  font-style: normal;
  font-weight: normal;
}

@mixin SimonCircularPro-Light {
  font-family: "SimonCircularPro-Light", helvetica, arial, sans-serif;
  font-style: normal;
  font-weight: normal;
}

@mixin SimonCircularPro-Lightitalic {
  font-family: "SimonCircularPro-Lightitalic", helvetica, arial, sans-serif;
  font-style: normal;
  font-weight: normal;
}

@mixin ellipses {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
