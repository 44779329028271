/*
 * Accordion
 */

.accordion {
  position: relative;

  dl {
    margin: 0;
  }

  dt:first-child {
    border-top: 1px solid var(--brand-gray);
  }
}

/* Content of the accordion using max-height property and collapse class to hide content until corresponding title is clicked */

.accordion-content {
  border-bottom: 1px solid var(--accordion-border-color);
  margin: 0;
  height: auto;
  overflow: hidden;
  max-height: 100em;
  padding: 0 var(--accordion-padding-x) var(--accordion-padding-y) 5.17rem;
  transition: max-height, 1s, ease, padding, 1s, ease;

  @media screen and (min-width: 48em) {
    max-height: 100em;
    transition: max-height, 0.5s, ease, padding, 0.5s, ease;
  }

  /* Collapse class to hide content with max-height */

  &.is-collapsed {
    max-height: 0;
    padding: 0 var(--accordion-padding-x) 0 5.17rem;
  }

  @media (--sm-viewport-down) {
    padding: 0 0.9256rem 0.9256rem 3.75rem;

    &.is-collapsed {
      padding: 0 var(--accordion-padding-x) 0 3.75rem;
    }
  }

  &-no-icon,
  &-no-icon.is-collapsed {
    padding-left: 0;

    @media (--sm-viewport-down) {
      padding-left: var(--accordion-padding-y);
    }
  }
}

/* Fallback for no js */

.no-js .accordion-content.is-collapsed {
  max-height: initial;
}

/* Accordion title */

.accordion-title {
  font-size: var(--accordion-title-font-size);
  line-height: 1;
  padding: var(--accordion-padding-y);
  display: block;
  text-decoration: none;

  @media (--sm-viewport-up) {
    padding: var(--accordion-padding-y) var(--accordion-padding-x) var(--accordion-padding-y) 0;
  }
}

/* Icon classes for plus/minus on the right and a built in class for left side icons (i.e. amenities) */

.accordion-icon {
  height: var(--accordion-icon-size);
  position: relative;
  width: var(--accordion-icon-size);

  &-right {
    float: right;
    top: 3px;

    @include transition(all 0.2s ease-in-out);
  }

  &-left {
    float: left;
    margin-right: var(--accordion-padding-x);
    transition: none;
    width: 36px;
    height: 37px;
    position: relative;
    top: -7px;

    @media (--sm-viewport-up) {
      margin-right: 54px;
    }
  }

  &-plus {
    opacity: 1;
  }

  &-minus {
    left: 18px;
    opacity: 0;
    transform: rotate(-90deg);
  }
}

/* Rotation animation & Show/Hide of +/- icon on expansion of its accordion content */

.accordion-title.is-expanded {
  .accordion-icon-plus {
    transform: rotate(90deg);
    opacity: 0;
  }

  .accordion-icon-minus {
    opacity: 1;
    transform: rotate(0deg);
  }
}

/* Newer accordion styles using CSS Grid */
.accordion-grid dl {
    border-bottom: 1px solid var(--accordion-border-color);
}
.accordion-grid-dt {
    display: grid;
    align-items: center;
    grid-template-columns: 50px auto 50px;
    border-top: 1px solid var(--accordion-border-color);
    padding: 15px 15px 0px 15px;
}
.accordion-grid-dd {
    display: grid;
    grid-template-columns: 50px auto 50px;
    margin-left: 0;
    padding: 15px 15px 0px 15px;
    & span, & p, & div.editIcon {               
      grid-column: 2 / 4;
    }
}

.accordion-grid-icon {
    height: 36px;
    width: 36px;
}
.accordion-grid-icon-plus {
    margin-left: auto;
    height: var(--accordion-icon-size);
    width: var(--accordion-icon-size);
}
.accordion-grid-title {
    font-size: var(--accordion-title-font-size);
}
.accordion-grid-trigger {
    text-decoration: none;
    padding: 15px 15px;
    cursor: pointer;
}

a.accordion-grid-trigger:not(.active) + dd.accordion-grid-dd {
    display: none;
}

dt:has(a.accordion-grid-trigger:not(.active)) + dd.accordion-grid-dd {
    display: none;
}

.accordion-grid-icon-plus {
    transform: rotate(0deg);
    transition: transform .2s;
}
a.accordion-grid-trigger.active .accordion-grid-icon-plus {
    transform: rotateX(180deg);
}