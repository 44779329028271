/* Spacing Utilities */

.no-gutter {
  padding-left: 0 !important;
  padding-right: 0 !important;

  .row {
    margin-left: 0;
    margin-right: 0;
  }
}

.no-gutter-mobile {

  @media (--sm-viewport-down) {
    padding-left: 0 !important;
    padding-right: 0 !important;

    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

@media (max-width: 543px) {
  .no-margin-xs-down {
    margin: 0 !important;
  }

  .no-padding-xs-down {
    padding: 0 !important;
  }
}

@media (min-width: 544px) {
  .no-margin-sm-up {
    margin: 0 !important;
  }

  .no-padding-sm-up {
    padding: 0 !important;
  }
}

@media (max-width: 767px) {
  .no-margin-sm-down {
    margin: 0 !important;
  }

  .no-padding-sm-down {
    padding: 0 !important;
  }
}

@media (min-width: 768px) {
  .no-margin-md-up {
    margin: 0 !important;
  }

  .no-padding-md-up {
    padding: 0 !important;
  }
}

@media (max-width: 991px) {
  .no-margin-md-down {
    margin: 0 !important;
  }

  .no-padding-md-down {
    padding: 0 !important;
  }
}

@media (min-width: 992px) {
  .no-margin-lg-up {
    margin: 0 !important;
  }

  .no-padding-lg-up {
    padding: 0 !important;
  }
}

@media (max-width: 1199px) {
  .no-margin-lg-down {
    margin: 0 !important;
  }

  .no-padding-lg-down {
    padding: 0 !important;
  }
}

@media (min-width: 1200px) {
  .no-margin-xl-up {
    margin: 0 !important;
  }

  .no-padding-xl-up {
    padding: 0 !important;
  }
}
