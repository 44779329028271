h1,
.header-xl {
  font-size: 2.29824rem;
  margin-bottom: 0.6413966049rem;
}

h2,
.header-lg {
  font-size: 1.9152rem;
  margin-bottom: 0.6413966049rem;
}

h3,
.header-md {
  font-size: 1.596rem;
  margin-bottom: 0.6413966049rem;
}

h4,
.header-sm {
  font-size: 1.33rem;
  margin-bottom: 0.6413966049rem;
}

h5,
.header-xs {
  font-size: 1.1083333333rem;
  margin-bottom: 0.6413966049rem;
}

h6,
p,
blockquote,
.copy {
  font-size: 0.9236111111rem;
  margin-bottom: 0.6413966049rem;
}

ul,
ol {
  font-size: 0.9236111111rem;
}

label,
.small,
.legal-text {
  font-size: 0.7696759259rem;
  margin-bottom: 0.6413966049rem;
}

label.floating {
  font-size: 0.6413966049rem;
  margin-bottom: 0.204rem;
}

.base {
  font-size: 0.9236111111rem;
}

/* Display Headers - for larger than the normal scale sizes */
.display-1 {
  font-size: 5.7187565568rem;
  line-height: 0.77;
  letter-spacing: -0.06em; /* -0.06em = -60 tracking */
}

.display-2 {
  font-size: 4.765630464rem;
  line-height: 0.77;
  letter-spacing: -0.06em; /* -0.06em = -60 tracking */
}

.display-3 {
  font-size: 3.97135872rem;
  line-height: 0.77;
  letter-spacing: -0.06em; /* -0.06em = -60 tracking */
}

.display-4 {
  font-size: 3.3094656rem;
  line-height: 0.77;
  letter-spacing: -0.06em; /* -0.06em = -60 tracking */
}

.display-5 {
  font-size: 2.757888rem;
  line-height: 0.77;
  letter-spacing: -0.06em; /* -0.06em = -60 tracking */
}