/*
 * Sliders - Including main hero, logo and mall photo gallery styles
 */

/* Import Slick Slider Base Styles */

@import "../vendor/slickslider/slickslider";

/* Custom logo slider */

.logo-slider {
  display: none;
  padding: 1.10833rem 30px;

  &.slick-initialized {
    display: block;
  }
}

.logo-slider .logo img {
  margin: 0 auto;
  display: block;
  width: 138px;
  max-height: 138px;
}

.logo-slider .logo-no-img {
  height: 115px;
  padding-top: 46px;

  @media (--sm-viewport-up) {
    height: 138px;
    position: relative;
    width: auto;
    padding-top: 68px;
  }
}

/* Large hero slider for homepages */

.hero-slider,
.blob-slider {
  opacity: 0;
  margin-bottom: 2.25rem;

  @mixin transition opacity;

  &.slick-initialized {
    opacity: 1;
  }

  &-title {
    margin-bottom: 1.9152rem;

    @media (--md-viewport-up) {
      font-size: var(--font-size-h4);
    }

    @media (--lg-viewport-up) {
      margin-bottom: 2.78rem;
      font-size: var(--font-size-h3);
    }
  }

  .slick-next,
  .slick-prev {
    transform: translateY(calc(-1 * 50% - 24px));
    z-index: 2;
  }

  .slick-next {
    right: 30px;
  }

  .slick-prev {
    left: 30px;
  }

  @media(--md-viewport-down) {
    padding: 0;
    width: 100%;
    max-width: 100%;

    .slick-next,
    .slick-prev {
      visibility: hidden;
    }
  }
}

/* Specific slide in the hero slider */

.hero-slide {
  position: relative;

  @include clearfix;

  @media(--lg-viewport-up) {
    background-color: var(--gray-lighter);
  }

  @media(--md-viewport-down) {
    background-color: #fff !important;

    .hero-slide-btn {
      background-color: var(--black);
      color: var(--white);
    }
  }
}

.hero-slide-img {
  float: none;
  width: 100%;
  margin-right: 0;
  display: block;
  max-height: 262px;
  overflow: hidden;

  @media(--md-viewport-up) {
    max-height: 100%;
    float: left;
    width: 50%;
    margin-right: 30px;
  }
}

.hero-slide-details {
  padding: 0 15px;
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  text-align: center;

  @media(--md-viewport-up) {
    padding: 0 50px;
    position: absolute;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
  }
}

.hero-slide-details * {
  transform: translateZ(0);
}

.hero-slide-title,
.hero-slide-desc {
  margin-bottom: 1.03rem;
  width: 100%;

  @media(--lg-viewport-up) {
    margin-bottom: 1.6rem;
  }
}

.hero-slide-title {
  margin-top: var(--font-size-h2);
  font-size: var(--font-size-h2);
  display: flex;
  width: 100%;
  justify-content: inherit;

  @media(--md-viewport-down) {
    color: var(--black) !important;
    display: block;
  }

  @media(--md-viewport-up) {
    margin-top: 0;
  }

  @media(--lg-viewport-up) {
    font-size: var(--display4-size);
  }

  @media(--xl-viewport-up) {
    font-size: var(--display3-size);
  }
}

/* Superscript temp fix */
.hero-slide-title span sup {
  top: -0.5em;
  right: initial;
  position: relative;
  vertical-align: super;
}

.hero-slide-desc {
  font-size: var(--base-font-size);
  letter-spacing: -0.01em;

  @media(--lg-viewport-up) {
    font-size: var(--font-size-h5);
  }

  @media(--md-viewport-down) {
    color: var(--black) !important;
    display: block;
  }
}
