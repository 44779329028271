/*
 * Sections - used for consistent spacing between content sections
 */

.simon-section {
  padding: 1.7rem 0 var(--section-padding-mobile);

  @media (--sm-viewport-up) {
    padding: var(--section-padding-desktop) 0;
  }

  /* Class to make a gray bg section */

  &-gray {
    background-color: var(--section-bg);
  }

  &-gray-mobile {

    @media (--sm-viewport-down) {
      padding-top: var(--section-padding-mobile);
      background-color: var(--section-bg);
    }
  }
}

.simon-section-slider + .simon-section:not(.simon-section-gray-mobile) {
  padding-top: 0.64137rem;

  @media (--lg-viewport-up) {
    padding-top: 1.9152rem;
  }
}

.simon-section-header {
  font-size: var(--section-header-font-size);
  margin-bottom: var(--section-header-margin);
  text-align: center;

  @media(--md-viewport-up) {
    text-align: left;
  }

  @media(--lg-viewport-up) {
    font-size: var(--section-header-font-size-lg);
  }
}

/* The left side items of a simon-section class */

.simon-section-aside {
  text-align: center;
  margin-bottom: 1.10833rem;

  @media (--md-viewport-up) {
    text-align: left;
    margin-bottom: 0;
  }

  /* zero out margin of any elements not the header in the aside */

  & > *:not(.aside-header, .form-control, .aside-group) {
    margin: 0;
    padding: 0;
  }

  /* spacer between all non asider header elements at the root level */

  & > *:not(.aside-header) + * {
    margin-top: var(--aside-item-padding-mobile);

    @media (--md-viewport-up) {
      margin-top: var(--aside-item-padding-desktop);
    }
  }
}

.aside-group {
  & > * {
    margin: 0;
  }

  & + .aside-group {
    margin-top: 1.10833rem;
  }
}
