#smartbanner {
  position: fixed !important;
  left: 0;
  top: -74px;
  width: 100%;
  height: 74px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #f1f1f1;
  z-index: 1065;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
}

#smartbanner,
html.sb-animation {
  transition: all 0.3s ease;
}

#smartbanner .sb-container {
  margin: 0 auto;
}

#smartbanner .sb-close {
  position: absolute;
  left: 5px;
  top: 5px;
  display: block;
  border: 2px solid #fff;
  width: 14px;
  height: 14px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  text-decoration: none;
}

#smartbanner .sb-icon {
  position: absolute;
  left: 30px;
  top: 10px;
  display: block;
  width: 57px;
  height: 57px;
  background: rgba(0, 0, 0, 0.6);
  background-size: cover;
  border-radius: 10px;
}

#smartbanner .sb-info {
  position: absolute;
  left: 98px;
  top: 18px;
  width: 44%;
  font-size: 11px;
  line-height: 1.2em;
  font-weight: bold;
  color: #000;
}

#smartbanner #smartbanner.no-icon .sb-info {
  left: 34px;
}

#smartbanner .sb-info strong {
  display: block;
  font-size: var(--base-font-size);
  color: #000;
  line-height: 18px;
}

#smartbanner .sb-info > span {
  display: block;
}

#smartbanner .sb-info em {
  font-style: normal;
  text-transform: uppercase;
}

#smartbanner .sb-button {
  position: absolute;
  right: 4px;
  top: 21px;
  padding: 0 10px;
  min-width: 10%;
  height: 24px;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
}

#smartbanner.android .sb-close {
  border: 0;
  width: 17px;
  height: 17px;
  line-height: 17px;
  color: #000;
  background: var(--gray-lighter);
}

#smartbanner.android .sb-close:active {
  color: #eee;
}

#smartbanner.android .sb-info {
  color: #000;
}

#smartbanner.android .sb-button span {
  color: #fff;
  text-align: center;
  display: block;
  padding: 0 10px;
  background-color: #000;
  text-transform: none;
  text-shadow: none;
  box-shadow: none;
  padding-bottom: 5px;
  padding-top: 5px;
}

#smartbanner + #fullHeader {
  top: var(--navbar-height); /* push black header down for simon central header addition */

  & + .search {
    top: var(--navbar-height); /* push search down for simon central header addition */
  }

  & + .search.open,
  & .navbar-sidepanel {
    top: calc(var(--navbar-height) * 2); /* push search and mobile nav down for simon central header addition */
  }

  & + .search.open + .search-links,
  & + .search.open + .search-results {
    top: calc(var(--navbar-height) * 2 + var(--search-height)); /* push search trays down for simon central header addition */
  }
}
