.fonts-loaded {
  font-family: "SimonCircularPro-Book", sans-serif;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  b,
  dt,
  strong,
  .small,
  .btn,
  .page-link,
  .nav-tab .nav-link,
  .nav-pills .nav-link,
  .navbar-nav .nav-link,
  .file-control::before,
  .slick-dots li button::before,
  .table-responsive-no-scroll td::before {
    font-family: "SimonCircularPro-Bold", sans-serif;
  }

  .navbar-sidepanel .nav-link.nav-link-secondary,
  .form-control {
    font-family: "SimonCircularPro-Book", sans-serif;
  }

  h1.thin,
  h2.thin,
  h3.thin,
  h4.thin,
  h5.thin,
  h6.thin,
  .form-control.search-input,
  .btn-find-nearby,
  .search-links-header,
  .search-results-header {
    font-family: "SimonCircularPro-Light", sans-serif;
  }

  @media (--sm-viewport-down) {
    .card-secondary-desc {
      font-family: "SimonCircularPro-Book", sans-serif;
    }
  }

  .medium {
    font-family: "SimonCircularPro-Medium", sans-serif !important;
  }

  .bold {
    font-family: "SimonCircularPro-Bold", sans-serif !important;
  }

  .black {
    font-family: "SimonCircularPro-Black", sans-serif !important;
  }

  .book,
  .adp,
  #smartbanner,
  #smartbanner .sb-close {
    font-family: "SimonCircularPro-Book", sans-serif !important;
  }

  .light,
  .thin {
    font-family: "SimonCircularPro-Light", sans-serif !important;
  }

  .italic {
    font-family: "SimonCircularPro-Bookitalic", sans-serif !important;
  }

  .bold-italic {
    font-family: "SimonCircularPro-Bolditalic", sans-serif !important;
  }

  .black-italic {
    font-family: "SimonCircularPro-Blackitalic", sans-serif !important;
  }

  .medium-italic {
    font-family: "SimonCircularPro-Mediumitalic", sans-serif !important;
  }

  .thin-italic,
  .light-italic {
    font-family: "SimonCircularPro-Lightitalic", sans-serif !important;
  }

  .circular-xx-sub-black {
    font-family: "CircularXXSub-Black", sans-serif !important;
  }

  .circular-xx-sub-black-italic {
    font-family: "CircularXXSub-BlackItalic", sans-serif !important;
  }

  .circular-xx-sub-bold {
    font-family: "CircularXXSub-Bold", sans-serif !important;
  }

  .circular-xx-sub-bold-italic {
    font-family: "CircularXXSub-BoldItalic", sans-serif !important;
  }

  .circular-xx-sub-book {
    font-family: "CircularXXSub-Book", sans-serif !important;
  }

  .circular-xx-sub-book-italic {
    font-family: "CircularXXSub-BookItalic", sans-serif !important;
  }

  .circular-xx-sub-light {
    font-family: "CircularXXSub-Light", sans-serif !important;
  }

  .circular-xx-sub-light-italic {
    font-family: "CircularXXSub-LightItalic", sans-serif !important;
  }

  .circular-xx-sub-medium {
    font-family: "CircularXXSub-Medium", sans-serif !important;
  }

  .circular-xx-sub-medium-italic {
    font-family: "CircularXXSub-MediumItalic", sans-serif !important;
  }

  .circular-xx-sub-thin {
    font-family: "CircularXXSub-Thin", sans-serif !important;
  }

  .circular-xx-sub-thin-italic {
    font-family: "CircularXXSub-ThinItalic", sans-serif !important;
  }

  .circular-xx-web-black {
    font-family: "CircularXXWeb-Black", sans-serif !important;
  }

  .circular-xx-web-black-italic {
    font-family: "CircularXXWeb-BlackItalic", sans-serif !important;
  }

  .circular-xx-web-bold {
    font-family: "CircularXXWeb-Bold", sans-serif !important;
  }

  .circular-xx-web-bold-italic {
    font-family: "CircularXXWeb-BoldItalic", sans-serif !important;
  }

  .circular-xx-web-book {
    font-family: "CircularXXWeb-Book", sans-serif !important;
  }

  .circular-xx-web-book-italic {
    font-family: "CircularXXWeb-BookItalic", sans-serif !important;
  }

  .circular-xx-web-light {
    font-family: "CircularXXWeb-Light", sans-serif !important;
  }

  .circular-xx-web-light-italic {
    font-family: "CircularXXWeb-LightItalic", sans-serif !important;
  }

  .circular-xx-web-medium {
    font-family: "CircularXXWeb-Medium", sans-serif !important;
  }

  .circular-xx-web-medium-italic {
    font-family: "CircularXXWeb-MediumItalic", sans-serif !important;
  }

  .circular-xx-web-thin {
    font-family: "CircularXXWeb-Thin", sans-serif !important;
  }

  .circular-xx-web-thin-italic {
    font-family: "CircularXXWeb-ThinItalic", sans-serif !important;
  }
}
