/* Type Styles */

.bold {
  font-family: Arial, Helvetica, sans-serif !important;
}

.black {
  font-family: Arial, Helvetica, sans-serif !important;
}

.book {
  font-family: Arial, Helvetica, sans-serif !important;
}

.light {
  font-family: Arial, Helvetica, sans-serif !important;
}

.italic {
  font-family: Arial, Helvetica, sans-serif !important;
}

.center {
  text-align: center !important;
}
