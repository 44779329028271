/* Nabar - for navigational blocks and styling with branding */

/* Wrapper and base class
 *
 * Provide a static navbar from which we expand to create full-width, fixed, and
 * other navbar variations.
 */

.navbar {

  @include clearfix;
  padding: var(--navbar-padding-y) var(--navbar-padding-x);
  position: relative;
}

/* Navbar alignment options
 *
 * Display the navbar across the entirety of the page or fixed it to the top or
 * bottom of the page.
 */

/* A static, full width modifier with no rounded corners. */

.navbar-full {
  z-index: var(--zindex-navbar);
}

/* Fix the top/bottom navbars when screen real estate supports it */

.navbar-fixed-top,
.navbar-fixed-bottom {
  left: 0;
  position: fixed;
  right: 0;
  z-index: var(--zindex-navbar-fixed);
}

.navbar-fixed-top {
  top: 0;
}

.navbar-fixed-bottom {
  bottom: 0;
}

.navbar-sticky-top {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: var(--zindex-navbar-sticky);
}

/* Brand/project name */

.navbar-brand {
  float: left;
  font-size: var(--base-font-size);
  margin-right: 0; /* 1rem? */
  padding-bottom: var(--navbar-brand-padding-y);
  padding-top: var(--navbar-brand-padding-y);
  min-width: 175px;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  /* Full width brand container for tablet and mobile devices */

  @media (--lg-viewport-down) {
    width: 100%;
    min-width: 100px;
    height: var(--navbar-logo-height);
    margin-bottom: var(--navbar-logo-margin-y);
    margin-top: var(--navbar-logo-margin-y);
  }
}

.navbar-logo {
  display: block;
  float: left;
  height: var(--navbar-logo-height);
  margin-bottom: var(--navbar-logo-margin-y);
  margin-top: var(--navbar-logo-margin-y);

  /* Center logo within brand container for tablet and mobile devices */

  @media (--xl-viewport-down) {
    max-width: 180px;
  }

  @media (--lg-viewport-down) {
    float: none;
    max-width: 200px;
    height: auto;
    margin: 0 auto;
    min-height: 40px;
    max-height: 40px;
  }
}

.navbar-divider {
  background-color: var(--gray);
  float: left;
  height: var(--navbar-nav-font-size);
  margin: var(--navbar-nav-link-padding-y) var(--navbar-divider-spacing);
  overflow: hidden;
  width: 1px;

  &::before {
    content: "\00a0";
  }
}

/* Navigation
 *
 * Custom navbar navigation built on the base `.nav` styles.
 */

.navbar-nav {
  .nav-item {
    float: left;
  }

  .nav-link {
    color: var(--navbar-nav-link-color);
    display: block;
    font-family: Arial, Helvetica, sans-serif;
    font-size: var(--navbar-nav-font-size);
    letter-spacing: 0.05em;
    line-height: 1;
    padding-bottom: var(--navbar-nav-link-padding-y);
    padding-top: var(--navbar-nav-link-padding-y);

    &:hover,
    &:focus {
      color: var(--navbar-nav-link-hover-color);
    }

    + .nav-link {
      margin-left: var(--navbar-nav-spacer);
    }
  }

  .nav-item + .nav-item {
    margin-left: var(--navbar-nav-spacer);
  }

  .open > .nav-link,
  .active > .nav-link,
  .nav-link.open,
  .nav-link.active {
    & {
      color: var(--navbar-nav-link-active-color);

      &:hover,
      &:focus {
        color: var(--navbar-nav-link-color);
      }
    }
  }
}

/* Dark links against a light background - Simon Central Header */

.navbar-light {
  background: var(--navbar-light-bg);

  .navbar-brand {
    color: var(--navbar-light-active-color);
    fill: var(--navbar-light-color);
  }

  .navbar-brand:hover,
  .navbar-brand:focus {
    color: var(--navbar-light-active-color);
  }

  .navbar-nav {
    .nav-link {
      color: var(--navbar-light-color);
    }

    .nav-link:hover,
    .nav-link:focus {
      color: var(--navbar-light-hover-color);
    }
  }

  .navbar-nav .open > .nav-link,
  .navbar-nav .active > .nav-link,
  .navbar-nav .nav-link.open,
  .navbar-nav .nav-link.active {
    &,
    &:hover,
    &:focus {
      color: var(--navbar-light-active-color);
    }
  }

  .navbar-divider {
    background-color: rgba(255, 255, 255, 1);
  }
}

/* White links against a dark background - Default Simon Header */

.navbar-dark {
  background: var(--navbar-dark-bg);

  .navbar-brand {
    color: var(--navbar-dark-active-color);
    fill: var(--white);
  }

  .navbar-brand:hover,
  .navbar-brand:focus {
    color: var(--navbar-dark-active-color);
  }

  .navbar-nav {
    .nav-link {
      color: var(--navbar-dark-color);
    }

    .nav-link:hover,
    .nav-link:focus {
      color: var(--navbar-dark-hover-color);
    }
  }

  .navbar-nav .open > .nav-link,
  .navbar-nav .active > .nav-link,
  .navbar-nav .nav-link.open,
  .navbar-nav .nav-link.active {
    & {
      color: var(--navbar-dark-active-color);
    }

    &:hover,
    &:focus {
      color: var(--navbar-dark-color);
    }
  }

  .navbar-divider {
    background-color: rgba(255, 255, 255, 1);
  }
}

.navbar-sidepanel {
  z-index: var(--zindex-navbar-sidepanel);

  @media (--lg-viewport-down) {
    background-color: #000;
    height: calc(100vh - var(--navbar-height));
    margin: 0;
    max-width: 300px;
    min-height: calc(100vh - var(--navbar-height));
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0 0 100px;
    position: fixed;
    right: -100%;
    top: var(--navbar-height);
    transition: right 0.3s;
    width: 100%;

    &.open {
      transition: right 0.3s;
      right: 0;
    }

    .nav-item {
      float: none;
      width: 100%;
      margin: 0;
      padding: 0;
    }

    .nav-item + .nav-item,
    .nav-link + .nav-link {
      margin-left: 0;
    }

    .nav-link {
      border-bottom: 1px solid #1d1d1d;
      color: var(--navbar-sidepanel-link-color);
      display: block;
      font-size: var(--font-size-small);
      letter-spacing: 0.05em;
      padding: 1.1rem;
      text-transform: uppercase;
    }

    .nav-link.nav-link-secondary {
      background-color: #393939;
      border-bottom-color: #484848;
      color: var(--navbar-sidepanel-secondary-link-color);
      font-family: Arial, Helvetica, sans-serif;
      font-size: var(--base-font-size);
      text-transform: capitalize;
    }

    .nav-link:hover,
    .nav-link.active,
    .nav-link-secondary:hover,
    .nav-link-secondary.active {
      color: var(--navbar-sidepanel-active-link-color);
    }

    .nav-link:hover svg,
    .nav-link.active svg {
      fill: var(--navbar-sidepanel-active-link-color);
    }

    .nav-link-group {
      padding-left: 1.1rem;
      padding-right: 1.1rem;
      border-bottom: 1px solid #1d1d1d;
    }

    .nav-link-group .nav-link {
      border-bottom: none;
      padding-left: 0;
      padding-right: 0;
    }

    .nav-link-spacer {
      padding: 1.1rem 5px;
      color: var(--navbar-sidepanel-link-color);
      display: block;
      font-size: var(--font-size-small);
      letter-spacing: 0.05em;
      line-height: 1;
      transition: none;
    }
  }

  svg {

    @mixin transition fill;
  }

  .icon-pagination {
    fill: var(--navbar-sidepanel-secondary-link-color);
  }

  .icon-search {
    fill: var(--navbar-sidepanel-link-color);
    height: 15px;
    width: 15px;
  }
}

.navbar-sidepanel-mask {
  background: rgba(0, 0, 0, 0.75);
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: var(--navbar-height);
  visibility: hidden;
  width: 100%;
  z-index: var(--zindex-navbar-sidepanel-mask);

  @include transition(opacity, 0.1s, ease-in);

}

.sidepanel-active {
  height: 100%;
  overflow: hidden !important;
  width: 100%;
  position: fixed;

  & .navbar-sidepanel-mask {
    opacity: 1;
    visibility: visible;

    @media (--lg-viewport-up) {
      opacity: 0;
    }
  }
}

/* Navbar Toggle / Hamburger Icon */

.navbar-toggle {
  cursor: pointer;
  display: none;
  margin-top: -10px;
  padding-left: 1.5rem;
  padding-right: 0.86806rem;
  position: absolute;
  right: 0;
  top: 50%;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
  transition-timing-function: linear;
  z-index: var(--zindex-navbar-toggle);

  @media (--lg-viewport-down) {
    display: inline-block;
  }
}

.hamburger-box {
  display: inline-block;
  height: 22px;
  position: relative;
  width: 23px;
}

.hamburger-inner {
  display: block;
  margin-top: calc(4px / -2);
  top: 50%;

  &,
  &::before,
  &::after {
    background-color: #fff;
    border-radius: 0;
    height: 1.5px;
    position: absolute;
    transition-duration: 0.15s;
    transition-property: transform;
    transition-timing-function: ease;
    width: 23px;
  }

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::before {
    top: calc((6px + 4px) * -1);
  }

  &::after {
    bottom: calc((6px + 4px) * -1);
  }
}

.hamburger--squeeze {
  .hamburger-inner {
    transition-duration: 0.1s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &::before {
      transition: top 0.1s 0.14s ease, opacity 0.1s ease;
    }

    &::after {
      transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }
}

.hamburger--squeeze.open {
  .hamburger-inner {
    transform: rotate(45deg);
    transition-delay: 0.14s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    width: 26px;

    &::before {
      opacity: 0;
      top: 0;
      transition: top 0.1s ease, opacity 0.1s 0.14s ease;
    }

    &::after {
      bottom: 0;
      transform: rotate(-90deg);
      transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
      width: 26px;
    }
  }
}
