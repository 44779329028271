/*
 * Pagination - Pager left and right arrows with number blocks filling the space between
 */

.pagination {
  display: inline-block;
  margin-bottom: var(--spacer-y);
  margin-top: var(--spacer-y);
  padding-left: 0;
}

.page-item {
  display: inline; /* Remove list-style and block-level defaults */

  /* First child in pagination group - the left arrow */

  &:first-child {
    .page-link {
      border-bottom-left-radius: 0;
      border-left-color: var(--pager-border-color);
      border-top-left-radius: 0;
      margin-left: 0;
    }
  }

  /* Last child in pagination group - the right arrow */

  &:last-child {
    .page-link {
      border-bottom-right-radius: 0;
      border-right-color: var(--pager-border-color);
      border-top-right-radius: 0;
    }
  }

  /* Class for active pagination item - i.e. the current active page */

  &.active .page-link {
    &,
    &:hover,
    &:focus {
      background-color: var(--pagination-active-bg);
      border-left-color: var(--pagination-active-border);
      border-right-color: var(--pagination-active-border);
      color: var(--pagination-active-color);
      cursor: default;
      z-index: 2;
    }
  }

  /* Disabled class - to use for arrows when out of options */

  &.disabled .page-link {
    &,
    &:hover,
    &:focus {
      background-color: var(--pagination-disabled-bg);
      color: var(--pagination-disabled-color);
      cursor: var(--cursor-disabled);
      pointer-events: none;
    }

    &:hover .icon-pagination,
    &:focus .icon-pagination {
      fill: var(--pagination-disabled-color);
    }
  }
}

/* The individual blocks making up the pagination control */

.page-link {
  background-color: var(--pagination-bg);
  border: var(--pagination-border-width) solid var(--pagination-border-color);
  border-left-color: var(--brand-gray);
  color: var(--pagination-color);
  font-family: Arial, Helvetica, sans-serif;
  float: left; /* Collapse white-space */
  margin-left: -1px; /* To collapse the adjacent borders */
  padding: var(--pagination-padding-y) var(--pagination-padding-x);
  position: relative;
  text-decoration: none;

  &:hover,
  &:focus {
    background-color: var(--pagination-hover-bg);
    border-left-color: var(--pagination-active-border);
    border-right-color: var(--pagination-active-border);
    color: var(--pagination-hover-color);

    .icon-pagination {
      fill: var(--pagination-active-color);
    }
  }
}

/* The arrow icon for next and prev buttons */

.icon-pagination {
  height: 10px;
  fill: var(--pagination-icon-color);
  margin-top: 5px;
  width: 8px;
}
