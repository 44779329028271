/* Images
 *
 * Responsive images (ensure images don't scale beyond their parents)
 *
 * This is purposefully opt-in via an explicit class rather than being the default for all `<img>`s.
 */

.img-fluid {

  @include img-fluid;
}

/* Fluid Image with no width constraints - WARNING: WILL STRETCH HIGHER THAN IMAGE WIDTH/HEIGHT */

.img-full {
  width: 100%;

  @include img-fluid;
}

/* Lazy Load */

.img-lazy {
  display: block;
}

/* Image thumbnails */

.img-thumbnail {
  transition: all 0.2s ease-in-out;

  /* Keep them at most 100% wide */

  @include img-fluid(inline-block);
}

/* Perfect circle image */

.img-circle {
  border-radius: 50%;
}

/* Figures */

.figure {
  display: inline-block; /* Ensures the caption's text aligns with the image. */
}

.figure-img {
  line-height: 1;
  margin-bottom: calc(var(--spacer-y) / 2);
}

.figure-caption {
  color: var(--gray-light);
  font-size: 90%;
}
