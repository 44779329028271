/* Simon.com Search Pages */

.search-page {
  margin-top: 148px;

  &-toggle {
    & > .nav-item {
      flex: 1;
      text-align: center;
    }

    @media (--md-viewport-down) {
        margin-top: 1.9152rem;
    }
  }

  &-header {
    padding: 1.9152rem 0;

    .flex {
      justify-content: space-between;

      @media (--sm-viewport-down) {
        flex-direction: column;
      }
    }
  }

  &-map.open + .search-page-filters:not(.simon-section-gray) {
    margin-top: 1.9152rem;
  }
  
  &-map-toggle {
    align-self: center;
  }

  .map-toggle-icon {
    transform: rotate(90deg);
  }

  &-map-toggle.open .map-toggle-icon {
    transform: rotate(-90deg);
  }

  .map-toggle-icon {
    fill: var(--gray);
    position: relative;
    top: 3px;
    margin-left: 5px;

    @mixin transition all, 0.2s, ease-in-out;
  }
  
  &-filters {
    padding: 0 0 1.9152rem 0;

    &.simon-section-gray {
      padding-top: 1.9152rem;

      @media (--sm-viewport-down) {
        background-color: transparent;
      }
    }

     @media (--md-viewport-up) {
         &.hidden-xs-up + .search-page-results {
       padding-top: 1.9152rem;
    }
      }
   
  }

  &-results {
    min-height: 500px;
  }

  @media (--sm-viewport-down) {
        .search-page-map:not(.open) + .search-page-filters {
          padding-top: 0;
        }
      }
  
  &-title {
    margin: 0;

    @media (--sm-viewport-down) {
       text-align: center;
    }

    @media (--lg-viewport-down) {
       font-size: var(--font-size-h4);
    }
  }
  
  &-results-item {
    overflow: hidden;

    &:hover .search-page-results-item-links {
      opacity: 1;
    }
  }

  &-results-item-links {
    position: absolute;
    z-index: 2;
    right: 15px;
    background-color: rgba(0,0,0,0.8);
    left: 15px;
    bottom: 30px;
    font-size: 11px;
    text-align: center;
    color: #ffffff;
    opacity: 0;
    padding: 10px 5px;

    transition: opacity 0.5s ease, bottom 0.3s ease;
  }
}
