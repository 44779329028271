/*
 * Footers - for styling footer elements
 */

/* Default Simon Footer */

.footer-default {
  padding: 0 0 0.6rem;
  background-color: var(--white);

  @media (--lg-viewport-down) {
    margin-bottom: 0;

    .container {
      max-width: none;
    }

    *[class^="col-"] {
      padding: 0;
    }

    .nav-title {
      display: none;
    }

    .footer-social {
      margin-bottom: var(--footer-social-spacing);
      text-align: center;
    }

    .footer-links .nav-item {
      border-bottom: var(--footer-default-border-width) solid var(--footer-default-border-color);
      border-top: var(--footer-default-border-width) solid var(--footer-default-border-color);

      & + .nav-item,
      &:first-child {
        border-top: 0;
      }
    }

    .footer-links .nav-link {
      font-size: var(--footer-default-mobile-font-size);
      padding: var(--footer-default-mobile-padding-y) var(--footer-default-mobile-padding-x);
      width: 100%;
    }

    .footer-links .footer-legal {
      margin: 0;
      padding: var(--footer-default-mobile-padding-y) var(--footer-default-mobile-padding-x);
    }

    .footer-divider {
      margin: 0;
    }
  }
}

/* Full width banner for footer promotion */

.footer-banner {
  background: var(--footer-banner-bg);
  text-align: center;

  .nav-link {
    color: var(--footer-banner-color);
    font-size: var(--footer-banner-font-size);
    letter-spacing: 0.05em;
    line-height: 1;
    padding: var(--footer-banner-padding-y) var(--footer-banner-padding-x);

    &:hover,
    &:focus {
      color: var(--footer-banner-active-color);
    }
  }
}

/* The Find another mall search link with search icon to the left of the text */

.footer-search {
  font-size: var(--footer-links-font-size);
  text-decoration: none;

  @mixin transition color;

  &:hover,
  &:focus {
    color: var(--footer-icon-hover-color);

    .icon-search {
      fill: var(--footer-icon-hover-color);
    }
  }

  .icon-search {
    display: inline-block;
    float: left;
    margin-right: 12px;
    height: 22px;
    width: 20px;

    @mixin transition fill;
  }
}

/* Footer social icons */

.footer-social {
  margin-top: var(--footer-social-spacing);

  .social-icon {
    fill: #000;
    height: var(--footer-social-icon-size);
    width: var(--footer-social-icon-size);

    @mixin transition fill;

    &:hover,
    &:focus {
      fill: var(--footer-icon-hover-color);
    }
  }

  .nav-item + .nav-item {
    margin-left: calc(var(--spacer) * 2);
  }
}

/* Lists of footer links */

.footer-links {
  .footer-legal {
    margin-top: var(--footer-default-spacing);
  }

  ul.nav + ul.nav {
    padding-left: var(--spacer);
  }

  .nav-title,
  .nav-item {
    font-size: var(--footer-links-font-size);
  }

  .nav-item {
    padding-bottom: 3px;
  }
}

/* Line divider between social/search and footer links */

.footer-divider {
  margin: var(--footer-divider-padding-y) var(--footer-divider-padding-x);
}

/* Footer signup (Currently on Web & Brands) */

footer .footer-signup-form {
  margin-top: 1.9152rem;
  margin-bottom: 1.9152rem;

  h4 {
    letter-spacing: -0.06em;
  }

  @media(--lg-viewport-down) {
    padding-left: 0.8680555rem;
    padding-right: 0.8680555rem;
    text-align: center;

    form {
      max-width: 720px;
      margin: 0 auto;
    }
  }
}
