/* MALL NAV HIDE */

.nav-item.hide-lg {
  @media (--xl-viewport-down) and (--lg-viewport-up) {
    display: none;
  }
}

.search-page .sub-nav-offset {
    padding-top: 0px;
    margin-top: 0px;
}

.search-page.store.simon-section {
    padding-top: 1em;
}

@media only screen and (max-width: 360px) {
    .montreal main {
        margin-top: calc(var(--navbar-global-height) + var(--navbar-mobile-height));
    }
        .montreal main.mall-alert-active.sbActive {
            margin-top: 25px;
        }
        .montreal main.mall-alert {
            margin-top: calc(var(--navbar-mobile-height) + var(--mall-alert-height));
        }
        .montreal main.mall-alert-active {
            margin-top: calc(var(--navbar-mobile-height) + var(--mall-alert-height));
        }

}

@media only screen and (max-width: 815px) {
    .search-page.store.simon-section {
        padding-top: 1rem;
    }
}

@media only screen and (max-width: 900px) {
    .montreal main {
        margin-top: calc(var(--navbar-global-height) + var(--navbar-mobile-height));
    }
    .montreal main.mall-alert-active.sbActive {
        margin-top: 25px;
    }
    .montreal main.sbActive {
        margin-top: 25px;
    }
    .montreal main.mall-alert-active {
        margin-top: calc(var(--navbar-mobile-height) + var(--mall-alert-height));
    }
    .search-page .sub-nav-offset {
        padding-top: 0px;
        margin-top: 0px;
    }
}
/* MALL NAV */
.mall-nav-wrapper {
    display: flex;
    justify-content: space-between;
}

.mall-nav-wrapper > ul.navbar-nav:first-of-type {
  @media (--lg-viewport-down) {
    width: 100%; flex: 1 0 100%;
  }
}

.tabs {
  flex: 1 0 80%;
  max-width: 80%;
  position: relative;

  &:not(.jsfied) {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  a,
  button {
    width: 100%;
    height: 100%;
    text-align: center;
  }

  .primary {
    display: flex;
    justify-content: flex-end;
    
    li > a, 
    li > button {
      white-space: nowrap;
    }
    
    .more {
      > button {
        font-size: .6414rem;
        letter-spacing: .05em;
        line-height: 1;
        padding: 0;
        color: #757575;
      }
      > button[aria-expanded="true"] {
        color: #000;  
      }
      > button span {
        display: inline-block;
        transition: transform 0.2s;
      }
    }
  }
  
  &.show-secondary .primary {
    .more > button span {
      transform: rotate(180deg);
    }
  }
  
  .secondary {
    max-width: 100%;
    min-width: 10em;
    display: none;
    position: absolute;
    top: 54px;
    right: 0;
    border-left: 1px solid #b4b2b0;
    border-right: 1px solid #b4b2b0;
    border-bottom: 1px solid #b4b2b0;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.3);
    animation: nav-secondary 0.2s;
    z-index: 99;

    li.nav-item {      
      background-color: #fff;
      margin-left: 0;
      border-top: 1px solid #b4b2b0;
      float: none;
      margin-top: 0;
      
      & a {
        padding: 10px 0;
      }
    }
  }
  
  &.show-secondary .secondary {
    display: block;
  }
}

@keyframes nav-secondary {
  0% {
    opacity: 0;
    transform: translateY(-1em);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@media(--lg-viewport-up) {
    .mall-nav-wrapper {
        & .mall-nav.primary {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
        }
        & .nav-mall-hours .nav-link {
            line-height: 1.5;
        }
    }
    .nav-mall-hours {
        min-height: var(--navbar-height);
    }
}

/* MALL LIST */

.mall-list {
  &-item

{
  margin-bottom: 30px;
  position: relative;
  background-color: var(--black);
  &:hover .mall-list-item-img

{
  opacity: 0.7;
  backface-visibility: hidden;
  transform: translateZ(0) scale(1, 1);
}

}

&-item-text {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%) scale(1, 1);
  left: 0;
  color: var(--white);
  text-align: center;
  padding: 0 var(--spacer);
  backface-visibility: hidden;
}

&-item-phone {
  margin-top: 0.76968rem;
  display: block;
}

&-item-location {
  margin-bottom: 0;
  text-transform: capitalize;
}

&-item-img {
  opacity: 0.5;
  backface-visibility: hidden;
  transform: translateZ(0) scale(1, 1);
  @include transition(opacity);
}

}

/* MALL HOME */

.mall-alert {
  background-color: var(--brand-primary);
  color: var(--white);
  padding: 0.64137rem 0;


  & .mall-alert-text a {
    display: inline-block;
    margin-left: 0.5rem;
    &:focus {
      color: var(--gray-lighter);
    }
  }

  @media (--md-viewport-down) {
    text-align: center;
  }

  .social-icon

{
  fill: white;
  position: relative;
  display: block;
}

&-btn {
  padding-right: 1.9152rem;
  padding-left: 5px;

  @media (--md-viewport-down) {
    padding-right: 0;
  }
}

.close {
    position: absolute;
    right: 15px;
    top: 2px;
    display: block;
}

&-text {
  padding-top: 2px;
  padding-right: 30px;
}

}

.mall-map-view .mall-alert {
    position: fixed;
    top: 74px;
    left: 0;
    right: 0;
    z-index: 3;
    display: none;
}

.mall-map-view .cardless-ad-spot {
    display: none;
}

.mall-homepage .logo-slider.featured-stores {
  padding-top: 0;
  margin-bottom: 50px;
}

.mall-homepage {
  padding-top: 1.9152rem;
  padding-bottom: 0;
  
  &.simon-section-gray-mobile {
    @media (--md-viewport-down) {
      padding-top: var(--section-padding-mobile); background-color: var(--section-bg);
    }
  }

@media (--lg-viewport-up) {
  padding-top: 1.1rem;
}

@media (--md-viewport-up) {
  padding-top: 0.05rem; padding-bottom: 3.30947rem;
}

&-info {
  margin-bottom: 1.9152rem;
}

&-border {
  border-right: 1px solid var(--black);
  border-left: 1px solid var(--black);
}

&-info .icon {
  margin: 0 auto 0.64137rem;
}

}

.nav-mall-hours {
  @media (--lg-viewport-down) {
    text-align: center; width: 100%;
  }
}

/* MALL HOURS */

.hours-hero {
  background-color: #C9C9C9;
}

.mall-hours-header {
  margin-bottom: 1.33rem;

  @media (--lg-viewport-down) {
    font-size: var(--font-size-h4);
  }

  @media (--md-viewport-down) {
    text-align: center; margin-bottom: 1.7rem;
  }
}

.hour {
  padding: 1.10833rem 0;
  font-family: 'SimonCircularPro-Book', Arial, Helvetica, sans-serif;
  border-bottom: 1px solid #757575;
  border-top: 1px solid #757575;
  display: flex;
  justify-content: space-between;

  @media (--md-viewport-down) {
    flex-wrap: wrap;
    padding-left: 15px;
    padding-right: 15px;
  }

  & > * {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &-day.hour-day-with-description {
    flex: 1 1 10%;

    & + .hour-day {
      flex: 1 1 15%;
      & + .hour-time {
        flex: 1 1 30%;
      }
    }

  }

  &-day,
  &-time {
    flex: 1 1 33.333%;

    & .legal-text {
      @media (--md-viewport-up) {
        margin-bottom: 0;
      }
    }

    @media (--md-viewport-down) {
      flex-basis: 68%;
      justify-content: flex-start;
    }
  }

  &-day.hour-day-with-description + .hour-description {
      @media (--md-viewport-down) {
      order: 2;
      flex-basis: 100%;
      &.text-right {
        text-align: left;
      }
    }
  }
  &-time {
    @media (--md-viewport-down) {
      order: 2;
      width: 100%;
    }
  }
  &-day.hour-day-with-description {
    @media (--md-viewport-down) {
      flex-basis: 50%;
    }
  }
  &-weather {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    @media (--md-viewport-down) {
      flex: 1 0 32%;
      align-items: flex-start;

      & .weather-icon {
        width: 20px;
        height: 21px;
      }
    }
  }
}

.mall-hours-regular,
.mall-hours-special {
  & .hour-day,
  & .hour-time {
    @media (--md-viewport-down) {
      flex: 1 0 50%;
      width: auto;
      justify-content: center;
    }

    @media (--sm-viewport-down) {
      flex: 1 0 100%;
    }

    & .legal-text {
      max-width: 90%;
    }
  }
}

.hour + .hour {
  border-top: none;
}

.mall-hours-special,
.mall-hours-regular {
  padding-top: 1.9152rem;

  @media (--md-viewport-up) {
    padding-top: 3.30947rem;
  }
}

.hour-date,
.hour-weather {
  &.pull-xs-right

{
  @media (--sm-viewport-down) {
    position: absolute; right: 15px; top: -10px;
  }
}

}

/* MALL ABOUT */

.mall-hero {
  .jumbotron-title

{
  margin-bottom: 1.10833rem;
  width: 100%;
}

.jumbotron-desc {
  margin-bottom: 0;
  font-family: 'SimonCircularPro-Book', Arial, Helvetica, sans-serif;
  font-size: var(--base-font-size);

  @media (--lg-viewport-up) {
    font-size: var(--font-size-h5);
  }
}

}

.mall-gmap {
  position: relative;
}

.btn-get-directions {
  position: absolute;
  bottom: 47px;
  left: 50%;
  transform: translateX(-50%);

  @media (--sm-viewport-down) {
    left: 15px; width: auto; transform: translateX(0); right: 15px;
  }
}

.featured-stores-title,
.mall-contact-mobile-title {
  margin-top: 3.30947rem;
  margin-bottom: 0;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--brand-gray);
}

.logo-slider.featured-stores {
  padding-bottom: 0;
}

.accordion-amenities {
  overflow: hidden;
  margin-bottom: 1.9152rem;
}

.accordion-transportation {

  @media (--md-viewport-down) {
    margin-bottom: 1.9152rem;
  }
}

.mall-gallery {
  display: none;
  padding: 0 30px;
  &.slick-initialized

{
  display: block;
}

&-img {
  width: 180px;
  height: 180px;
  margin: 0 15px;
  overflow: hidden;
  position: relative;
  img

{
  position: absolute;
  left: -1000%;
  right: -1000%;
  top: -1000%;
  bottom: -1000%;
  margin: auto;
  width: 150%;
  max-width: 150%;
}

}
}

.mall-gallery-modal {
  display: none;
  &.slick-initialized

{
  display: block;
}

&-img {
  width: 500px;
  height: 400px;
  margin: 15px;
  overflow: hidden;
  position: relative;
  img

{
  position: absolute;
  left: -1000%;
  right: -1000%;
  top: -1000%;
  bottom: -1000%;
  margin: auto;
  width: 500px;
}

}
}

.mall-contact-mobile {
  &-title

{
  margin-bottom: 1.10833rem;
}

&-subtitle {
  margin-bottom: 0.76968rem;
}

}

.learn-more-mobile .btn {
    @media (--md-viewport-down) {
        border-bottom: 1px solid var(--white);
    }
}

/* STORE DIRECTORY */

.directory-store-tag-icon {
  fill: var(--white);
}

.store-name .directory-store-tag {
    top: -.25rem;
    position: relative;
    margin-bottom: 0;
    margin-right: .25rem;
}

.directory {
  min-height: calc(100vh - 74px);

  & .simon-section-gray {
    min-height: calc(100vh - 74px);
  }

  @media (--sm-viewport-down) {
    padding-bottom: 0;
    
    .phone + .directory-store-tag {
      margin-top: 1.33rem;
    }
  }

  &-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &-header {
    margin-bottom: 1.9152rem;
  }

&-store-tag {
    position: static;
    display: inline-block;
    padding: 0.5rem;
    background-color: var(--brand-primary);
    line-height: 1;
    color: var(--white);
    font-size: 0.65rem;
    margin-bottom: 0.5rem;

    &.store-tag-large {
        padding: 0.75rem;
        font-size: .76968rem;
        margin: 0;
    }

    @media (--sm-viewport-down) {
        font-size: 11px;
        display: inline-block;
        margin-bottom: 0.5rem;
        margin-right: 0.5rem;
    }

    &-wrapper {
        @media (--sm-viewport-down) {
            display: inline-block;
        }
    }

    &-list {
        @media (--sm-viewport-up) {
            position: absolute;
            top: 2.5rem;
            right: .8680555rem;
            text-align: right;
        }

        &.content-stream-tag-list {
            position: absolute;
            top: 1rem;
            right: 0;
            z-index: 1;
        }
    }

    & a,
    & a:hover,
    & a:focus {
        color: var(--white);
        text-decoration: underline;
    }

  }

  &-store {
    display: flex;

    .card-secondary {
      display: flex;
      flex-direction: column;
      width: 100%;
      flex: 1 1 auto;
    }

    .card-secondary-btn {
      text-align: center;
    }

    .card-secondary-btn,
    a:first-child {
      flex: 0 0 auto;
    }

    .card-secondary-text {
      flex: 1 1 auto;
      background-color: #fff;
      text-align: center;

      & a:focus {
        & > h2, 
        & > div {
          color: var(--black);
        }
      }

      @media(--sm-viewport-down) {
          text-align: left;

          & .card-secondary-desc {
              color: var(--gray);
              font-family: 'SimonCircularPro-Book' !important;
          }
      }
    }

    .store-hours {
      display: none;
    }

    .card-secondary-title {
      color: var(--gray);
    }

    @media (--sm-viewport-down) {
      .card-secondary {
        flex-direction: row;
      }

      .card-secondary-title:not(.phone) {
        color: var(--black);
      }

      .card-secondary-title.phone {
        font-family: 'SimonCircularPro-Book';
      }
    }

    @media (--md-viewport-down) {
      margin-bottom: 1px;
    }

    @media (--sm-viewport-down) {
      margin-bottom: 1rem;
      flex-direction: column;

      & > .directory-store {
          margin-bottom: 0;
      }

      .card-secondary {
        border: 1px solid var(--brand-gray);
        position: relative;
        padding-right: 1.5rem;
      } 
    }

    @media (--sm-viewport-down) {
        .directory-button-container {
            display: flex;
        
            & .card-secondary-btn {
                margin-top: -1px;
                background-color: #fff;
                border: 1px solid var(--brand-gray);
                color: var(--gray);
                flex: 1 1 auto;
                display: flex;
                align-items: center;
                justify-content: center;
                & ~ .card-secondary-btn {
                    border-left: none;
                }

                & svg {
                    fill: var(--gray);
                    top: -1px !important;
                }
            }
        }
    }

    @media (--sm-viewport-down) {
      margin-bottom: 1rem;
      flex-direction: column;

      & > .directory-store {
          margin-bottom: 0;
      }

      .card-secondary {
        border: 1px solid var(--brand-gray);
        position: relative;
        padding-right: 1.5rem;
      } 
    }

    @media (--sm-viewport-down) {
        .directory-button-container {
            display: flex;
        
            & .card-secondary-btn {
                margin-top: -1px;
                background-color: #fff;
                border: 1px solid var(--brand-gray);
                color: var(--gray);
                flex: 1 1 auto;
                display: flex;
                align-items: center;
                justify-content: center;
                & ~ .card-secondary-btn {
                    border-left: none;
                }

                & svg {
                    fill: var(--gray);
                    top: -1px !important;
                }
            }
        }
    }

    & .name {
      @media (--sm-viewport-up) {
        font-family: 'SimonCircularPro-Book';
      }
    }
  }

  &-filters {
    margin-bottom: 1.10833rem;

    @media (--md-viewport-down) {
      margin-bottom: 1.9152rem;
    }
  }

  &-filter {
    margin-bottom: 1.10833rem;
  }

  &-sort,
  &-print {
    margin-left: 30px;
    margin-bottom: 0;
    padding: 14px 0;
  
    & .icon {
      fill: var(--gray-dark);
      margin-left: 5px;
    }

    & .text {
      font-size: 0.76968rem;
      font-family: 'SimonCircularPro-Bold';
      color: var(--gray-dark);
      position: relative;
      top: -5px;
    }

    @media (--lg-viewport-down) {
      padding: 1px 0px;

      & .text,
      & .icon {
        float: right;
      }

      & .icon {
        margin-right: 7px;
      }
    }

    @media (--md-viewport-down) {
      & .text,
      & .icon {
        float: none;
      }

      & .icon {
        margin-right: 0;
      }
    }
  }

  &-sort {
    & .text {
      font-size: .7696rem;
    }

    @media (--md-viewport-down) {
      margin: 0; text-align: center; width: 100%; font-size: 0.7696rem; display: block; padding: 10px; border: 1px solid var(--gray);
    }
  }

  &-phone {
    & .icon {
      fill: var(--white);
      top: 2px;
      margin-right: 4px;
      position: relative;
      @media(--sm-viewport-down){
         top: 0;
      }
    }

    & .text {
      position: relative;
      top: -3px;
      @media(--sm-viewport-down){
         top: 0;
         margin: 0;
      }
    }
  }
}

.directory_search {
    @media(--xs-viewport-down){
        height: 117px;
        overflow-y: hidden;
    }

    @media(--sm-viewport-up) {
        height: 53px;
    }
}

.directory-ad-spot {
    padding-top: 2.5rem;

    @media(--md-viewport-down) {
        padding-top: 0.86806rem;
    }

    & + .directory {
        & .simon-section-gray {
            padding-top: 0;
        }
        & h1 {
            margin: 2.5rem 0;
            @media(--md-viewport-down) {
              margin: 2.25rem 0;
            }
        }
    }
}

#moreDining .directory-store-tag-list {
    top: 0.5rem;
}

/* MALL MAP */

.mall-map {
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 780px;
  width: 100%;

  @media (--md-viewport-up) {
    height: 830px;
  }

  @media (--lg-viewport-up) {
    height: 780px;
  }
}

.simon-section-mall-map {
  @media (--md-viewport-down) {
    padding-top: 0;
  }
}

/* STORE DETAIL */

.store {
  .icon-uber

{
    display: inline-block;
    fill: var(--diamond-list-bullet-color);
    height: var(--list-bullet-size);
    margin-right: calc(var(--spacer) / 2);
    width: var(--list-bullet-size);
    margin-top: var(--vertical-list-margin);
    fill: var(--gray);
}

&.simon-section {
    /* Impacts mall / Store padding */
    /*  padding-top: .8rem;*/
    padding-top: 2rem;
}

.small {
  margin-bottom: 6px;
}

&-entrance p, &-curbside p {
  margin-bottom: 1.10833rem;
}

.btn-back {
  display: block;
  width: 100%;
  text-align: left;
  padding-top: 1.9152rem;

  @media (--md-viewport-down) {
    text-align: center;
  }
}

.center-content {
  @media (--lg-viewport-up) {
    align-items: center; display: flex;
  }
}

.footer-social .nav-item + .nav-item {
  margin-left: 21px;
}

.btn-back-icon {
  fill: var(--gray);
  position: relative;
  top: 1px;
  margin-right: 5px;
}

&.search-page {
  margin-top: 0;
}

& .search-page-map-toggle {
  @media (--sm-viewport-down) {
    display: block; margin: 0 auto;
  }
}

&-name {
  margin-bottom: var(--spacer);

  @media (--lg-viewport-down) {
    font-size: var(--font-size-h4);
  }
}

&-logo img {
  @media (--lg-viewport-down) {
    margin: 0 auto 1.9152rem;
  }
}

&-no-logo {
  padding: 6rem 0;
}

&-hours {
  @media (--sm-viewport-down) {
    margin-bottom: var(--spacer);
  }
}

&-links .btn-block {
  margin-top: var(--spacer);
}

&-divider {
  border-bottom: 1px solid var(--brand-gray);
  margin-bottom: 1.79rem;
  padding-top: 1.62rem;

  @media (--lg-viewport-up) {
    margin-bottom: 1.79rem; padding-top: 1.62rem;
  }
}

&-desc {
  margin-bottom: 1.9152rem;

  @media (--lg-viewport-up) {
    margin-bottom: 2.89rem
  }
}

&-social {
    margin-top: 1.9152rem;
    text-align: center;
    .social-icon

{
  fill: var(--gray);
}

@media (--sm-viewport-down) {
  margin-top: var(--spacer);
}

}

&-locations {
  .simon-section-header

{
  margin-bottom: 1.596rem;
}

}

&-see-more {
  color: var(--card-footer-color);
  font-size: var(--base-font-size);
  padding: 0;

  &:hover,
  &:focus {
    color: var(--black);
  }
}

&-see-more-icon {
  width: var(--card-icon-size);
  height: var(--card-icon-size);
  fill: var(--card-footer-color);
  display: inline-block;
  margin-left: 2px;
}

&-social-desktop {
  text-align: center;
  margin-top: 1.68rem;
  margin-bottom: 1.38rem;
}

.list-blocks {
  margin-top: 9px;
  margin-bottom: 12px;
  &.open

{
  max-height: 100%;
}

&.closed {
  max-height: 86px;
  overflow: hidden;
}

}

&-categories {
  @media (--lg-viewport-down) {
    margin-top: 26px;
  }
}

}

.btn.tooltip {
  position: relative;
}

.btn .tooltip-desc {
  position: absolute;
  top: -1rem;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -100%);
  width: 15rem;
  padding: 1rem;
  background-color: var(--white);
  border: 1px solid var(--black);
  opacity: 0;
  transition: opacity 0.3s;
  white-space: normal;
  visibility: hidden;

  @media(--sm-viewport-down) {
    width: 100%;
  }

  &::before {
    content: " ";
    position: absolute;
    top: calc(100% - 2px);
    left: 50%;
    margin-left: -1rem;
    border-width: 1rem;
    border-style: solid;
    z-index: 2;
    border-color: var(--white) transparent transparent transparent;
  }

  &::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -1rem;
    border-width: 1rem;
    border-style: solid;
    border-color: var(--black) transparent transparent transparent;
  }
}

.svg-with-tooltip {
  position: relative;
  @media(--sm-viewport-down) {
    position: static;
  }
}

.btn .svg-with-tooltip:hover .tooltip-desc {
  opacity: 1;
  visibility: visible;
}

/* CONTENT STREAM */

.logo-loader,
.simon-logo {
  height: 150px;
  width: 275px;
  margin: 0 auto;
}

.logo-loader {
  height: 320px;
}

.simon-logo-path {
  stroke: var(--black);
  stroke-width: 10px;
  fill: none;
  transform-origin: center;
  &.animatable

{
  stroke-dasharray: 509.33;
  stroke-dashoffset: 509.33;
  animation: logoAnimation 1.75s ease-in-out infinite;
}

}

.line {
  width: 10px;
  height: 20px;
  background-color: var(--gray-lighter);
  transform: rotate(53.5deg);
  position: relative;
  &.right

{
  left: 149px;
  top: 79px;
}

&.left {
  left: 112px;
  top: 73px;
}

}

@keyframes logoAnimation {
  0% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.05);
  }

  87.5% {
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }

  to {
    stroke-dashoffset: 0;
  }
}

.content-stream {
  &-hero {
    background-color: #a0928f;
  }

  &-item,
  & .HtmlContentClass {
    margin-bottom: 30px;
    position: relative;
  }

  &-item-tenant-details .item-title {
      @media(--md-viewport-up) {
          width: calc(100% - 2rem);
      }
  }
}

.related-content .logo-slider {
  padding-top: 0;
}

/* COTNENT STREAM ITEM DETAILS */

.inline-hours-divider {
  display: none;
}

.item {
    /*padding-top: 1.9152rem;*/
    padding-top: 5rem;

    @media (--sm-viewport-down) {
        padding-top: 0;
    }

    &-related:empty

{
  @media (--md-viewport-up) {
    margin-top: 1.9152rem; display: block; padding: 0;
  }

  display: none;
}


&-details {
  padding: 0 1.9152rem;
  background-color: var(--white);

  @media (--md-viewport-up) {
    margin-bottom: 1.9152rem;
  }

  .item-title

{
  margin-top: 1.596rem;

  @media (--md-viewport-down) {
    font-size: var(--font-size-h4); margin-top: 1.33rem;
  }
}

@media (--sm-viewport-down) {
  padding: 0;
}

.item-img {
  margin-top: 1.596rem;

  @media (--sm-viewport-down) {
    margin-top: 0;
  }
}

.item-store-name,
.item-disclaimer,
.item-social {
  margin-bottom: 1.596rem;
}

.item-store-name {
  @media (--md-viewport-down) {
    margin-bottom: 1.10833rem;
  }
}

.item-social {
  margin-top: 3.05rem;

  @media (--md-viewport-down) {
    margin: 1.596rem 0;
  }
}

.item-link {
  margin: 1.596rem 0;
}

.item-social.footer-social .nav-item + .nav-item {
  margin-left: 1rem;
}

.item-social-header {
  margin-bottom: var(--spacer);
}

.item-disclaimer {
  margin-bottom: 1.596rem;
}

.social-icon {
  fill: var(--gray);
}

}

&-store {
  background-color: var(--white);
  padding: 1.9152rem 0;
  .store-hours br

/*{
  display: none;
}*/

.inline-hours-divider:not(:last-of-type) {
  display: inline-block;
}

.small {
  margin-bottom: 2px;
}

.store-hours {
  margin-bottom: 0.6414rem;
  font-size: 0.92361rem;
}

& p:not(.small),
div.store-hours {
  margin-bottom: 1rem;
}

}

&-store-logo {
  max-height: 140px;
  display: block;
  margin: 0 auto;
}

}

/* MALL DIRETIONS */

.directions {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 900px) {
      flex-direction: row;
      align-items: flex-start;
  }

  @media (--lg-viewport-down) {
    background-color: var(--gray-lighter);
  }

  &-map

{
  width: 100%;
  height: 300px;

  @media (--lg-viewport-up) {
    height: calc(100vh - (var(--navbar-height) * 2));
  }
}

&-form {
    position: absolute;
    z-index: 2;
    background: var(--white);
    padding: 1.9152rem;
    height: calc((100vh - (var(--navbar-height) * 2)) - .01rem);
    min-width: 350px;
    max-width: 400px;
    overflow-y: scroll;

    @media (--lg-viewport-down) {
        position: relative; top: 0; bottom: 0; padding-left: 0; padding-right: 0; background: transparent; height: auto; min-width: 250px; padding-top: 1.7rem;
    }

    @media only screen and (max-width: 420px) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

&-input.form-control {
  padding: 0.9722rem 1.2731rem 0.9722rem 2.75rem;
}

&-results {
  & + .form-group

{
  margin-top: 0;
  margin-bottom: 0;
}

&[style="direction: ltr;"] + .form-group {
  margin-top: 1.798rem;
}

@media (--lg-viewport-down) {
  max-height: 100%;
}

}

&-group {
  position: relative;
}

&-icon {
  height: .9256rem;
  margin-top: -.4861rem;
  position: absolute;
  left: .9722rem;
  top: 50%;
  width: .9256rem;
}

&-icon.end {
  fill: var(--brand-primary);
  width: 16px;
  height: 21px;
  left: 0.9rem;
  top: 25px;
}

}

/* TRAVEL HERE */

.travel-here,
.dining {
  &-info .HtmlContentClass

{
  padding-left: 15px;
  padding-right: 15px;
}

&-info-desc:not(.dining-info-desc) {
  padding: 0 15px;
}

.btn-group-tour,
.btn-dining-guide {
  margin-top: 1.10833rem;
}

.travel-here-blobs {
  padding-top: 0;
}

&-info {
  &, &-img

{
  margin-bottom: 1.9152rem;
}

}

@media (--md-viewport-up) {
  & .no-margin {
    margin: 0;
  }

  & .travel-here-info-desc {
    padding: 0;
  }

  & .travel-here-blobs {
    padding-top: 1.7rem;
  }
}

@media (--lg-viewport-up) {
  & .travel-here-blobs {
    padding-top: 3.30947rem;
  }
}

.simon-section-header {
  margin-bottom: 1.8152rem;
}

.logo-slider.featured-stores {
  padding-bottom: 1.10833rem;
}

}

/* DINING */

.dining-links {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 4rem;
    margin-left: -5px;
    @media (--lg-viewport-down) {
      margin-bottom: 4rem;
      justify-content: center;

      & .square {
          width: calc(40% - 10px);
          min-width: 95px;
      }
    }
}
.square {
    height: 95px;
    width: 95px;
    background-color: #f1f1f1;
    margin: 5px;
    font-size: .7rem;
    padding: 25px 5px 5px 5px;
}
a.square:hover,
a.square:focus {
    background-color: var(--brand-primary);
    color: #fff;
    & svg {
      fill: #fff;
      opacity: 1;
    }
}

.sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important; /* 2 */
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important; /* 3 */
}

#diningInfo ul {
    padding-left: 0px;
}
#diningInfo ul li {
    list-style: none;
    display: inline;
    color: #757575;
    padding-right: 15px;
    font-size: 0.8rem;
}
#diningMobile ul {
    border-bottom: none;
}
#diningMobile li {
    font-size: 12px;
}
.nav-item-dining-mobile {
    flex: 1;
    padding: 1rem 0;
    text-align: center;
    text-transform: none
}

.delivery-option-menu {
    margin-bottom: 10px;
}

.delivery-option {
    background-color: #f1f1f1;
    margin-bottom: 15px;
}

.dining {
  &-blobs

{
  padding-top: 0;
}

&-info {
    @media(--lg-viewport-down) {
        text-align: center;
    }
}

&-info .simon-section-header {
  margin-bottom: .5rem;
  text-align: left;
  @media (--lg-viewport-down) {
      text-align: center;
  }
}

&-info .btn {
  margin-top: 1.9152rem;
}

&-info-img {
  margin-bottom: 4rem;
  @media (--lg-viewport-down) {
      margin-bottom: 1rem;
  }
}

}

.dining-links .nav-link svg {
    opacity: 0.55;
    transition: all .3s ease-out;
}

.dining-links .nav-link:hover svg {
    opacity: 1;
}

/* SHOP ONLINE */
section.shop-online-section {
    margin-bottom: 2rem;
    & h2 {
        font-family: SimonCircularPro-Book;
        font-size: 2rem;
        margin-bottom: 1.5rem;
    }
    & h3 {
        font-family: SimonCircularPro-Book;
        font-size: 1.5rem;
    }
    & h4 {
        font-size: 20px;
        font-weight: 600;
    }
    & .shop-online-header {
        margin-bottom: 1rem;
    }
    & .shop-online-banner
    {
        background: var(--black);
        height: var(--navbar-logo-height);
        margin-bottom: 2rem;
    }
    & svg {
        height: var(--navbar-logo-height);
    }
}

/* Shop Online cards*/
.shop-online-card {
    color: var(--white);
    position: relative;
    margin-left: 12px;
    margin-right: 12px;
    @media(--sm-viewport-down) {
        margin-left: 7px;
        margin-right: 7px;
    }
    & picture {
        width: 100%;
    }
    & .top-left {
        position:absolute;
        top: 0;
        left: 0;
        padding-top: 1rem;
        padding-left: 1rem;
        z-index: 2;
    }
    & .bottom-left {
        position: absolute;
        bottom: 0;
        left: 0;
        padding-bottom: 1rem;
        padding-left: 1rem;
        z-index: 2;
    }
    & .promo-text {
        font-size: 1.2rem;
        font-family: 'SimonCircularPro-Bold';
        margin-bottom: 0;
    }
    & .promo-code {
        margin-bottom: 0;
    }

}

body:not(.mall-map-view) main a.shop-online-link:not(.btn):focus {
    color: var(--white);
}

.shop-online-slider {

    & .slick-prev, & .slick-next {
        width: 40px;
        height: 40px;
        border-style: solid;
        border-width: 10px;
        border-radius: 50%;
        background-color: #fff;
        opacity: 1;
    }
}

.store-page-logo-large {
    width: 100%;
    max-width: 300px;
    @media (--sm-viewport-down) {
        max-width: 200px;
    }
}
.store-page-logo-medium {
    width: 100%;
    max-width: 250px;
    @media (--sm-viewport-down) {
        max-width: 150px;
    }
}
.store-page-logo-small {
    width: 100%;
    max-width: 200px;

    @media (--sm-viewport-down) {
        max-width: 125px;
    }
}
.store-page-logo-xsmall {
    width: 100%;
    max-width: 150px;

    @media (--sm-viewport-down) {
        max-width: 100px;
    }
}

.card-overlay::after {
    content: '';
    z-index: 1;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background: linear-gradient(90deg,rgba(0,0,0,.5), transparent 50%);
    top:0;
}

/* HOLIDAY */
.mall-insider-holiday {
    color: #d81f26;
}

.video-overlay-darker {
  background-color: rgba(0,0,0,.25);
}


/* MOVIES */
.mall-movies {
  min-height: calc(100vh - 148px);

  @media (--lg-viewport-down) {
    padding-bottom: 0;
  }
}

.mall-movie {
  min-height: 640px;

  @media (--md-viewport-down) {
    min-height: 0;
  }
}

.movies-nav {
  @media (--lg-viewport-down) {
    overflow: auto;
    white-space: nowrap;
  }

  & .btn:first-of-type {
    margin-left: 15px;
  }

  & .btn:last-of-type {
    margin-right: 15px;
  }
}

.movie-times-mobile {
  flex: 1 0 100%;
}

.movie-times-mobile-header {
  background-color: var(--gray);
  text-align: center;
  padding: 5px 0;
  color: var(--white);
  font-family: 'SimonCircularPro-Bold';
}

.movie-times-mobile-link {
  padding: 5px;
  flex: 1 0 calc(50% - 15px);
  margin-left: 15px;
  margin-bottom: 15px;
  max-width: calc(50% - 15px);
}

.movie-times-mobile-links {
  padding: 3px 15px 11px;
  display: flex;
  flex-wrap: wrap;
}

.card-details .movie-card-time {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 0;
  white-space: normal;
}

.movie-card {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (--sm-viewport-down) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.movie-card .card-link {
  @media (--sm-viewport-down) {
    width: 100%;
  }
}

.movie-card .card-details {
  @media (--md-viewport-up) {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.movie-card .card-details {
  @media (--sm-viewport-down) {
    display: flex;
    flex: 1 0 50%;
    justify-content: center;
    align-items: center;
    padding: 0 15px 15px;
  }
}

.movie-card-poster {
  flex: 1 0 auto;

  @media (--md-viewport-up) {
    position: relative;
  }

  @media (--sm-viewport-down) {
    flex: 1 0 35%;
    display: flex;
    align-items: flex-end;
  }
}

.movie-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.movie-card-info {
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.85);
  color: var(--white);
  padding: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (--sm-viewport-down) {
    opacity: 1;
  }
}

.movie-card:hover .movie-card-info {
  opacity: 1;
}

.movie-card-time {
  padding: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
}

.movie-card-info-times {
  text-align: center;
  margin-bottom: 30px;
  width: 100%;
}

.movie-poster {
  position: relative;
  cursor: pointer;
}

#movieTrailer.open {
  @media (--sm-viewport-down) {
    width: 100vw;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .modal-content {
      height: 300px;
    }

    .close svg {
      fill: #fff;
    }
  }
}

.more-movies .simon-section-gray {
  @media (--sm-viewport-down) {
    background-color: #fff;
  }
}

.movie-poster-info {
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.85);
  color: var(--white);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.movie-poster-play {
  fill: white;
  margin-bottom: 20px;
}

.movie-poster:hover .movie-poster-info {
  opacity: 1;
}

.movie-trailer {
  margin: 0 auto;
}

.movie-details {
  @media(--md-viewport-down) {
    & *:not(.movie-synopsis) {
      text-align: center;
      justify-content: center;
    }
  }
}

.movie-rating-and-time {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  & > * + *

{
  margin-left: 15px;
}

}

.movie-times {
  @media(--sm-viewport-down) {
    padding-bottom: 0;
  }
}

.movie-times-list {
  clear: both;
  padding: 1.7rem;

  @media(--sm-viewport-down) {
    padding: 1.7rem 15px;
  }
}

.movie-times .nav-tabs {
  border: none;
  font-size: 0;
  background-color: transparent;

  & .nav-item,
  & .nav-item .nav-link {
    margin-bottom: 0;
    font-size: var(--btn-font-size);
  }

  & .nav-item:first-of-type {
    @media(--sm-viewport-down) {
      margin-left: 15px;
    }
  }

  & .nav-item:last-of-type {
    @media(--sm-viewport-down) {
      margin-right: 15px;
    }
  }

  @media (--lg-viewport-down) {
    overflow-y: hidden;
    overflow-x: scroll;
    white-space: nowrap;
  }
}

.movie-times .nav-tabs .nav-item {
  @media (--lg-viewport-down) {
    float: none;
    display: inline-block;
  }
}

.movie-times .nav-tabs .nav-link {
  border-color: var(--gray-lighter);
  font-family: 'SimonCircularPro-Bold';

  @media(--lg-viewport-up) {
    border: none;
  }
}

.movie-times .nav-tabs .nav-link.active {
  border-color: var(--gray-lighter);
  background-color: var(--gray-lighter);
  color: var(--gray);

  @media(--lg-viewport-up) {
    border: none;
  }
}

.mall-movies-slider .slick-slide {
  height: auto;
}

.mall-movies-slider.slick-initialized .slick-slide,
.mall-movies-slider.slick-slider .slick-track {
  @media(--md-viewport-up) {
    display: flex;
  }
}

#movieListError {
    padding-top: 2rem;
    margin-bottom: -1rem;
    background-color: var(--gray-lighter);

    @media(--md-viewport-down) {
        padding-top: 1.75rem;
        padding-bottom: 0;
    }

    @media(--sm-viewport-down) {
        padding-top: 1.5rem;
        margin-bottom: 0;
    }
}

a span.hidden-link-text {
    height: 1px;
    width: 1px;
    position: absolute;
    overflow: hidden;
    top: -10px;
}

#tenant-minimap-container {
    height: 400px;
    padding-top: 20px;
}
#minimap-overlay {
    position: absolute;
    background-color: transparent;
    top: 0px;
    left: 0px;
    padding: 0px 0px 0px 0px;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.text-plum {
    color: var(--brand-primary)
}

.navbar-mobile-fixed {
    display: none;
}

@media screen and (max-width: 767.98px) {
    .navbar-mobile-fixed {
        display: block;
        overflow: hidden;
        background-color: var(--gray-lighter);
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 20;
        padding: 1rem .75rem;
        text-align: center;
        font-size: .75rem;
        /*border-top: #757575 solid 2px;*/
        box-shadow: 0px 0px 20px rgba(0,0,0,0.25);
        transition: bottom 0.3s ease, box-shadow 0.3s ease;
    }

    .navbar-mobile-fixed svg {
        padding: 5px;
        height: 30px;
        width: 30px;
    }

    .navbar-mobile-fixed a {
        text-decoration: none;
        font-weight: bold;
    }
}

.discover-slider {
    @media(--md-viewport-down) {
        padding-right: 15px;
        padding-left: 15px;
    }
}

.discover-slider p a, 
.explore-slider div>a,
.spg-ad-spot-link {
    letter-spacing: 0.05em;
}
.explore-slider .header-md a {
    text-decoration: none;
    text-transform: none;
}

.explore-slider div > a {
    font-family: "SimonCircularPro-Bold", sans-serif;
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    display: block;
    text-transform: uppercase;
    color: var(--gray);
    font-size: .9236111111rem;
}

.explore-slider div > a:first-of-type {
    margin-top: 1.25rem;
}

.explore-slider div > a:hover,
.explore-slider div > a:focus,
.service-spot a:hover,
.service-spot a:focus,
.redesign-spo-carousel a:hover,
.redesign-spo-carousel a:focus{
    color: var(--brand-primary);
    transition: all 0.2s ease-out;
}

.service-spot a, 
.redesign-spo-carousel a {
    transition: all 0.2s ease-out;
}
.service-spot .service-image-wrapper{
    overflow: hidden;
    padding-top: 147.368%;
    position: relative;
    margin-bottom: 1rem;
}
.service-spot a img,
.redesign-spo-carousel .slick-slide .spo-carousel-img-wrapper img{
    transition: all 0.3s ease-out;
    box-sizing: border-box;
    display: block;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 100%;
    height: auto;
    max-width: none;
}
.service-spot a:hover img,
.redesign-spo-carousel .slick-slide a:first-child:hover img {
    width: 103%;
}

.redesign-spo-carousel .slick-slide {
    position: relative;
}
.redesign-spo-carousel .slick-slide .spo-carousel-img-wrapper {
    overflow: hidden;
    padding-top: 150%;
    position: relative;
    margin-bottom: 2rem;
    display: block;
}

.explore-slider h5 {
    margin-bottom: 1rem;
}

.explore-slider img {
    width: 100%;
}

.sm-col-no-gutter {
    @media(--sm-viewport-down) {
        padding-right: 0px;
        padding-left: 0px;
    }
}

.md-col-no-gutter {
    @media(--md-viewport-down) {
        padding-right: 0px;
        padding-left: 0px;
    }
}

.lg-col-no-gutter {
    @media(--lg-viewport-down) {
        padding-right: 0px;
        padding-left: 0px;
    }
}


.full-width-section {
    background-color: var(--gray-lighter);
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.hero-container + .full-width-section {
    margin-top: 0;
}

.logo-slider-gray {
    background-color: var(--gray-lighter);
}

.trending-slider {
    margin-top: 1.5rem;
    @media(--lg-viewport-down) {
        margin-top: 1.5rem;
    }
    & h2 {
        font-size: 1.5rem;
    }
}

.redesign-logo-slider {
    padding: 0;
    margin-left: -0.15rem;
    margin-right: -0.15rem;
    margin-top: 0.3rem;
}

.redesign-logo-slider .slick-track {
    margin-left: -0.15rem;
}

.redesign-logo-slider .logo img {
    width: 100%;
    height: auto;
    max-height: none;
    transition: all 0.15s ease-out;
    max-width: none;
    margin: 0%;
}

.redesign-logo-slider .logo {
    padding: 0.3rem;
}

.redesign-logo-slider .slick-prev,
.explore-slider .slick-prev,
.redesign-spo-carousel .spo-carousel .slick-prev{
    margin-left: -1rem;
}

.redesign-logo-slider .slick-next,
.explore-slider .slick-next,
.redesign-spo-carousel .spo-carousel .slick-next{
    margin-right: -1rem;
}

.slick-dots li button:focus:before {
    color: var(--black);
}

.redesign-spo-carousel .spo-carousel {
    margin-bottom: 0;
}
.service-spot {
    margin-left: 10px;
    margin-right: 10px;
}

.service-spot p,
.redesign-spo-carousel h3 {
    font-size: 1.15rem;
    margin-top: 0.75rem;
}
.redesign-spo-carousel {
    margin-top: 3rem;
}
.redesign-spo-carousel h2 {
    margin-bottom: 3rem;
}

.service-spot a img {
    width:100%;
    max-width: none;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.spg-hero {
    position: relative;
    color: black;
    text-decoration: none;
}

.spg-hero .hero-full-size-link,
.spg-video-hero .hero-full-size-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.spg-hero h1, .spg-hero h2 {
    line-height: .8;
}

.spg-hero div p a:nth-of-type(2) {
    padding-top: .5rem;
}

.hero-text {
    padding: 2.5rem 2.5rem;
}

.hero-text p a,
.spg-ad-spot-link {
    font-family: 'SimonCircularPro-Bold', sans-serif;
    text-transform: uppercase;
}

.hero-text h2 a {
    text-decoration: none;
}

.spg-ad-spot {
    display: block;
}

.spg-ad-spot .hero-text h2,
.explore-slider h5 {
    line-height: 1.25;
    letter-spacing: -0.01em;
}

.spg-ad-spot-link {
    display: inline-block;
    text-transform: uppercase;
    padding-bottom: 0.2rem;
    text-decoration: underline;
    letter-spacing: -0.01em;
}

.hero-top-left {
    position: absolute;
    top: 0;
    left: 0;
}

.hero-top-center {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100%;
}

.hero-top-right {
    position: absolute;
    top: 0;
    right: 0;
}

.hero-middle-left {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0%, -50%);
}

.hero-middle-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.hero-middle-right {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0%, -50%);
}

.hero-bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
}

.hero-bottom-center {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100%;
}

.hero-bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
}

.mall-copy {
    margin-top: 3rem;
    margin-bottom: 3rem;
    text-align: center;
}

.mall-copy h1 {
    font-size: 1.75rem;
}

.services-slider {
    margin-bottom: 3rem;
}

.services-header {
    margin-bottom: 3rem;
    margin-top: 3rem;
}

.services-header h2 {
    margin: 0;
}

.explore-header {
    margin-bottom: 3rem;
}

.explore-header h2{
    margin: 0;
}

.hero-image-wrapper {
    width: 100%;
}

.spg-hero p a:not(.btn), .spg-video-hero p a:not(.btn) {
    display: block;
}

.spg-video-hero.main-hero .volume{
    display: block;
    z-index: 2;
}

.video-inner-container .play{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;

    & svg {
        display: block;
    }
}

.description-headline :last-child,
.main-hero .hero-text > :last-child,
.spg-ad-spot .hero-text > :last-child,
.spg-video-hero .hero-text > :nth-last-child(2){
    margin-bottom: 0;
}

.spg-video-hero .hero-text > h2,
.spg-video-hero .hero-text > p {
    position: relative;
    z-index: -1;
}

.spg-video-hero .hero-text > div.spg-button-hidden-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.spg-video-hero .hero-text > p > a.btn-none, 
.spg-hero .hero-text > p > a.btn-none,
.hero-text > div.spg-button-hidden-wrapper > a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
}

.spg-video-hero .hero-text .play {
    position: relative;
}

.spg-video-hero .pause,
.spg-video-hero .play,
.spg-video-hero .hero-text {
    z-index: 2;
}

.spg-video-hero .pause:focus,
.spg-video-hero .play:focus {
    fill: var(--gray-lighter);
    opacity: 0.75;
}

main .navbar {
    border-bottom: 1px solid var(--gray-light);
    background-color: var(--white);
    z-index: 3;
}

@media(--lg-viewport-up){
    .spg-ad-spot.spg-ad-spot-tall {
        position: relative;
        width: 100%;
        padding-top: calc(200% + 1.736111rem);
        & picture {
            position: absolute;
            top: 0;
            left: 0;
            width:100%;
            height:100%;
            object-fit: cover;
        }
    }
    .spg-ad-spot.spg-ad-spot-wide {
        position: relative;
        width: 100%;
        padding-top: 50%;

        & picture {
            position: absolute;
            top: 0;
            left: 0;
            width:100%;
            height:100%;
            object-fit: cover;

        }
    }
    .spg-ad-spot {
        position: relative;
        width: 100%;
        padding-top: 100%;
        margin-bottom: 1.736111rem;
        overflow: hidden;
        display: block;

        & img {
            position: absolute;
            top: 0;
            left: 0;
            width:100%;
            height:100%;
            object-fit: cover;
        }
        & h2 {
            margin-bottom: 1.25rem;
        }
        & h2 + p {
            margin-bottom: 1.4375rem;
        }
    }
    .featured-ad-spot-container .spg-ad-spot {
        margin-bottom: 0;
    }

    .spg-ad-spot-container {
        margin-bottom: -1.736111rem;
    }

    .spg-ad-spot-image-container {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        transition: all 0.3s ease-out; 
    }
    .spg-ad-spot-image-container.cta-hover {
        width: 102%;
        height: 102%;
    }
    .spg-ad-spot-wide .spg-ad-spot-image-container.cta-hover {
        width: 101%;
        height: 101%;
    }
    /* Default font sizing for desktop */
    
    .spg-ad-spot p {
        font-size: 1.0625rem;
    }
    .spg-ad-spot p a,
    .spg-ad-spot-link {
        font-size: 0.92361111111rem;
    }

}
@media(--lg-viewport-down) {
    .spg-ad-spot img {
        display: block;
        width: 100%;
    }
    .spg-ad-spot .hero-text {
        padding: 0.75rem 0 2rem 0;
    }
    .spg-ad-spot .hero-text p a {
        transition: all 0.2s ease-out;
    }
    .spg-ad-spot.spg-hero .hero-text p a:hover,
    .spg-ad-spot.spg-hero .hero-text p a:focus {
        color: var(--brand-primary);
    }
    .spg-ad-spot.spg-hero h2 {
        font-family: 'SimonCircularPro-Bold', sans-serif;
        font-size: 1.125rem;
        margin-bottom: 1rem;
    }

    .spg-ad-spot.spg-hero h2 + p {
        margin-bottom: 0.75rem;
    }

    .spg-ad-spot .hero-text > .text-center,
    .spg-ad-spot .hero-text > .text-left,
    .spg-ad-spot .hero-text > .text-right {
        text-align: left;
    }

    .featured-ad-spot-container .spg-ad-spot
    {
        padding: 0 .8680555rem;
    }
    .featured-ad-spot-container .spg-ad-spot img,
    .discover-slider .slick-track > div:only-child .spg-ad-spot img{
        width: 100%;
    }
    .discover-slider .slick-track > div:only-child {
        float: none;
    }

    .discover-slider p a {
        font-size: 0.8rem;
    }

    .discover-slider > div:nth-child(2) > .col-xs-6 {
        padding-right: 0.225rem;
    }
    .discover-slider > div:nth-child(3) > .col-xs-6 {
        padding-left: 0.225rem;
    }

    .spg-ad-spot .hero-top-left, .spg-ad-spot .hero-top-center, .spg-ad-spot .hero-top-right,
    .spg-ad-spot .hero-middle-left, .spg-ad-spot .hero-middle-center, .spg-ad-spot .hero-middle-right,
    .spg-ad-spot .hero-bottom-left, .spg-ad-spot .hero-bottom-center, .spg-ad-spot .hero-bottom-right {
        position: relative;
        left: 0px;
        top: 0px;
        bottom: initial;
        right: initial;
        transform: translate(0%, 0);
        text-align: left;
        background: none;
    }

    .spg-ad-spot.spg-hero h2, 
    .spg-ad-spot.spg-hero p, 
    .spg-ad-spot.spg-hero p a:not(.btn),
    .spg-ad-spot.spg-hero p span {
        color: black;
    }

    .spg-ad-spot .spg-hero h2 {
        font-family: SimonCircularPro-Light, sans-serif;
    }

    /* Set Default Font Styles for Mobile */
    .fonts-loaded {
        .spg-ad-spot.spg-hero h2,
        .spg-ad-spot.spg-hero h2 a,
        .spg-ad-spot.spg-hero h2 span {
            font-family: "SimonCircularPro-Bold", sans-serif !important;
            color: #000;
            text-align: left;
        }
        .spg-ad-spot.spg-hero p {
            font-family: "SimonCircularPro-Book", sans-serif !important;
            color: #000;
            text-align: left;
        }

    }

    .spg-ad-spot .hero-text br,
    .spg-ad-spot .hero-text br:after{
        display: none;
    }
}

@media(--md-viewport-up) {

    .redesign-logo-slider .logo img {
        padding: 5%;
    }
    .redesign-logo-slider .logo a:hover img {
        position: relative;
        max-width: none;
        padding: 8%;
        background-color: var(--gray-light);
    }

    .main-hero img,
    .main-hero video{
        object-fit: cover;
        object-position: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .main-hero {
        height: calc(100vh - (var(--navbar-height) * 2)); /* Calculating for header & nav bar */
        width: 100%;
        overflow: hidden;
        position: relative;
    }

    .main-hero .hero-text > h2,
    .main-hero .hero-text > p,
    .main-hero .hero-text > a {
        margin-bottom: 1.25rem;
    }

    .main-hero .hero-text > h2 + p {
        margin-bottom: 1.475rem;
    }

    .video-inner-container .play{
        display: none;
    }

    .national-hero {
        margin-bottom: 2rem;
    }

    .national-hero .main-hero {
        height: calc(100vh - var(--navbar-height)); /* Calculating for header */
    }

    .hero-container .col-md-12{
        padding: 0;
    }

    .explore-slider img {
        margin-bottom: 1rem;
    }

    .main-hero.ms-video-workaround video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
    }

    /* Default font sizing for desktop */

    .description-headline p,
    .spg-ad-spot p,
    .explore-slider p,
    .service-spot p,
    .redesign-spo-carousel h3 {
        font-size: 1.0625rem;
    }

    .spg-ad-spot p a,
    .explore-slider div > a,
    .spg-ad-spot-link {
        font-size: 0.92361111111rem;
    }


}

@media(--md-viewport-down) {
    .main-hero .spg-button:hover,
    .main-hero .spg-button:focus{
        color: var(--brand-primary);
    }
    .explore-slider h5{
        font-size: 1.125rem;
        margin-bottom: 1rem;
        font-family: 'SimonCircularPro-Bold', sans-serif;
    }

    .explore-slider .col-sm-12 {
        margin-bottom: 1rem;
    }
    .explore-slider > .HtmlContentClass:last-child .col-sm-12 {
        margin-bottom: 0;
    }

    .explore-slider div > a {
        font-size: 0.8rem;
    }
    .hero-top-left, .hero-top-center, .hero-top-right,
    .hero-middle-left, .hero-middle-center, .hero-middle-right,
    .hero-bottom-left, .hero-bottom-center, .hero-bottom-right,
    .spg-video-hero .overlay{
        position: relative;
        left: 0px;
        top: 0px;
        bottom: initial;
        right: initial;
        transform: translate(0%, 0);
        text-align: left;
        background: none;
    }

    .main-hero div {
        text-align: center;
    }

    .spg-hero h1, .spg-hero h2, .spg-hero p, .spg-hero p a:not(.btn) {
        width: 100%;
        color: black;
    }

    .spg-hero h1, .spg-hero h2 {
        font-family: SimonCircularPro-Light, sans-serif;
    }
    .hero-text {
        padding: 3rem 0;
    }

    .fonts-loaded .main-hero .hero-text p, 
    .main-hero .hero-text .text-left, 
    .main-hero .hero-text .text-center, 
    .main-hero .hero-text .text-right,
    .fonts-loaded .main-hero.spg-video-hero .hero-text p{
        text-align: center;
        color: #000;
    }

    .main-hero .spg-button {
        color: #000;
        background-color: #fff;
        border: none;
        text-decoration: underline;
        padding: 0;
    }

    .main-hero a.spg-button:hover,
    .main-hero a.spg-button:focus {
        background-color: transparent;
    }


    .main-hero {
        height: auto;
    }

    .video-inner-container {
        position: relative;
    }

    .spg-video-hero .pause {
        z-index: 2;
    }

    .overlay .play {
        display: none;
    }

    video.v2-video,
    .spg-video-hero .video-fallback {
        height: auto;
    }

    /* Set Default Font Styles for Mobile */
    .fonts-loaded {
        .hero-text .header-xl,
        .hero-text .display-5,
        .spg-video-hero .hero-text h1,
        .explore-header h2,
        .services-header h2{
            font-family: "SimonCircularPro-Light", sans-serif !important;
            color: #000;
            text-align: center;
        }
        .hero-text svg.icon-play {
            fill: #000;
        }
        .explore-slider h5, 
        .spg-ad-spot.spg-hero h2,
        .spg-ad-spot.spg-hero h2 a,
        .spg-ad-spot.spg-hero h2 span,
        /*.hero-text p,*/
        .description-headline h3 {
            font-family: "SimonCircularPro-Bold", sans-serif !important;
            color: #000;
            text-align: left;
        }
        .explore-slider p,
        .spg-ad-spot.spg-hero p,
        .spg-video-hero .hero-text p,
        .description-headline p {
            font-family: "SimonCircularPro-Book", sans-serif !important;
            color: #000;
            text-align: left;
        }
    }

    .main-hero p {
        font-size: 0.92361111111rem;
    }

    .spg-ad-spot.spg-hero h2 {
        font-size: 0.92361rem;
        margin-bottom: 0.6414rem;
    }

    fieldset.mall-field, .filters .mall-filter, .filters .mall-list-filter {
        margin-bottom: 1rem;
    }

    .fonts-loaded .spg-hero .hero-text,
    .fonts-loaded .spg-video-hero .hero-text,
    .fonts-loaded .main-hero.spg-video-hero .hero-text {
        & .text-brand-primary-mobile {
            color: var(--brand-primary);
        }

        & .text-brand-secondary-mobile {
            color: var(--brand-secondary);
        }

        & .text-brand-primary-forum-mobile {
            color: var(--brand-primary-forum);
        }

        & .text-brand-primary-mills-mobile {
            color: var(--brand-primary-mills);
        }

        & .text-brand-gray-mobile {
            color: var(--brand-gray);
        }

        & .text-gray-mobile {
            color: var(--gray);
        }

        & .text-black-mobile {
            color: #000;
        }

        & .text-white-mobile {
            color: #fff;
        }

        & .text-holiday-mobile {
            color: var(--holiday-red);
        }

        & .text-brand-turquoise-mobile {
            color: var(--brand-turquoise);
        }

        & .medium-mobile {
          font-family: "SimonCircularPro-Medium", sans-serif !important;
        }

        & .bold-mobile {
          font-family: "SimonCircularPro-Bold", sans-serif !important;
        }

        & .black-mobile {
          font-family: "SimonCircularPro-Black", sans-serif !important;
        }

        & .book-mobile {
          font-family: "SimonCircularPro-Book", sans-serif !important;
        }

        & .light-mobile,
        & .thin-mobile {
          font-family: "SimonCircularPro-Light", sans-serif !important;
        }

        & .italic-mobile {
          font-family: "SimonCircularPro-Bookitalic", sans-serif !important;
        }

        & .bold-italic-mobile {
          font-family: "SimonCircularPro-Bolditalic", sans-serif !important;
        }

        & .black-italic-mobile {
          font-family: "SimonCircularPro-Blackitalic", sans-serif !important;
        }

        & .medium-italic-mobile {
          font-family: "SimonCircularPro-Mediumitalic", sans-serif !important;
        }

        & .thin-italic-mobile,
        & .light-italic-mobile {
          font-family: "SimonCircularPro-Lightitalic", sans-serif !important;
        }

        & .text-left-mobile {
            text-align: left;
        }

        & .text-center-mobile {
            text-align: center;
        }

        & .text-right-mobile {
            text-align: right;
        }
    }

}

@media(--sm-viewport-up) {
    .redesign-logo-slider,
    .explore-slider,
    .redesign-spo-carousel .spo-carousel{
        overflow: visible;
    }
    .redesign-logo-slider .slick-list,
    .explore-slider .slick-list,
    .redesign-spo-carousel .spo-carousel .slick-list{
        overflow: hidden;
    }
    .redesign-spo-carousel h3.copy {
        font-size: 1.15rem;
    }

}

.hero-container + .spg-ad-spot-container {
    margin-top: 2rem;
}

@media screen and (--md-viewport-down) {
    .hero-container + .full-width-section,
    .hero-container + .spg-ad-spot-container {
        margin-top: 2rem;
    }
}


@media screen and (--sm-viewport-down) {
    .slick-list {
        overflow: visible;
    }

    .logo-slider .slick-list, .services-slider .slick-list {
        overflow: hidden;
    }
    .redesign-logo-slider {
        margin: 0 -0.066rem;
    }
    .redesign-logo-slider .slick-track {
        margin: 0 -0.033rem;
    }
    .redesign-logo-slider .logo {
        padding: 0.066rem;
    }

    .redesign-logo-slider {
        padding-top: 0.25rem;
        padding-bottom: 0.75rem;
    }

    .discover-slider > .text-center {
        text-align: left;
    }

    .service-spot {
        margin: 0 .225rem;
    }

    .service-spot p,
    .redesign-spo-carousel h3{
        font-size: 0.8rem;
        margin-top: 0;
    }

    .mall-copy {
        margin-top: 2rem;
        margin-bottom: 2rem;
        text-align: left;
    }

    .full-width-section, .hero-container + .full-width-section {
        margin-top: 2rem;
        margin-bottom: 2rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .explore-header {
        margin-bottom: 2rem;
    }
    .redesign-spo-carousel h2 {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    .services-header {
        margin-bottom: 2rem;
        margin-top: 2rem;
    }
    .services-header h2,
    .explore-header h2,
    .mall-copy .header-xl,
    .main-hero h2,
    .redesign-spo-carousel h2 {
        font-size: 1.5rem;
    }

    .main-hero {
        height: auto;
    }

    .hero-text {
        padding: 2rem 0;
    }

}

a.spg-button {
    font-size: .8rem;
    border-radius: 4px;
    text-transform: uppercase;
}

a.btn-black-white {
    color: #fff;
    background-color: #000;
    border-color: #000;
}

a.btn-white-black {
    color: #000;
    background-color: #fff;
    border-color: #fff;
}

a.btn-black-white:hover, 
a.btn-black-white:focus, 
a.btn-white-black:hover,
a.btn-white-black:focus {
    color: #fff;
    background-color: gray;
    border-color: gray;
}

a.btn-transparent-black {
    color: #000;
    border-color: #000;
    border-style: solid;
    border-width: 1px;
}

a.btn-transparent-black:hover {
    /*background-color: rgba(255, 255, 255, 0.4);*/
    background-color: var(--black);
    color: var(--white);
}

a.btn-transparent-white {
    color: #fff;
    border-color: #fff;
    border-style: solid;
    border-width: 1px;
}

a.btn-transparent-white:hover {
    background-color: rgba(0, 0, 0, 0.4);
}

a.btn-holiday-red, button.btn-holiday-red {
    color: #fff;
    border-color: #d81f26;
    background-color: #d81f26;
}

    a.btn-holiday-red:hover,
    button.btn-holiday-red:hover,
    a.btn-holiday-red:focus,
    button.btn-holiday-red:focus {
        background-color: #A6171C;
        border-color: #A6171C;
    }

.text-holiday {
    color: var(--holiday-red);
}

.text-family {
    &-aqua {
        color: var(--family-aqua);
    }

    &-red {
        color: var(--family-red2);
    }

    &-blue {
        color: var(--family-blue);
    }

    &-pink {
        color: var(--family-pink);
    }

    &-yellow-green {
        color: var(--family-yellow-green);
    }

    &-purple {
        color: var(--family-purple2);
    }
}

@media screen and (--sm-viewport-down) {
    a.btn-white-black, a.btn-transparent-black, a.btn-transparent-white {
        color: #fff;
        background-color: #000;
        border-color: #000;
    }
}

.overlay {
    /*background: rgba(0,0,0,0.25);*/
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.v2-video {
    height: auto;
}

@media (min-width: 1600px) {
    .viewport-xxl-containers .container {
        max-width: 1500px;
    }
}
.explore-slider div a {
    text-transform: uppercase;
}

.signup-content a:focus a:focus-visible {
    outline: 3px solid blue;
}

#emailSignupPrompt .signup-content,
#wifiSuccessModal .signup-content {
    padding: 1.5rem;
}

#wifiSuccessModal .row.simon-section-gray {
    @media(--lg-viewport-up) {
        display: flex;
        
        & img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

}

#emailSignupPrompt .signup-heading {
    line-height: 1.7rem;
    letter-spacing: -0.1rem;
}

@media (min-width: 992px) {
    .float-right-lg {
        float: right;
    }
}
.hide-on-print {
    display: none;
}

.collapse-margin {
    margin-top: 0px;
}

.hide-on-screen {
    display: none;
}
img.avoid-page-break {
    page-break-inside: avoid;
}

table.striped tr:nth-child(odd) {
    background-color: #f5f5f5;
}

.doc-link, .copy-link {
    cursor: pointer;
    margin-right: 10px;
}

#newsSection table {
    width: 100%;
}
#newsSection td {
    padding: 20px 10px;
    font-size: 1rem;
}

#more-vip-offers {
    background-color: #fdf2e4;
    padding: 6rem;
    position: relative;
    padding-top: 5rem;
    margin-bottom: 30px;

    @media (--md-viewport-down) {
        padding: 5rem 1rem 6rem 1rem;
    }

    & .more-vip-offers-img {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100%;
        width: auto;
        max-width: none;

        @media (--xl-viewport-down) {
            left: -5rem;
        }

        @media (--md-viewport-down) {
            display: none;
        }
    }

    & .vip-logo {
        position: absolute;
        bottom: 0rem;
        width: 12rem;
        right: 1rem;
    }

    .directory-store-tag-list {
        top: 1.5rem;
        right: 0;
        position: absolute;
    }
}

.home-luxury {
    &-tenant {
        display: flex;

        @media (--md-viewport-down){
            display: block;
        }

        &:nth-child(even) {
            @media(--md-viewport-up) {
                flex-direction: row-reverse;
            }
        }

        &-logo {
            background-color: var(--gray-light);
        }

        &-image {
            padding: 0;
            display: block;
            clear: both;

            & img {
                width: 100%;
                height: auto;
                display: block;
            }

            @media(--md-viewport-up) {
                width: 50%;
                min-height: 300px;
                display: flex;
                align-items: center;
                justify-content: center;

                & img {
                    width: auto;
                }
            }
        }

        &-description {
            padding: 1.5rem 0 3rem 0;

            & h2 {
                font-size: 1.75rem;

                & a {
                    text-decoration: none;
                }
            }

            @media(--md-viewport-up) {
                width: 50%;
                padding: 7%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                & h2 {
                    font-size: 2rem;
                }
            }
        }
    }
}

.js-simon-search-banner-ad {
    height: auto;
    padding: 1rem;
    min-height: calc(74px);
    left: 0;
    width: 100%;

    @media (--xl-viewport-down) {
        h1 {
            font-size: 1.5rem;
        }

        .spo-ad-text {
            font-size: 1rem;
        }
    }

    @media (--lg-viewport-down) {
        h1 {
        }

        .spo-ad-text {
            font-size: 0.8rem;
        }
    }

    @media (--md-viewport-down) {
        h1 {
        }

        .spo-ad-text {
        }
    }

    @media (--sm-viewport-down) {
        h1 {
        }

        .spo-ad-text {
        }
    }

    @media (--xs-viewport-down) {
        h1 {
        }

        .spo-ad-text {
        }
    }
}

.favorite-brands .sortable:not(.sortable-by-primary) {
    cursor: auto;

    &:first-child {
        margin-top: 0;
    }
}
