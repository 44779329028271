/* TRAVEL NATIONAL AND REGIONAL STYLES */

.travel {
  &-here {
    margin-top: 0px;
  }
  &-hero {
    background-color: #d1d1d1;
  }

  &-links {
    font-size: 0.76968rem;
  }

  &-national {
    .simon-section-header {
      margin-bottom: 1.8152rem;
    }

    &-regions {
      padding-top: 1.9152rem;
    }

    .btn-travel-trade {
      margin-top: 1.9152rem;
    }
  }

  &-regional {

    margin-top: 148px;

    &-toggle {
      & > .nav-item {
        flex: 1;
        text-align: center;
      }

      @media (--md-viewport-down) {
          margin-top: 1.9152rem;
      }
    }

    &-header {
      padding: 1.9152rem 0;

      .flex {
        justify-content: space-between;

        @media (--sm-viewport-down) {
          flex-direction: column;
        }
      }
    }
  
    &-map-toggle {
      align-self: center;
    }

    &-map-toggle.open .map-toggle-icon {
      transform: rotate(-90deg);
    }

    .map-toggle-icon {
      fill: var(--gray);
      position: relative;
      top: 3px;
      margin-left: 5px;
      transform: rotate(90deg);

      @mixin transition all, 0.2s, ease-in-out;
    }

    &-title {
      margin: 0;
      
      @media (--sm-viewport-down) {
         text-align: center;
         margin: 0 0 1.9152rem 0;
      }
    }
  }

  &-divider {
    border-bottom: 1px solid var(--brand-gray);
    margin-bottom: 3.30947rem;
    padding-top: 2.7rem;

    @media (--md-viewport-down) {
      margin-bottom: 1.9152rem;
      padding-top: 1.33rem;
    }
  }

  &-featured-mall {
    margin-bottom: 30px;

    &-name {
      margin-bottom: 0.25rem;
    }

    &-region {
      margin-bottom: 1.596rem;
    }

    &-img {
      margin-bottom: 30px;
    }

    @media (--sm-viewport-down) {
      &-text {
        padding: 0 15px;
      }
    }
  }
}

figure.intl-travel {
    margin: 0 0 0 0;
}
figure.intl-travel figcaption {
    margin-top: 15px;
    font-weight: bold;
    text-align: center;
}