/*
 * Search - Styles for global search in header and any uniform styles for search inputs
 */

/* The Search bar */

.search {
  width: 100%;
  height: var(--search-height);
  background: rgba(0, 0, 0, var(--search-opacity));
  color: var(--search-color);
  transition: opacity, 0.3s, ease, top, 0.3s, ease;
  top: 0;
  position: fixed;
  opacity: 0;
  z-index: var(--zindex-search);

  @media (--lg-viewport-down) {
    &.focused {
      background: rgba(0, 0, 0, 1);
    }
  }

  &.open {
    top: var(--navbar-height);
    opacity: 1;
  }

  & .icon-search {
    height: 22px;
    width: 20px;
    fill: var(--search-color);
    margin-top: calc((74px - 1.10833rem) / 2);
    margin-right: 0.9rem;
  }

  /* Make full width container on tablet and lower */

  & > .container {
    max-width: 100%;
  }

  @media (--lg-viewport-up) {
    & > .container {
      max-width: 940px;
    }
  }

  @media (--xl-viewport-up) {
    & > .container {
      max-width: 1140px;
    }
  }
}

/* Class for the body - kills scroll when search results/quick links are open */

.search-open {
  & .search-input-wrapper {
    border-bottom: 1px solid #59595b;

    @media (--lg-viewport-up) {
      border-bottom: none;
    }
  }
}

/* Wrapper for the search input */

.search-input-wrapper {
  display: flex;

  @media (--lg-viewport-up) {
    border-bottom: none;
  }
}

/* Cancel out default form control styles */

.form-control.search-input {
  flex: 1;
  width: auto;
  height: var(--search-height);
  background: transparent;
  color: var(--search-color);
  border: none;
  box-shadow: none;
  font-size: 1.10833rem;
  font-family: Arial, Helvetica, sans-serif;
  padding-left: 0;
  padding-right: 0;

  &:focus {
    box-shadow: none;
    color: var(--gray-lighter);

    &::placeholder {
      color: var(--gray-lighter);
    }
  }

  &::placeholder {
    color: var(--search-color);
  }

  /* stylelint-disable */
  &::-moz-placeholder,
  &:-moz-placeholder {
    line-height: 2;
  }
  /* stylelint-enable */
}

/* Geo Location button */

.btn-find-nearby {
  font-size: var(--base-font-size);
  font-family: Arial, Helvetica, sans-serif;
  color: var(--brand-gray);

  @include transition(color, 0.3s, ease);

  &:hover,
  &:focus {
    & .icon-find-nearby {
      fill: var(--search-color);
    }
    color: var(--search-color);
  }

  & .icon-find-nearby {
    width: var(--base-font-size);
    height: var(--base-font-size);
    fill: var(--brand-gray);
    margin-right: 0.9rem;
    display: block;

    @include transition(fill, 0.3s, ease);
  }

  @media (--lg-viewport-up) {
    height: var(--search-height);
    font-size: 1.10833rem;

    & .icon-find-nearby {
      width: 1.10833rem;
      height: 1.10833rem;
      margin-right: 0.9rem;
      margin-top: 2px;
    }
  }
}

/* Secondary tray that shows under search on focus. Quick links / Search results */

.search-links,
.search-results {
  width: 100%;
  opacity: 0;
  max-height: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 1);
  position: fixed;
  z-index: -1;
  top: 74px;
  color: var(--search-color);
  transition: opacity, 0.3s, ease, top, 0.3s, ease;
  padding: calc((74px - 1.10833rem) / 2) 0;

  &.open {
    opacity: 1;
    top: calc(var(--navbar-height) + var(--search-height));
    z-index: var(--zindex-search-results);
  }

  &-header {
    font-family: Arial, Helvetica, sans-serif;
  }

  /* Make full width container on tablet and lower */

  @media (--lg-viewport-down) {
    & > .container {
      max-width: 100%;
    }
  }

  @media (--lg-viewport-up) {
    max-height: 218px;
    background: rgba(0, 0, 0, 1);
    border-top: 1px solid #59595b;
  }
}

/* Quick links */

.search-links-item {
  font-size: var(--base-font-size);
  display: block;
  text-decoration: none;
  cursor: pointer;
  padding: 10px 0.95rem;
  color: var(--brand-gray);

  @include transition(color, 0.3s, ease);

  &:hover,
  &:focus {
    color: var(--search-color);
  }

  @media (--lg-viewport-up) {
    padding: 0;

    &:not(.btn-find-nearby) + .search-links-item {
      margin-top: 1.9152rem;
    }
  }
}

/* The container jQuery autocomplete fills with results */

.results-list {
  position: relative;
  top: -6px;
}

/* jQuery autocomplete styles */

.ui-autocomplete,
.search-results .ui-autocomplete {
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
  padding: 0;
  z-index: var(--zindex-search-results);
  top: 0 !important;
  left: 0 !important;

  .ui-menu-item {
    font-size: var(--base-font-size);
    background: transparent;
    color: var(--brand-gray);
    cursor: pointer;
    display: block;
    padding: 16px 0.95rem;
    text-decoration: none;
    color: var(--brand-gray);
    transition: color, 0.3s, ease, background, 0.3s, ease;

    &:hover,
    &:focus,
    &.ui-state-focus {
      background: #000;
      color: #fff;
    }
  }

  @media (--lg-viewport-up) {
    max-height: 150px;

    .ui-menu-item {
      padding: 10px 0.95rem;
    }
  }
}

.search-group + .ui-autocomplete {
  background: #000;
  position: absolute !important;
  top: var(--input-height) !important;
}

/* Search button that opens search bar */

.search-toggle {
  cursor: pointer;
  margin-top: -10px;
  padding-left: 0.86806rem;
  padding-right: 1.5rem;
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 1020;

  @media (--lg-viewport-up) {
    margin-top: -5px;
    padding-left: 0;
    padding-right: 0;
    position: relative;
  }

  svg {

    @mixin transition fill;
  }

  .icon-search {
    fill: var(--white);
    height: 22px;
    width: 20px;
  }

  &:hover,
  &:focus {
    .icon-search {
      fill: var(--gray);
    }
  }

  /* Fix to not stay in hover state on a touch device */

  @media screen and (min-resolution: 0dppx) {
    &:hover .icon-search,
    &:active .icon-search,
    &:focus .icon-search {
      fill: var(--white);
    }
  }

  &.open .icon-search {
    fill: var(--gray);
  }

  &.open:focus .icon-search {
    fill: var(--white);
  }

  &.collapsed:not(:hover) .icon-search {
    fill: var(--white);
  }
}

/* For simple search input with search icon in the inside right of the input */

.search-group {
  position: relative;

  & > .icon-search {
    cursor: pointer;
    margin-top: -0.6rem;
    position: absolute;
    right: var(--input-padding-y);
    top: 50%;
    fill: var(--gray);
    height: 22px;
    width: 20px;
  }
}
