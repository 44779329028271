.sidenav {
    height: calc(100dvh - var(--navbar-global-height));
    width: 85%;
    position: fixed;
    z-index: 1030;
    top: 40px;
    left: -9999px;
    background-color: #fff;
    overflow-x: hidden;
    transition: 0.2s;
    overflow-y: scroll;
}

#sideNavOverlay {
    content: '';
    position: fixed;
    background-color: rgba(0,0,0,0.4);
    left: 0;
    top: var(--navbar-global-height);
    bottom: 0;
    right: 0;
    z-index: 1029;
    transition: opacity 0.2s;
    display: none;
    opacity: 0;
}


.sidenav ul {
    list-style: none;
    padding-left: 0px;
    line-height: 1.5rem;
    margin-top: 0px;
    margin-bottom: 0px;
}

.sidenav ul li, .find-mall {
    border-top: solid;
    border-width: 1px;
    border-color: #dadada;
}

.sidenav li a, .sidenav-section {
    text-decoration: none;
    color: #000;
    font-weight: bold;  
    padding: 15px 15px 15px 20px;
    display: block;
}

.sidenav ul li a.active {
    color: var(--brand-primary);
    background-color: var(--brand-primary-lighter);
}

.sidenav-section.active {
    padding: 15px 15px 0px 20px;
}

.sidenav-section {
    padding: 15px 15px 15px 20px;
}

.sidenav a:hover {
    color: #818181;
}

.sidenav dd a {
    font-weight: normal;
    padding: 8px 15px 8px 15px;
}
.sidenav dd:first-child {
    padding-top: 8px;
}

.sidenav dl.link-group {
    font-size: inherit;
    padding-left: 30px;
    padding-right: 15px;
}

.sidenav dl.link-group:last-child {
    padding-bottom: 15px;
}

.sidenav dt.squeezebox {
    border: none;
    font-family: 'SimonCircularPro-Book';
}

.sidenav-header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-left: 20px;
    background-color: var(--gray-pale);
    padding-top: 5px;
    padding-bottom: 5px;
    height: 55px;
    > * {
        display: flex;
        align-items: center;
        justify-content: center;

        &:first-child {
            justify-content: flex-start;
        }
    }
}

.sidenav-split {
    display: grid;
    grid-template-columns: auto auto;
    padding: 0;
}

.find-header {
    grid-area: head;
    font-weight: bold;
    & a {
            text-decoration: none;
        }
}
.find-icon {
    grid-area: nav;
}
.find-content {
    grid-area: content;
    color: var(--gray);
}

.find-mall {
    padding: 15px 8px 15px 20px;
    text-wrap: auto;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-areas: "head nav"
                          "content nav";
    overflow-x: hidden;
    transition: ease-in-out .5s;
}

