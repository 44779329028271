/* //Jeremy */

@mixin box-shadow($top: 0, $left: 0, $blur: 0, $spread: 1px, $color: var(--black), $inset: false) {

  @if $inset {
    box-shadow: inset $top $left $blur $spread $color;
  }

  @else {
    box-shadow: $top $left $blur $spread $color;
  }
}
