/* Simon.com National Landing Page */

.landing-page {
  @media (--sm-viewport-down) {
    margin-top: 74px;
  }
}

.landing-page-logo {
  @media (--sm-viewport-down) {
    z-index: 2;
  }
}

.landing-page-nav {
  top: var(--navbar-height);
  z-index: 9;
  position: absolute;

  @media (--sm-viewport-down) {
    background-color: var(--white);
    position: absolute;
    top: 74px;
  }
}

.show-list-toggle-container {
  position: relative;

  @media (--lg-viewport-down) {
    max-width: 100%;
    width: 100%;
  }
}

.show-list-toggle {
  font-family: "SimonCircularPro-Bold";
  font-size: var(--font-size-xsmall);
  color: var(--gray);
  padding-top: calc((74px - .6414rem) / 2);
  padding-bottom: calc((74px - .6414rem) / 2);
  line-height: 1.5;
  position: absolute;
  z-index: 99;
  right: 15px;

  .icon-show-list {
    fill: var(--gray);
    width: var(--icon-size-md);
    height: var(--icon-size-md);
    margin-left: 9px;
  }

  &.open {
    color: var(--white);

    .icon-show-list {
      fill: var(--white);
    }
  }

  @media (--sm-viewport-down) {
    color: var(--black);
    border: 2px solid var(--black);
    display: block;
    padding: var(--btn-padding-y) var(--btn-padding-x);
    font-size: var(--btn-font-size);
    width: 100%;
    margin: 0 auto;
    right: initial;
    left: 15px;
    width: calc(100vw - 30px);
    top: 7.5px;

    &.open {
      color: var(--white);
      border-color: var(--white);
    }
  }
}

.landing-page-list {
  background: rgba(0, 0, 0, 0.8);
  padding-top: 84px;
  position: absolute;
  width: 100%;
  z-index: 9;
  display: none;
  overflow: hidden;
  max-height: 500px;
  padding-bottom: 30px;

  &-wrapper {
    overflow-y: scroll;
    max-height: 386px;

    @media (max-height: 600px) {
      max-height: calc(100vh - 190px);
    }
  }

  & > .container {
    max-width: 100%;
  }

  @media (--lg-viewport-up) {
    & > .container {
      max-width: 940px;
    }
  }

  &.open {
    display: block;

    @media (--md-viewport-up) {
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  &-item {
    margin-bottom: 30px;
    background-color: var(--brand-gray);
    color: var(--black);
  }

  @media (--sm-viewport-down) {
    padding-top: 80px;
  }

  @media (max-height: 600px) {
    max-height: calc(100vh - 75px);
  }
}