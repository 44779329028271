/*
 * Close class for positioning of X icon - X is added as svg within an element that has this class
 */

.close {
  float: right;
  opacity: 1;

  @mixin transition opacity, 0.2s, ease-in;

  &:hover,
  &:focus {
    color: var(--close-color);
    cursor: pointer;
    opacity: var(--close-hover-opacity);
    text-decoration: none;
  }
}

button.close {
  appearance: none;
  background: rgba(255, 255, 255, .9);
  border-radius: var(--btn-border-radius);
  cursor: pointer;
  padding: 5px;
}
